import React, { ReactEventHandler, forwardRef, useCallback, useEffect, useState } from 'react';
import {
  BlurContainerStyled,
  BlurStyled,
  ImageContainerStyled,
  ImageStyled,
} from './BlurredImage.styles';
import { ImageNotFound } from './components/ImageNotFound';

export interface ImageSize {
  offsetLeft: number;
  offsetTop: number;
  width: number;
  height: number;
}

interface BlurredImageProps {
  imageSrc: string;
  draggable?: boolean;
  onImageErrorStatusChange?: (status: { hasError: boolean }) => void;
  onImageSizeChange?: (size: ImageSize) => void;
  onImageLoaded?: () => void;
}

export const BlurredImage = forwardRef<HTMLImageElement, BlurredImageProps>(
  (
    {
      imageSrc,
      onImageErrorStatusChange,
      draggable = true,
      onImageSizeChange,
      onImageLoaded,
    }: BlurredImageProps,
    imageRef
  ): JSX.Element => {
    const [loaded, setLoaded] = useState(false);
    const [isLandscapeOrientedImage, setIsLandscapeOrientedImage] = useState(false);

    const [failedToLoadImage, setFailedToLoadImage] = useState(false);

    const onError: ReactEventHandler<HTMLImageElement> = useCallback(() => {
      setFailedToLoadImage(true);
      onImageErrorStatusChange?.({ hasError: true });
      onImageLoaded?.();
    }, [setFailedToLoadImage, onImageErrorStatusChange, onImageLoaded]);

    const onLoad: ReactEventHandler<HTMLImageElement> = useCallback(
      event => {
        const imageElement = event.target as HTMLImageElement;
        const isLandscape = imageElement.naturalWidth > imageElement.naturalHeight;

        setIsLandscapeOrientedImage(isLandscape);
        setLoaded(true);
        onImageLoaded?.();
      },
      [setIsLandscapeOrientedImage, onImageLoaded]
    );

    useEffect(() => {
      setFailedToLoadImage(false);
      setIsLandscapeOrientedImage(false);
      onImageErrorStatusChange?.({ hasError: false });
    }, [imageSrc, setFailedToLoadImage, setIsLandscapeOrientedImage, onImageErrorStatusChange]);

    useEffect(() => {
      const imageEl = (imageRef as any)?.current;

      const handleResize = () => {
        if (imageEl && imageEl.isConnected) {
          onImageSizeChange?.({
            offsetLeft: imageEl.offsetLeft,
            offsetTop: imageEl.offsetTop,
            height: imageEl.height,
            width: imageEl.width,
          });
        }
      };

      let resizeObserver: ResizeObserver;

      if (loaded && imageEl) {
        resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(imageEl);
      }
      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }, [loaded, onImageSizeChange]);

    return failedToLoadImage ? (
      <ImageNotFound />
    ) : (
      <BlurContainerStyled className='syte-blurred-image-blur-container' imageSrc={imageSrc}>
        <BlurStyled className='syte-blurred-image-blur' />
        <ImageContainerStyled className='syte-blurred-image-container'>
          <ImageStyled
            className='syte-blurred-image'
            src={imageSrc}
            onError={onError}
            onLoad={onLoad}
            ref={imageRef}
            draggable={draggable}
            isLandscape={isLandscapeOrientedImage}
          />
        </ImageContainerStyled>
      </BlurContainerStyled>
    );
  }
);
