import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import { Dispatch } from 'src/components-bl';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { useValidateSchema } from 'src/hooks';
import { MerchandiseRule, ShopFeatureToggles } from 'src/services/src/service/types/shops';
import { MerchandisingRuleTypes, SyteProductType } from 'src/services';
import { syteProductToRuleSettingsMap } from '../constants';
import { UseDataFieldsLookupTableArgumentsReturnType } from '../useDataFieldsLookupTable';
import { RuleFormSyteProductSection } from './components/RuleFormSyteProductSection/RuleFormSyteProductSection';
import { RuleFormConditionsSection } from './components/RuleFormConditionsSection/RuleFormConditionsSection';
import { RuleFormGeneralSection } from './components/RuleFormGeneralSection/RuleFormGeneralSection';
import { RuleFormScheduleDateSection } from './components/RuleFormScheduleDateSection/RuleFormScheduleDateSection';
import { RuleFormRegionSection } from './components/RuleFormRegionSection/RuleFormRegionSection';
import { merchandisingRulesFormActions } from './MerchandisingRuleForm.actions';
import { RuleDraft, rulesFormInitialData } from './MerchandisingRuleForm.config';
import { ruleFormMappers } from './MerchandisingRuleForm.mappers';
import { formValidationSchema } from './validation-schema';
import { CategoryFilterRule } from '../../../VisualEditor/types/category-filter-rule';
import { useValidateP2PRulesLimit } from './use-validate-p2p-rules-limit';

export interface RuleApiRef {
  submit(): Promise<void>;
}
export interface MerchandisingRuleFormProps extends UseDataFieldsLookupTableArgumentsReturnType {
  shopId: number;
  variantId?: string;
  entityId?: string;
  rule?: MerchandiseRule;
  mode: 'edit' | 'create';
  isReadOnly?: boolean;
  /* Used as default product when creating a rule, hide product selection  */
  predefinedProduct?: MerchandisingRuleTypes.MerchandisingRuleProduct;
  onFormStatusChange: (status: {
    canSubmit: boolean;
    isDirty: boolean;
    isValid: boolean;
    isSubmitting?: boolean;
  }) => void;
  dispatch: Dispatch;
  kpiOptions?: string[];
  availableRegions?: string[];
  isAiTagsEnabled: boolean;
  categoryFilterRule?: CategoryFilterRule;
  featureToggles: ShopFeatureToggles;
}

export const ruleFormSkeleton = <Skeleton variant='rectangular' height={500} />;

export const MerchandisingRuleForm = forwardRef<RuleApiRef, MerchandisingRuleFormProps>(
  (
    {
      shopId,
      variantId,
      rule,
      mode,
      dataFieldsLookupTable,
      isReadOnly,
      predefinedProduct,
      getDataFieldByName,
      onFormStatusChange,
      dispatch,
      kpiOptions,
      entityId,
      availableRegions,
      isAiTagsEnabled,
      categoryFilterRule,
      featureToggles,
    }: MerchandisingRuleFormProps,
    ref
  ): JSX.Element => {
    useEffect(() => {
      dispatch(merchandisingRulesFormActions.getRulesOverview({ shopId, variantId, entityId }));
    }, [dispatch, variantId, shopId, entityId]);

    useEffect(() => {
      return function reset() {
        dispatch(merchandisingRulesFormActions.reset());
      };
    }, []);

    const isReadOnlyExperiment = Boolean(variantId) && Boolean(isReadOnly);

    // map original rule to form draft, used only on init and resets
    const mappedRuleInitial = useMemo(() => {
      return ruleFormMappers.mapApiRuleToRuleDraft({
        rule,
        predefinedProduct,
        entityId,
        availableRegions,
      });
    }, [rule, predefinedProduct]);

    // rule draft state, based on original mapped rule
    const [ruleDraft, setRuleDraft] = useState(mappedRuleInitial);

    const isEditingDuplicatedRule = mode === 'create' && !!rule;

    const [isDirty, setIsDirty] = useState(isEditingDuplicatedRule);

    // fetch rule settings by product, determines options for conditions, which apply when (different for augmented search)
    const ruleSettings = ruleDraft.product
      ? syteProductToRuleSettingsMap[ruleDraft.product]
      : undefined;

    const isShopMultiLocale = Boolean(availableRegions?.length);

    const { errors, validate, isValid } = useValidateSchema<
      Omit<RuleDraft, 'id' | 'entityId' | 'createdAt' | 'updatedAt'>
    >({
      schema: formValidationSchema,
      validateOnStart: false,
      initialData: mappedRuleInitial,
    });

    const canSubmit = isDirty && isValid;

    const validateRuleForm = useCallback(
      (ruleToValidate: RuleDraft) => {
        const skipValidation = mode === 'create' && !isDirty;

        if (skipValidation) return;

        validate({
          dataToValidate: ruleToValidate,
          context: {
            dataFieldsLookupTable,
            ruleSettings,
            availableRegions,
          },
        });
      },
      [dataFieldsLookupTable, ruleSettings, mode, isDirty, availableRegions]
    );

    // reset to the initial mapped rule
    useEffect(() => {
      setRuleDraft(mappedRuleInitial);

      validateRuleForm(mappedRuleInitial);
    }, [shopId, mappedRuleInitial, setRuleDraft, dataFieldsLookupTable]);

    useEffect(() => {
      onFormStatusChange({ canSubmit, isDirty, isValid });
    }, [canSubmit]);

    const updateIsDirty = (dirty: boolean) => {
      setIsDirty(dirty);
      dispatch(merchandisingRulesFormActions.notifyIsDirty({ isDirty: dirty }));
    };

    const onFieldChange = useCallback(
      (partialData: Partial<RuleDraft>) => {
        const newDraftState = { ...ruleDraft, ...partialData };

        if (!isEqual(newDraftState, ruleDraft)) {
          setRuleDraft(newDraftState);

          updateIsDirty(true);

          validate({
            dataToValidate: newDraftState,
            context: {
              dataFieldsLookupTable,
              ruleSettings,
              availableRegions,
            },
          });
        }
      },
      [
        ruleDraft,
        dataFieldsLookupTable,
        ruleSettings,
        setRuleDraft,
        updateIsDirty,
        validate,
        availableRegions,
      ]
    );

    const onProductFieldChange = useCallback(
      ({ product }: Pick<RuleDraft, 'product'>) => {
        const { weight, appliedDateRange } = { ...ruleDraft };

        onFieldChange({
          subRules: [ruleFormMappers.createEmptyCondition()],
          searchCondition: rulesFormInitialData.searchCondition,
          sourceCondition: rulesFormInitialData.sourceCondition,
          weight,
          appliedDateRange,
          action: MerchandisingRuleTypes.RuleAction.Promote,
          product,
          redirectRule: rulesFormInitialData.redirectRule,
        });
      },
      [ruleDraft, onFieldChange]
    );

    const onLocaleFieldChange = useCallback(
      ({ regions }) => {
        onFieldChange({
          regions,
        });
      },
      [onFieldChange]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          async submit() {
            if (!isValid) {
              return;
            }

            if (mode === 'create') {
              await (
                dispatch(
                  merchandisingRulesFormActions.createMerchandisingRule({
                    shopId,
                    variantId,
                    ruleDraft: ruleDraft as Required<RuleDraft>,
                  })
                ) as any
              ).unwrap();
            } else {
              await (
                dispatch(
                  merchandisingRulesFormActions.updateMerchandisingRule({
                    shopId,
                    variantId,
                    ruleDraft: ruleDraft as Required<RuleDraft>,
                  })
                ) as any
              ).unwrap();
            }

            updateIsDirty(false);
          },
        };
      },
      [mode, ruleDraft, shopId, variantId, isValid]
    );

    const { isP2PActionDisabled } = useValidateP2PRulesLimit({
      shopId,
      isExistingP2PRule:
        mappedRuleInitial.action === MerchandisingRuleTypes.RuleAction.PinToPosition,
      variantId,
      dispatch,
    });

    useEffect(() => {
      if (isEditingDuplicatedRule && isDirty) {
        dispatch(merchandisingRulesFormActions.notifyIsDirty({ isDirty }));
      }
    }, [isEditingDuplicatedRule]);

    const shouldShowRegionForm = isReadOnlyExperiment
      ? ruleDraft.regions.length !== 0
      : isShopMultiLocale && Boolean(ruleDraft.product);

    const isCategoryPage = ruleDraft.product === SyteProductType.Collections && !entityId;

    return (
      <form>
        <RuleFormGeneralSection
          errors={errors}
          name={ruleDraft.name}
          kpi={ruleDraft.kpi}
          onFieldChange={onFieldChange}
          isReadOnly={isReadOnly}
          rulesKpiOptions={kpiOptions}
        />
        {!predefinedProduct && (
          <RuleFormSyteProductSection
            product={ruleDraft.product}
            onFieldChange={onProductFieldChange}
            isReadOnly={mode === 'edit' || isReadOnly}
            errors={errors}
            entityId={ruleDraft?.entityId}
            featureToggles={featureToggles}
          />
        )}
        {shouldShowRegionForm && (
          <RuleFormRegionSection
            availableRegions={availableRegions || []}
            regions={ruleDraft.regions}
            isReadOnly={isReadOnly}
            onFieldChange={onLocaleFieldChange}
            errors={errors}
          />
        )}

        {!!ruleSettings && (
          <RuleFormConditionsSection
            searchCondition={ruleDraft.searchCondition}
            sourceCondition={ruleDraft.sourceCondition}
            filterByCondition={ruleDraft.filterByCondition}
            subRules={ruleDraft.subRules}
            redirectRule={ruleDraft.redirectRule}
            action={ruleDraft.action}
            weight={ruleDraft.weight}
            subRulesConditionTypes={ruleSettings?.subRulesConditionTypes || []}
            conditionTypesOptions={ruleSettings?.conditionTypesOptions || {}}
            conditionTypes={ruleSettings.conditionTypes}
            dispatch={dispatch}
            dataFieldsLookupTable={dataFieldsLookupTable}
            getDataFieldByName={getDataFieldByName}
            shopId={shopId}
            onFieldChange={onFieldChange}
            errors={errors}
            isReadOnly={isReadOnly}
            actions={ruleSettings.actions}
            isAiTagsEnabled={isAiTagsEnabled}
            entityId={ruleDraft?.entityId}
            isCategoryPage={isCategoryPage}
            categoryFilterRule={categoryFilterRule}
            isP2PActionDisabled={isP2PActionDisabled}
          />
        )}

        <RuleFormScheduleDateSection
          appliedDateRange={ruleDraft.appliedDateRange}
          onChange={onFieldChange}
          isReadOnly={isReadOnly}
        />
      </form>
    );
  }
);
