import { AccountTypes, AccountBuilder } from '../../service/types';

export class AccountsRequestBuilder {
  static getCreateAccountPayload(name: string): AccountBuilder.CreateAccountPayload {
    return { accountName: name };
  }

  static getSearchAccountsUrlParams({
    searchTerm,
    includeArchived = false,
    skip = 0,
    limit = 10,
  }: AccountTypes.SearchAccountsArgs): AccountBuilder.SearchAccountsPayload {
    return { searchTerm, activeOnly: includeArchived, skip, limit };
  }

  static getPartialAccountPayload(
    account: Partial<Omit<AccountTypes.Account, 'shops' | 'users' | 'createdAt'>>
  ): Partial<AccountTypes.Account> {
    return account;
  }
}
