import React from 'react';
import { LogHistoryPanel } from 'src/components-bl';
import { useAppDispatch, useAppSelector } from 'src/hooks';

export const LogHistoryPanelContainer = (): JSX.Element => {
  const { opened, logs, type, versionId } = useAppSelector(state => state.logHistoryPanel);
  const dispatch = useAppDispatch();

  return (
    <LogHistoryPanel
      dispatch={dispatch}
      opened={opened}
      logs={logs}
      type={type}
      versionId={versionId}
    />
  );
};
