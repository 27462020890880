import React from 'react';
import {
  AccordionTriggerGroup,
  FlexContainer,
  VisualEditorForm,
  VisualEditorSubmenu,
} from '../../VisualEditor.styles';
import {
  Accordion,
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../../components-dummy';
import { ShopperExperienceSelect } from '../../ShopperExperienceSelect';
import { Experience, Switch } from '../../Switch';
import { ShopFeature } from '../../../../../app-types';
import { SimilarItemsSettings } from '../../SimilarItems';
import { CollectionSettings } from '../../Collections';
import { DiscoveryButtonSettings } from '../../DiscoveryButton';
import { BrowsePlpSettings } from '../../BrowsePLP';
import { useChangeShopperExperience } from './useChangeShopperExperience';
import { useEditSelectedOffer } from '../../../hooks/useEditSelectedOffer';
import { StateController } from './useStateControllerRef';
import { ShopTheLookSettings } from '../../SimilarItems/ShopTheLookSettings';
import { useCollapse } from '../../../hooks/useCollapse';
import { PermittedRouteMap } from '../../../../../app-routes';
import { TextSearch } from '../../TextSearch/TextSearch';

const accordionId = 'shopper-experience-accordion';

interface ShopperExperienceProps {
  shopId: number;
  stateController: StateController<any>;
  permittedRouteMap: PermittedRouteMap;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
}

export function ShopperExperience({
  shopId,
  stateController,
  permittedRouteMap,
  resultsScreenRef,
}: ShopperExperienceProps): JSX.Element {
  const { expandedId, expand } = useCollapse(accordionId);
  const { experiences, selectedExperience, changeExperience } = useChangeShopperExperience(
    stateController.reset
  );
  const { selectedOffer, changeOffer } = useEditSelectedOffer();

  return (
    <VisualEditorForm>
      <Accordion expandedIds={expandedId} onSelectionChanged={expand}>
        <Accordion.Item id={accordionId} observeHeight>
          <Accordion.Item.Header>
            <FlexContainer>
              <AccordionTriggerGroup>
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
                  Select Experience
                </Typography>
                <Icon className={!expandedId ? 'closed' : ''} name={AvailableIcons.RightArrow} />
              </AccordionTriggerGroup>
            </FlexContainer>
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <VisualEditorSubmenu>
              <ShopperExperienceSelect
                shopId={shopId}
                experiences={experiences}
                value={selectedExperience}
                onChange={changeExperience}
                permittedRouteMap={permittedRouteMap}
              />
              <Switch>
                <Experience feature={ShopFeature.SimilarItems}>
                  <SimilarItemsSettings
                    shopId={shopId}
                    offer={selectedOffer}
                    onOfferChange={changeOffer}
                    stateController={stateController}
                    selectedExperience={selectedExperience}
                    permittedRouteMap={permittedRouteMap}
                  />
                </Experience>
                <Experience feature={ShopFeature.ShopTheLook}>
                  <ShopTheLookSettings
                    shopId={shopId}
                    offer={selectedOffer}
                    onOfferChange={changeOffer}
                    stateController={stateController}
                    selectedExperience={selectedExperience}
                    permittedRouteMap={permittedRouteMap}
                  />
                </Experience>
                <Experience feature={ShopFeature.Collections}>
                  <CollectionSettings
                    shopId={shopId}
                    offer={selectedOffer}
                    onOfferChange={changeOffer}
                    stateController={stateController}
                    selectedExperience={selectedExperience}
                    permittedRouteMap={permittedRouteMap}
                  />
                </Experience>
                <Experience feature={ShopFeature.DiscoveryButton}>
                  <DiscoveryButtonSettings
                    shopId={shopId}
                    offer={selectedOffer}
                    onOfferChange={changeOffer}
                    stateController={stateController}
                    selectedExperience={selectedExperience}
                    permittedRouteMap={permittedRouteMap}
                  />
                </Experience>
                <Experience feature={ShopFeature.BrowsePLP}>
                  <BrowsePlpSettings
                    shopId={shopId}
                    stateController={stateController}
                    selectedExperience={selectedExperience}
                    permittedRouteMap={permittedRouteMap}
                  />
                </Experience>
                <Experience feature={ShopFeature.AugmentedSearch}>
                  <TextSearch
                    shopId={shopId}
                    stateController={stateController}
                    permittedRouteMap={permittedRouteMap}
                    selectedExperience={selectedExperience}
                    resultsScreenRef={resultsScreenRef}
                  />
                </Experience>
              </Switch>
            </VisualEditorSubmenu>
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </VisualEditorForm>
  );
}
