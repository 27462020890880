import { createReducer } from '@reduxjs/toolkit';
import { UserTypes } from 'src/services';
import { GlobalReducerState } from '../app-state-types';
import { userFormActions } from '../components-bl';
import { rootContainerActions } from '../root-container/Actions';
import { loginActions, shopRoutesActions } from '../containers';
import { navigationActions } from '../middleware-actions';

const initialState: GlobalReducerState = {
  loggedInUser: undefined,
  isFinishedAuthorizingUser: false,
  isFinishedLoadingUserAccount: false,
  appLoading: false,
  isDirty: false,
  confirm: undefined,
  redirectedFromLogin: false,
  firstRoute: undefined,
  domainEventsWSConnected: false,
  permittedRoutes: undefined,
  permittedRouteMap: undefined,
};

export const globalReducer = createReducer(initialState, builder => {
  builder
    .addCase(shopRoutesActions.setAppLoading, (state, action) => {
      return { ...state, appLoading: action.payload.appLoading };
    })
    .addCase(rootContainerActions.domainEventsWebSocketConnection, (state, action) => {
      return { ...state, domainEventsWSConnected: action.payload.connected };
    })
    .addCase(rootContainerActions.setFirstRoute, (state, action) => {
      return { ...state, firstRoute: action.payload.url };
    })
    .addCase(rootContainerActions.setPermittedRoutes, (state, action) => {
      const { permittedRouteMap, permittedRoutes } = action.payload;
      return { ...state, permittedRouteMap, permittedRoutes };
    })
    .addCase(rootContainerActions.me.pending, state => {
      return {
        ...state,
        appLoading: true,
      };
    })
    .addCase(loginActions.login.fulfilled, (state, action) => {
      const user = action.payload?.user as UserTypes.User | undefined;
      return {
        ...state,
        loggedInUser: user,
        isFinishedAuthorizingUser: true,
        redirectedFromLogin: true,
      };
    })
    .addCase(rootContainerActions.me.fulfilled, (state, action) => {
      const user = action.payload?.user as UserTypes.User | undefined;
      return {
        ...state,
        loggedInUser: user,
        isFinishedAuthorizingUser: true,
      };
    })
    .addCase(rootContainerActions.me.rejected, state => {
      return {
        ...state,
        appLoading: false,
        isFinishedAuthorizingUser: true,
      };
    })
    .addCase(userFormActions.updateUser.fulfilled, (state, action) => {
      const user = action.payload?.user as UserTypes.User | undefined;
      const loggedInUserUpdated = user?.userId === state.loggedInUser?.userId;

      return {
        ...state,
        loggedInUser: loggedInUserUpdated ? user : state.loggedInUser,
      };
    })
    .addCase(rootContainerActions.initApp.pending, state => {
      return { ...state, appLoading: true };
    })
    .addCase(rootContainerActions.initApp.fulfilled, (state, action) => {
      // if it's a shop route, let <ShopRoutes> container handle the appLoading, otherwise finish loading.
      if (!action.payload.isShopRoute) {
        return { ...state, appLoading: false, isFinishedLoadingUserAccount: true };
      }

      return { ...state, isFinishedLoadingUserAccount: true };
    })
    .addCase(rootContainerActions.initApp.rejected, state => {
      return { ...state, appLoading: false, isFinishedLoadingUserAccount: true };
    })
    .addCase(rootContainerActions.confirmNav, (state, action) => {
      return {
        ...state,
        isDirty: !action.payload.confirm.leave,
        confirm: undefined,
      };
    })
    .addCase(navigationActions.confirmNav, (state, action) => {
      return { ...state, confirm: action.payload };
    })
    // Listen to all NotifyIsDirty Actions
    .addMatcher(
      action => {
        return action.type.indexOf('NotifyIsDirty') > -1;
      },
      (state, action) => {
        return { ...state, isDirty: action.payload.isDirty };
      }
    )
    // TODO: need to see why originally added in old redux structure and if still relevant, perhaps for re-rerender. has no affect on redux toolkit actions since they end with "rejected"
    .addMatcher(
      action => {
        return action.type.indexOf('_ERROR') > -1;
      },
      state => {
        return { ...state };
      }
    );
});
