import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { Collection } from '../../../../components-bl/Collections/types';

function parseCollectionDates(collectionRaw: Collection): Collection {
  const collection = { ...collectionRaw };
  ['createdAt', 'updatedAt', 'startedAt', 'endedAt'].forEach(key => {
    if (collection[key as keyof Collection]) {
      collection[key as keyof Collection] = parseISO(
        collection[key as keyof Collection] as string
      ) as never;
    }
  });

  return collection;
}

export function mapCollectionsResponse({ data }: AxiosResponse): Array<Collection> {
  return data.collections.map((collection: Collection) => parseCollectionDates(collection));
}
export function mapCreateCollectionResponse({ data }: AxiosResponse): Collection {
  return parseCollectionDates(data) as Collection;
}

export function mapCollectionResponse({ data }: AxiosResponse<Collection>): Collection {
  return parseCollectionDates(data);
}
