import React from 'react';
import { useDispatch } from 'react-redux';
import { EditCollectionRulesContainer } from './EditCollectionRulesContainer';
import { CollapseDrawer } from '../CollapseDrawer/CollapseDrawer';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { PermittedRouteMap } from '../../../../app-routes';
import { useGetDefaultCollection } from '../../../../containers/CreateCollection/useGetDefaultCollection';

interface Props {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  className: string;
  isCreateMode?: boolean;
}

export const CreateCollectionRules = ({
  shopId,
  permittedRouteMap,
  className,
  isCreateMode,
}: Props): JSX.Element | null => {
  const { defaultCollection: currentCollection } = useGetDefaultCollection(shopId);
  const appDispatch = useDispatch();
  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  return (
    <CollapseDrawer
      open
      className={className}
      onClose={() => navigateToVisualEditorRoute({ shopId })}
    >
      <EditCollectionRulesContainer
        shopId={shopId}
        appDispatch={appDispatch}
        permittedRouteMap={permittedRouteMap}
        currentCollection={currentCollection}
        isCreateMode={isCreateMode}
      />
    </CollapseDrawer>
  );
};
