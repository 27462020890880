import { LogHistoryPanelAction, LogHistoryPanelActionTypes } from 'src/components-bl';
import { AppHeaderAction } from 'src/containers';
import { LogHistoryPanelReducerState } from '../app-state-types';

const defaultState: LogHistoryPanelReducerState = {
  logs: [],
  versionId: undefined,
  type: undefined,
  opened: false,
};

export const logHistoryPanelReducer = (
  state = defaultState,
  action?: LogHistoryPanelAction | AppHeaderAction
): LogHistoryPanelReducerState => {
  switch (action?.type) {
    case LogHistoryPanelActionTypes.SetOpened:
      return {
        ...state,
        opened: action.payload.opened,
      };

    default:
      return state;
  }
};
