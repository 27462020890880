import { DomainEntityPath } from 'src/services';

export enum VariantFeature {
  RankingStrategy = 'rankingStrategy',
  MerchandisingRules = 'merchandisingRules',
  ShopTheLookSettings = 'shopTheLookSettings',
  BoughtTogetherSettings = 'boughtTogetherSettings',
  RecentlyViewedSettings = 'recentlyViewedSettings',
  PersonalizationSettings = 'personalizationSettings',
  RecEnginesGeneralSettings = 'recEnginesGeneralSettings',
  SimilarItemsSettings = 'similarItemsSettings',
  EnableUiTest = 'enableUiTest',
  DiscoveryBannerSettings = 'discoveryBannerSettings',
  ResultsModalSettings = 'resultsModalSettings',
}

export const featureTranslationMap = {
  [VariantFeature.RankingStrategy]: 'Ranking Strategy',
  [VariantFeature.MerchandisingRules]: 'Merchandising Rules',
  [VariantFeature.ShopTheLookSettings]: 'Recommendation Engines - Shop The Look',
  [VariantFeature.BoughtTogetherSettings]: 'Recommendation Engines - Bought Together',
  [VariantFeature.RecentlyViewedSettings]: 'Recommendation Engines - Recently Viewed',
  [VariantFeature.PersonalizationSettings]: 'Recommendation Engines - Personalization',
  [VariantFeature.RecEnginesGeneralSettings]: 'Recommendation Engines - General Settings',
  [VariantFeature.SimilarItemsSettings]: 'Recommendation Engines - Similar Items',
  [VariantFeature.DiscoveryBannerSettings]: 'Visual Search - Discovery Banner',
  [VariantFeature.EnableUiTest]: 'Ui Test',
  [VariantFeature.ResultsModalSettings]: 'Results Modal Settings',
};

export const featureDomainEntitiesMap = {
  [VariantFeature.RankingStrategy]: [
    DomainEntityPath.AugmentedSearchRanking,
    DomainEntityPath.CollectionGlobalRanking,
    DomainEntityPath.DiscoveryButtonRanking,
    DomainEntityPath.PersonalizationRanking,
    DomainEntityPath.RecentlyViewedRanking,
    DomainEntityPath.ShopTheLookRanking,
    DomainEntityPath.SimilarItemsRanking,
    DomainEntityPath.VisualSearchRanking,
  ],
  [VariantFeature.MerchandisingRules]: [DomainEntityPath.MerchandisingRules],
  [VariantFeature.ShopTheLookSettings]: [DomainEntityPath.ShopTheLookSettings],
  [VariantFeature.BoughtTogetherSettings]: [DomainEntityPath.BoughtTogetherSettings],
  [VariantFeature.RecentlyViewedSettings]: [DomainEntityPath.RecentlyViewedSettings],
  [VariantFeature.PersonalizationSettings]: [DomainEntityPath.PersonalizationSettings],
  [VariantFeature.RecEnginesGeneralSettings]: [DomainEntityPath.RecEnginesGeneralSettings],
  [VariantFeature.SimilarItemsSettings]: [DomainEntityPath.SimilarItemsSettings],
  [VariantFeature.DiscoveryBannerSettings]: [DomainEntityPath.DiscoveryBannerSettings],
  [VariantFeature.EnableUiTest]: [DomainEntityPath.EnableUiTest],
  [VariantFeature.ResultsModalSettings]: [DomainEntityPath.ResultsModal],
};

export const variantFeatureChanges = {
  [VariantFeature.RankingStrategy]: 0,
  [VariantFeature.MerchandisingRules]: 0,
  [VariantFeature.ShopTheLookSettings]: 0,
  [VariantFeature.BoughtTogetherSettings]: 0,
  [VariantFeature.RecentlyViewedSettings]: 0,
  [VariantFeature.PersonalizationSettings]: 0,
  [VariantFeature.RecEnginesGeneralSettings]: 0,
  [VariantFeature.SimilarItemsSettings]: 0,
  [VariantFeature.DiscoveryBannerSettings]: 0,
  [VariantFeature.EnableUiTest]: 0,
  [VariantFeature.ResultsModalSettings]: 0,
};
