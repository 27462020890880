import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  editExistingCatalogModalContainerActions,
  useCatalogManagerWSActions,
} from 'src/containers';
import { catalogFormActions } from 'src/components-bl/CatalogManager/CatalogForm';
import { catalogFormContainerActions } from 'src/containers/CatalogManager/CatalogFormContainer';
import { catalogManagerContainerActions } from 'src/containers/CatalogManager/CatalogManagerContainer.actions';
import { CatalogFeedUploadLogContainerActions } from 'src/containers/CatalogManager/CatalogFeedUploadLogContainer/CatalogFeedUploadLogContainer.actions';
import { CatalogUploadErrorReportListContainerActions } from 'src/containers/CatalogManager/CatalogUploadErrorReportListContainer';
import { CatalogManagerReducerState } from '../app-state-types';
import { catalogsListPageActions } from '../components-bl/CatalogManager';

const initialState: CatalogManagerReducerState = {
  catalogs: [],
  selectedPlanCatalog: undefined,
  selectedConnectedCatalog: undefined,
};

export const catalogManagerReducer = createReducer(initialState, builder => {
  builder.addCase(useCatalogManagerWSActions.create, (state, action) => {
    const existingCatalog = state.catalogs?.find(
      catalog => catalog.id === action.payload.catalog.id
    );

    if (!existingCatalog) {
      return { ...state, catalogs: [action.payload.catalog, ...(state.catalogs || [])] };
    }
    return state;
  });
  builder.addCase(useCatalogManagerWSActions.update, (state, action) => {
    const updatedCatalogs = state.catalogs?.map(catalog => {
      if (catalog.id === action.payload.catalog.id) {
        return action.payload.catalog;
      }
      return catalog;
    });

    return { ...state, catalogs: updatedCatalogs };
  });

  builder.addCase(useCatalogManagerWSActions.delete, (state, action) => {
    const updatedCatalogs = state.catalogs?.filter(catalog => catalog.id !== action.payload.id);

    return { ...state, catalogs: updatedCatalogs };
  });

  builder.addCase(catalogsListPageActions.deleteCatalog.fulfilled, (state, action) => {
    const catalogsWithoutDeleted = state.catalogs?.filter(
      catalog => catalog.id !== action.meta.arg.id
    );
    return { ...state, catalogs: catalogsWithoutDeleted };
  });

  builder.addCase(catalogsListPageActions.getCatalogs.pending, state => {
    return { ...state, catalogs: [] };
  });

  builder.addCase(catalogsListPageActions.getCatalogs.rejected, state => {
    return { ...state, catalogs: [] };
  });
  builder.addCase(catalogFormContainerActions.getSingleCatalog.fulfilled, (state, action) => {
    return { ...state, selectedPlanCatalog: action.payload.selectedCatalog };
  });
  builder.addCase(catalogFormActions.resetSelectedCatalog, state => {
    return { ...state, selectedPlanCatalog: undefined };
  });
  builder.addCase(
    CatalogFeedUploadLogContainerActions.getCatalogFeedUploadFileContent.fulfilled,
    (state, action) => {
      return { ...state, catalogFeedUploadLogs: action.payload.catalogFeedUploadLog };
    }
  );
  builder.addCase(
    CatalogFeedUploadLogContainerActions.getCatalogFeedUploadFileContent.pending,
    state => {
      return { ...state, catalogFeedUploadLogs: undefined };
    }
  );
  builder.addCase(
    CatalogUploadErrorReportListContainerActions.getCatalogUploadErrorReport.fulfilled,
    (state, action) => {
      return { ...state, catalogUploadErrorReports: action.payload.catalogUploadErrorReports };
    }
  );
  builder.addCase(CatalogUploadErrorReportListContainerActions.resetData, state => {
    return { ...state, catalogUploadErrorReports: undefined, catalogFeedUploadLogs: undefined };
  });
  builder.addCase(
    editExistingCatalogModalContainerActions.getCatalog.fulfilled,
    (state, action) => {
      return { ...state, selectedConnectedCatalog: action.payload };
    }
  );
  builder.addCase(editExistingCatalogModalContainerActions.resetCatalog, state => {
    return { ...state, selectedConnectedCatalog: undefined };
  });

  builder.addMatcher(
    isAnyOf(
      catalogsListPageActions.getCatalogs.fulfilled,
      catalogManagerContainerActions.getCatalogs.fulfilled
    ),
    (state, action) => {
      return { ...state, catalogs: action.payload.catalogs };
    }
  );
});
