import { CustomInspirationsGallery } from 'src/services';
import { CustomInspirationGalleryDraft } from './types';

export class GalleryMapper {
  static mapGalleryToDraft(
    gallery?: CustomInspirationsGallery
  ): CustomInspirationGalleryDraft | undefined {
    return gallery
      ? {
          displayTitle: gallery.displayTitle,
          layout: gallery.layout,
          images: gallery.images,
          placement: gallery.placement,
          showSimilarProductWhenUnavailable: gallery.showSimilarProductWhenUnavailable,
        }
      : undefined;
  }
}
