import React from 'react';
import { RuleGroup } from 'src/components-bl/Collections';
import {
  OnDeleteRuleGroupCallback,
  OnAddRuleCallback,
  OnDeleteRuleCallback,
  OnEditRuleCallback,
} from '../types';
import { CollectionRuleGroup } from '../CollectionRuleGroup';
import { CollectionRulesDataFieldsFetchService } from '../../../../../services/src/service/collections/CollectionRulesDataFieldsFetchService';
import { ParseJoiValidateResponse } from '../../../../../utils';

interface CollectionRulesGroupListProps {
  shopId: number;
  ruleGroups: RuleGroup[];
  onDeleteRuleGroup: OnDeleteRuleGroupCallback;
  onAddRule: OnAddRuleCallback;
  onDeleteRule: OnDeleteRuleCallback;
  onEditRule: OnEditRuleCallback;
  dataFieldsFetchService: CollectionRulesDataFieldsFetchService;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const CollectionRulesGroupList: React.FC<CollectionRulesGroupListProps> = ({
  ruleGroups,
  onDeleteRuleGroup,
  onAddRule,
  onDeleteRule,
  onEditRule,
  dataFieldsFetchService,
  shopId,
  errors,
}) => {
  return (
    <>
      {ruleGroups.map((ruleGroup, index) => (
        <CollectionRuleGroup
          shopId={shopId}
          key={ruleGroup.id}
          ruleGroup={ruleGroup}
          index={index}
          onDeleteRuleGroup={onDeleteRuleGroup}
          onAddRule={onAddRule}
          onDeleteRule={onDeleteRule}
          onEditRule={onEditRule}
          dataFieldsFetchService={dataFieldsFetchService}
          errors={errors}
        />
      ))}
    </>
  );
};
