import { useCallback, useEffect, useRef, useState } from 'react';
import { visualEditorActions, visualEditorFetchOffersActions } from '../state';
import { ContentType, DiscoveryButtonPayload } from '../types';
import { useDebounce } from '../../../components-dummy';
import { useAppSelector } from '../../../hooks';
import { useGetDataFieldsToDisplay } from './useGetDataFieldsToDisplay';
import { useVisualEditorProvider } from '../context';

const minimumInputLength = 12;
export const useDiscoveryButtonSettings = (shopId: number) => {
  const { selectedOffer, offers } = useAppSelector(({ visualEditor }) => visualEditor);
  const { appDispatch, dispatchOffersThunk } = useVisualEditorProvider();
  const { sku, imageUrl } = selectedOffer || {};
  const [customImageURL, setCustomImageURL] = useState<string>('');
  const thunkPromiseRef = useRef(null as { abort: () => void } | null);

  const { fields } = useGetDataFieldsToDisplay({ shopId });

  const syteURLReferrer = `${window.location.origin}/shops/${shopId}/visual-editor`;
  const selectedImage = customImageURL || imageUrl;

  const fetchDiscoveryOffers = useCallback((payload: DiscoveryButtonPayload) => {
    const thunkPromise: any = dispatchOffersThunk(
      visualEditorFetchOffersActions.getDiscoveryButtonItems,
      payload
    );
    thunkPromiseRef.current = thunkPromise;
  }, []);

  const fetchOffersDebounced = useDebounce(fetchDiscoveryOffers, 300);

  const onCustomImageChanged = (searchValueTrimmed: string) => {
    thunkPromiseRef.current?.abort();

    if (searchValueTrimmed.length > minimumInputLength) {
      fetchOffersDebounced({
        imageUrl: searchValueTrimmed,
        syteURLReferrer,
        shopId,
        sku,
        fields,
        colorVariantFields: fields,
        sizeVariantFields: fields,
      });
      return;
    }

    if (imageUrl) {
      fetchOffersDebounced({
        imageUrl,
        syteURLReferrer,
        shopId,
        sku,
        fields,
        colorVariantFields: fields,
        sizeVariantFields: fields,
      });
      return;
    }

    fetchOffersDebounced.cancel();
  };

  /* Actions on offer removed */
  useEffect(() => {
    if (selectedOffer || !offers.length) return;
    appDispatch(visualEditorActions.setContentType(ContentType.SelectExperience));
  }, [selectedOffer]);

  useEffect(() => {
    if (!selectedImage) return;
    fetchDiscoveryOffers({
      shopId,
      imageUrl: selectedImage,
      syteURLReferrer,
      sku,
      fields,
      colorVariantFields: fields,
      sizeVariantFields: fields,
    });
  }, [selectedImage]);

  useEffect(() => {
    if (!imageUrl && !customImageURL) {
      appDispatch(visualEditorActions.setContentType(ContentType.SelectExperience));
      return;
    }
    onCustomImageChanged(customImageURL);
  }, [customImageURL]);

  return {
    updateImageURL: setCustomImageURL,
  };
};
