import { Store } from 'redux';
import { NotificationType } from 'src/components-dummy';
import { toastActions } from 'src/containers';
import { toastMiddlewareActionMethods } from 'src/middleware-actions';
import { rootContainerActions } from 'src/root-container/Actions';
import { APITypes, HttpStatusCode } from 'src/services';
import { errorActionsBlackList, actionsToIgnoreUnauthorized } from './actions-blacklist';
import { AnyErrorAction } from './types';
import { getErrorMessageKey } from './util';

const httpCodesForLogouts = new Set([HttpStatusCode.Unauthorized]);

function shouldForceLogout(action: AnyErrorAction, code: number | undefined): boolean {
  const isCriticalError = action.type === rootContainerActions.initApp.rejected.type;

  return !!(
    isCriticalError ||
    (code && !actionsToIgnoreUnauthorized.has(action.type) && httpCodesForLogouts.has(code))
  );
}

function logOutIfRelevant(action: AnyErrorAction, code: number | undefined, store: Store) {
  const shouldLogout = shouldForceLogout(action, code);
  if (shouldLogout) {
    store.dispatch(toastMiddlewareActionMethods.forceLogout() as any);
  }
}

export function errorActionsHandler(action: AnyErrorAction, store: Store): void {
  // If an action was cancelled, it means it's not relevant and we don't need to notify anything to user.
  if (action.meta?.aborted) {
    return;
  }

  const code = (action.payload?.error as APITypes.ApiError | undefined)?.code;
  const errorKey = (action.payload?.error as APITypes.ApiError | undefined)?.errorKey;

  const isForceLogoutRequired = shouldForceLogout(action, code);

  const shouldCreateToast = !errorActionsBlackList.has(action.type) && !isForceLogoutRequired;
  if (shouldCreateToast) {
    const messageKey = getErrorMessageKey(action);
    if (messageKey) {
      store.dispatch(
        toastActions.enqueue({
          toast: {
            errorKey,
            messageKey,
            type: NotificationType.Error,
            timestamp: new Date().getTime(),
            customMessage: (action.payload as any)?.customMessage,
          },
        })
      );
    }
  }
  // TODO: move to new middleware
  logOutIfRelevant(action, code, store);
}
