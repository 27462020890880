import styled from '@emotion/styled';
import { EllipsisWithTooltip, Typography } from '../../../../components-dummy';

export const ProductCardContainer = styled.div`
  padding: 12px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  position: relative;
`;

export const ButtonGroup = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
`;

export const ProductContent = styled.div<{ isMarginTop: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  margin-top: ${props => (props.isMarginTop ? '24px' : '0')};
  text-transform: capitalize;
`;

export const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto) minmax(min-content, 2fr);
  grid-template-areas: 'price . gender . category';
  column-gap: 5px;
`;

export const Title = styled(EllipsisWithTooltip)`
  max-width: 34ch !important;
`;

export const Gender = styled(Typography)`
  grid-area: gender;
`;

export const Category = styled(EllipsisWithTooltip)`
  line-height: 14px !important;
  grid-area: category;
`;

export const Divider = styled.div`
  border-right: 1px solid #1e1e1e;
  grid-area: .;
`;

export const ProductDetailsDivider = styled.div`
  border-bottom: 1px solid #dcdcdc;
`;

export const ImageContainer = styled.div`
  display: flex;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
