import { Auth, Users } from 'src/services';
import { createAsyncThunk } from '@reduxjs/toolkit';

const usersService = new Users();
const authService = new Auth();

export const loginActions = {
  login: createAsyncThunk(
    'LoginContainer/Login',
    async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
      try {
        const user = await authService.login({ email, password });

        return { user };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
  forgotPassword: createAsyncThunk(
    'LoginContainer/ForgotPassword',
    async ({ email }: { email: string }, { rejectWithValue }) => {
      try {
        await usersService.forgotPassword(email);
        return undefined;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
};
