import {
  CollectionRulesActionPayload,
  CollectionRulesActionTypes,
  CollectionRulesErrorActionPayload,
  CollectionRulesGetRuleGroupsErrorAction,
  CollectionRulesGetRuleGroupsRequestAction,
  CollectionRulesGetRuleGroupsSuccessAction,
  CollectionRulesSaveDiffErrorAction,
  CollectionRulesSaveDiffSuccessAction,
  CollectionRulesSaveDiffRequestAction,
  SaveDiffActionPayload,
  SaveDiffSuccessActionPayload,
} from './types';
import { Dispatch } from '../../../../types';
import { collectionsService } from '../../../../../services/src/service/collections';

const collectionRulesActions = {
  getRuleGroupsRequest(
    payload: CollectionRulesActionPayload
  ): CollectionRulesGetRuleGroupsRequestAction {
    return {
      type: CollectionRulesActionTypes.GetRuleGroupsRequest,
      payload,
    };
  },

  getRuleGroupsSuccess(
    payload: CollectionRulesActionPayload
  ): CollectionRulesGetRuleGroupsSuccessAction {
    return {
      type: CollectionRulesActionTypes.GetRuleGroupsSuccess,
      payload,
    };
  },

  getRuleGroupsError(
    payload: CollectionRulesErrorActionPayload
  ): CollectionRulesGetRuleGroupsErrorAction {
    return {
      type: CollectionRulesActionTypes.GetRuleGroupsError,
      payload,
    };
  },

  saveDiffRequest(payload: SaveDiffActionPayload): CollectionRulesSaveDiffRequestAction {
    return {
      type: CollectionRulesActionTypes.SaveDiffRequest,
      payload,
    };
  },

  saveDiffSuccess(payload: SaveDiffSuccessActionPayload): CollectionRulesSaveDiffSuccessAction {
    return {
      type: CollectionRulesActionTypes.SaveDiffSuccess,
      payload,
    };
  },

  saveDiffError(payload: CollectionRulesErrorActionPayload): CollectionRulesSaveDiffErrorAction {
    return {
      type: CollectionRulesActionTypes.SaveDiffError,
      payload,
    };
  },
};

export const collectionRulesActionMethods = {
  getRuleGroups(payload: CollectionRulesActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(collectionRulesActions.getRuleGroupsRequest(payload));
        const ruleGroups = await collectionsService.getRuleGroups({
          shopId: payload.shopId,
          collectionId: payload.collectionId,
        });
        dispatch(collectionRulesActions.getRuleGroupsSuccess({ ...payload, ruleGroups }));
      } catch (error) {
        dispatch(collectionRulesActions.getRuleGroupsError({ error }));
      }
    };
  },
  saveCollectionRulesDiff(payload: SaveDiffActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(collectionRulesActions.saveDiffRequest(payload));
        const ruleGroups = await collectionsService.saveCollectionRulesDiff({
          shopId: payload.shopId,
          collectionId: payload.collectionId,
          collectionName: payload.collectionName,
          body: payload.diff,
        });
        dispatch(collectionRulesActions.saveDiffSuccess({ ruleGroups }));
      } catch (error) {
        dispatch(collectionRulesActions.saveDiffError({ error }));
      }
    };
  },
};
