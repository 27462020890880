import React from 'react';
import { useDispatch } from 'react-redux';
import { Rule, RuleGroup } from '../../../types';
import { useRuleValue } from './useRuleValue';
import { ConditionValueInput } from '../../../../MerchandisingRules/components/DataFieldsCondition/ConditionValueInput';
import { ParseJoiValidateResponse } from '../../../../../utils';

interface RuleValueProps {
  data: Rule;
  onChange: (rule: Rule) => void;
  shopId: number;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const RuleValue: React.FC<RuleValueProps> = ({ data, shopId, onChange, errors }) => {
  const { getDataFieldByName, conditionData, onValuesChange } = useRuleValue({
    data,
    onChange,
  });
  const dispatch = useDispatch();
  const selectedDataField = getDataFieldByName(conditionData.dataField);
  return (
    <ConditionValueInput
      getDataFieldByName={getDataFieldByName}
      dataFieldTypes={selectedDataField?.types}
      conditionData={conditionData}
      onChange={onValuesChange}
      errors={errors}
      dispatch={dispatch}
      shopId={shopId}
    />
  );
};
