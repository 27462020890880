import { collectionsService } from './collections';
import { FieldSource } from '../../../../components-bl/Collections/EditCollectionRules/CollectionRules/FieldSourceDropdown/constants';
import { ShopDataField } from '../types';

export class CollectionRulesDataFieldsFetchService {
  private cache: Record<string, ShopDataField[]> = {};

  constructor(
    private shopId: number,
    private collectionId: string
  ) {}

  async getDataFields(fieldSource: FieldSource): Promise<ShopDataField[]> {
    if (this.cache[fieldSource]) {
      return this.cache[fieldSource];
    }

    const response = await collectionsService.getDataFields({
      shopId: this.shopId,
      collectionId: this.collectionId,
      fieldSource,
    });
    this.cache[fieldSource] = response;

    return response;
  }
}
