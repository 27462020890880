import React from 'react';
import { ImageTagWithId } from 'src/services';
import { AvailableIcons, EllipsisWithTooltip } from 'src/components-dummy';
import { TagIconStyled, TagStyled } from './TagsSection.styles';

interface TagProps {
  tag: ImageTagWithId;
  removeTagById: (id: string) => void;
}

export const Tag = ({ tag, removeTagById }: TagProps): JSX.Element => {
  const onRemove = () => {
    removeTagById(tag.id);
  };
  return (
    <TagStyled>
      <EllipsisWithTooltip tooltipText={tag.title}>{tag.title}</EllipsisWithTooltip>

      <TagIconStyled name={AvailableIcons.Close} onClick={onRemove} />
    </TagStyled>
  );
};
