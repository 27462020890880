import React, { useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';
import { FormCard, TypographyType, TypographyVariant } from 'src/components-dummy';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';
import { CodeSection } from 'src/components-dummy/CodeSection';
import { CodeColorOption } from 'src/components-dummy/CodeSection/CodeSection.styles';
import {
  JsSnippetCaptionStyled,
  JsSnippetSubTitleStyled,
  JsSnippetSectionStyled,
} from './JsSnippetSection.styles';
import { Dispatch } from '../../types';
import { jsSnippetSectionActions } from './Actions';

interface JsSnippetSectionProps {
  apiKeys?: IApiKey[];
  shopId?: number;
  dispatch: Dispatch;
}

function getJSSnippetParts({
  apiKey = 'API_KEY',
  shopId = 'SHOP_ID',
}: {
  apiKey: string | undefined;
  shopId: number | string | undefined;
}): { text: string | number; color: CodeColorOption }[] {
  return [
    { text: '<script ', color: 'green' },
    { text: 'src="', color: 'pink' },
    { text: 'https://cdn.syteapi.com/assets/imajs/imajs.js?a=', color: 'blue' },
    { text: shopId, color: 'black' },
    { text: '&sig=', color: 'blue' },
    { text: apiKey, color: 'black' },
    { text: '" async', color: 'pink' },
    { text: '></script> ', color: 'green' },
  ];
}

function getOldestNonExpiredApiKey(apiKeys: IApiKey[]): IApiKey | undefined {
  const now = new Date();
  const nonExpiredKeys = apiKeys.filter(apiKey => {
    if (!apiKey.expiration) return true;

    return now < apiKey.expiration;
  });

  if (nonExpiredKeys.length === 0) return undefined;

  const orderedKeys = orderBy(nonExpiredKeys, ['createdAt']);

  return orderedKeys[0];
}

export const JsSnippetSection = ({
  apiKeys = [],
  shopId,
  dispatch,
}: JsSnippetSectionProps): JSX.Element => {
  const apiKeySnippetParts = useMemo(() => {
    const oldestNotExpiredApiKey = getOldestNonExpiredApiKey(apiKeys);

    return getJSSnippetParts({
      apiKey: oldestNotExpiredApiKey?.key,
      shopId,
    });
  }, [shopId, apiKeys]);

  const onCopySnippetClick = useCallback(() => {
    if (!apiKeySnippetParts) return;

    dispatch(
      jsSnippetSectionActions.notification({ customMessage: 'Snippet copied to clipboard' })
    );
  }, [apiKeySnippetParts, dispatch]);

  return (
    <JsSnippetSectionStyled>
      <FormCard.Title>Syte Experiences JS Snippet</FormCard.Title>

      <FormCard.Content>
        <JsSnippetSubTitleStyled type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
          Install the Syte experiences snippet on your website.
        </JsSnippetSubTitleStyled>
        <JsSnippetCaptionStyled
          type={TypographyType.Paragraph}
          variant={TypographyVariant.SmallRegular}
        >
          Place the following Javascript snippet in your head tag of every page, directly or via
          your tag manager.
        </JsSnippetCaptionStyled>

        <CodeSection codeParts={apiKeySnippetParts} enableCopy onCopyClick={onCopySnippetClick} />
      </FormCard.Content>
    </JsSnippetSectionStyled>
  );
};
