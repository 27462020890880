import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import {
  ILexiconItem,
  ILexiconTagsQueryAPI,
  ILexiconTagsPaginationAPI,
  LexiconSortBy,
} from 'src/services';
import { UserRoles } from 'src/services/src/service/types/users';
import { Dispatch } from 'src/components-bl';
import { css } from '@emotion/react';
import { TableV2, useSelectAll, SelectAllModes } from 'src/components-dummy/TableV2';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { CHANGES_FILTER_ALL_VALUES, tableColumns } from './LexiconTable.config';
import {
  ILexiconTableBodyRow,
  ILexiconTableSortState,
  LexiconHeaderFilters,
  LexiconTableHeaderMenuFilters,
  LexiconTableQueryState,
} from './types';
import { mapRowLexiconItemBase, mapTableQueryToApiQueryOptions } from './LexiconTable.helpers';
import { LexiconTableHeaderColumns } from './Components/LexiconTableHeaderColumns';
import { LexiconTableBody } from './Components/LexiconTableBody';
import { LexiconTableHeaderFilters } from './Components/LexiconTableHeaderFilters';
import {
  LexiconTableWrapperStyled,
  TableBodyWrapperStyled,
  TableFiltersHeadStyled,
} from './LexiconTable.styles';
import { lexiconTableActions } from './LexiconTable.actions';
import { LexiconBulkActionsRow } from './Components/LexiconBulkActionsRow';
import { OnSortChange } from './Components/LexiconTableSortableColumnHeader';

interface LexiconTableProps {
  shopId: number;
  locale: string;
  dispatch: Dispatch;
  lexiconItems?: ILexiconItem[];
  lexiconTotalItems?: number;
  showApplyAll: boolean;
  editThematicTagRoute?: string;
  editLexiconRuleRoute?: string;
  userRole?: UserRoles;
  tagType: LexiconTagType;
}

const columns = Object.values(tableColumns);

// Filters that are below the header names (Auto completes / Search)
const headerTagFieldFiltersInitialState: LexiconHeaderFilters = {
  categoriesTranslations: [],
  attributesTranslations: [],
  valuesTranslations: [],
  masterTag: '',
};

// Menu filters that next to the header (not below it), currently only "Changes"
const headerMenuFiltersInitialState: LexiconTableHeaderMenuFilters = {
  changes: CHANGES_FILTER_ALL_VALUES,
};

type Pagination = { skip: number; limit: number };

/**
 * Pagination constants
 */
const PAGE_SIZE = 250;
const SKIP = PAGE_SIZE;
const LIMIT = PAGE_SIZE;

const paginationInitialState: Pagination = {
  skip: 0,
  limit: LIMIT,
};

export interface LexiconTableApiRef {
  fetchLexiconTags: () => void;
  refetchTagsOnCurrentPage: () => Promise<ILexiconTagsPaginationAPI>;
}

const tableQueryInitialState: LexiconTableQueryState = {
  headerTagFieldFilters: headerTagFieldFiltersInitialState,
  menuFilters: headerMenuFiltersInitialState,
  pagination: paginationInitialState,
  sortOptions: {
    columnName: LexiconSortBy.Category,
    sortAscending: true,
  },
};

export const LexiconTable = React.memo(
  forwardRef<LexiconTableApiRef, LexiconTableProps>(
    (
      {
        shopId,
        locale,
        dispatch,
        lexiconItems,
        lexiconTotalItems,
        showApplyAll,
        editThematicTagRoute,
        editLexiconRuleRoute,
        userRole,
        tagType,
      },
      ref
    ): JSX.Element => {
      const isSyteAdmin = userRole === UserRoles.SyteAdmin;

      const [tableQueryState, setTableQueryState] = useState(tableQueryInitialState);

      // Mapped table query's filters, sort and pagination for fetching lexicons, and bulk operations (which only need filters)
      // Not using useMemo as the mapping function need to be called also in callbacks, which would make it call the mapping both in callback and then after render.
      // TODO: mapping to API should happen in each function/action that calls service, not in a state/memo
      const [apiQueryOptions, setApiQueryOptions] = useState(() =>
        mapTableQueryToApiQueryOptions(tableQueryState)
      );

      const thunkFetchTagsPromiseRef = useRef(null as { abort: () => void } | null);

      const fetchLexiconTags = useCallback(
        (apiQueryParameter?: ILexiconTagsQueryAPI) => {
          thunkFetchTagsPromiseRef.current?.abort?.();

          const actualQuery = apiQueryParameter || apiQueryOptions;
          const promise = dispatch(
            lexiconTableActions.getLexiconTags({
              shopId,
              locale,
              tagType,
              ...actualQuery,
            })
          ) as any;

          thunkFetchTagsPromiseRef.current = promise;

          return promise.unwrap();
        },
        [shopId, locale, tagType, apiQueryOptions, dispatch]
      );

      /**
       * Updates query options and then fetches lexicons tags accordingly
       * Sets both tableQueryState & apiQueryOptions
       */
      const updateTableQuery = useCallback(
        async (newTableQuery: LexiconTableQueryState) => {
          setTableQueryState(newTableQuery);

          const newApiQuery = mapTableQueryToApiQueryOptions(newTableQuery);
          setApiQueryOptions(newApiQuery);

          try {
            const result = await fetchLexiconTags(newApiQuery);
            return result;
          } catch (error) {
            console.error(error);
            return undefined;
          }
        },
        [setTableQueryState, setApiQueryOptions, fetchLexiconTags]
      );

      const { onSelectAll, selectAllState, onSelectRow, onUnselectAll, includedIds, excludedIds } =
        useSelectAll();

      const onSelectLexiconRow = useCallback(
        ({ lexiconItemId: rowId, isSelected }: { lexiconItemId: string; isSelected: boolean }) => {
          onSelectRow({ rowId, isSelected });
        },
        [onSelectRow]
      );

      const onSortChange: OnSortChange = useCallback(
        (newSortState: ILexiconTableSortState) => {
          updateTableQuery({
            ...tableQueryState,
            sortOptions: {
              sortAscending: newSortState.sortAscending,
              columnName: newSortState.columnName,
            },
            pagination: paginationInitialState,
          });
        },
        [updateTableQuery, tableQueryState]
      );

      const tableData: {
        data: ILexiconTableBodyRow[];
        partialDataByTagKey: Record<string, Pick<ILexiconItem, 'isThematic' | 'tagKey'>>;
      } = useMemo(() => {
        return (lexiconItems || []).reduce(
          (
            accumulator: {
              data: ILexiconTableBodyRow[];
              partialDataByTagKey: Record<string, Pick<ILexiconItem, 'isThematic' | 'tagKey'>>;
            },
            item: ILexiconItem
          ) => {
            const category = mapRowLexiconItemBase(item.category);
            const attribute = mapRowLexiconItemBase(item.attribute);
            const value = mapRowLexiconItemBase(item.value);

            const isRenameSupported = !item.isThematic && !item.isLexiconRule;

            // Doesn't applied on thematic tag or lexicon rule
            const hasRenamed = isRenameSupported
              ? category.hasChanged || attribute.hasChanged || value.hasChanged
              : false;

            let selected = false;

            if (selectAllState.checked) {
              if (selectAllState.mode === SelectAllModes.AllSelected) {
                selected = !excludedIds.has(item.tagKey);
              } else {
                selected = includedIds.has(item.tagKey);
              }
            }

            let masterTag;
            if (item.isThematic) {
              masterTag = 'Thematic tag';
            } else if (item.isLexiconRule) {
              masterTag = 'Lexicon Rule Tag';
            } else {
              masterTag = `${item.category.key}:${item.attribute.key}:${item.value.key}`;
            }

            const mappedItem: ILexiconTableBodyRow = {
              selected,
              category,
              attribute,
              value,
              masterTag,
              changes: {
                hasRenamed,
                isOn: item.include,
              },
              actions: true,
              originalData: item,
            };

            accumulator.data.push(mappedItem);
            accumulator.partialDataByTagKey[item.tagKey] = {
              tagKey: item.tagKey,
              isThematic: item.isThematic,
            };

            return accumulator;
          },
          { data: [], partialDataByTagKey: {} }
        );
      }, [lexiconItems, includedIds, excludedIds, selectAllState]);

      const tableOptions = useMemo(() => ({ columns, data: tableData.data }), [tableData]);

      // Include all current loaded items
      const currentPagination = useMemo(
        () => ({
          skip: 0,
          limit: lexiconItems?.length || LIMIT,
        }),
        [lexiconItems?.length]
      );

      /**
       * Scrolled to the end => fetch more items
       */
      const onLoadMoreItems = useCallback(() => {
        const { pagination } = tableQueryState;
        // incase - a few chunks (pageSizes) loaded -> skip is total items else take the next chunk
        const totalLoadedItems = pagination.skip + pagination.limit;
        const newSkip = pagination.skip === 0 ? totalLoadedItems : pagination.skip + SKIP;

        const newPaginationState = { skip: newSkip, limit: LIMIT };

        updateTableQuery({
          ...tableQueryState,
          pagination: newPaginationState,
        });
      }, [tableQueryState]);

      /**
       * Filters - header filter popup menu - (published / included / renamed)
       */
      const onHeaderMenuFiltersChange = useCallback(
        (updatedFilters: LexiconTableHeaderMenuFilters) => {
          updateTableQuery({
            ...tableQueryState,
            menuFilters: updatedFilters,
            pagination: paginationInitialState,
          });
        },
        [updateTableQuery, tableQueryState]
      );

      /**
       * Filters - categories / attributes / master tag
       */
      const onHeaderFiltersChange = useCallback(
        (partialFilters: Partial<LexiconHeaderFilters>) => {
          const newTagFilters = { ...tableQueryState.headerTagFieldFilters, ...partialFilters };

          updateTableQuery({
            ...tableQueryState,
            headerTagFieldFilters: newTagFilters,
            pagination: paginationInitialState,
          });
        },
        [updateTableQuery, tableQueryState, tableQueryState.headerTagFieldFilters]
      );

      const onBulkActionSuccess = useCallback(async (): Promise<ILexiconTagsPaginationAPI> => {
        onUnselectAll?.();

        const result = updateTableQuery({
          ...tableQueryState,
          pagination: currentPagination,
        });

        return result;
      }, [updateTableQuery, currentPagination]);

      const refetchTagsOnCurrentPage = useCallback(async (): Promise<ILexiconTagsPaginationAPI> => {
        const result = updateTableQuery({ ...tableQueryState, pagination: currentPagination });

        return result;
      }, [updateTableQuery, tableQueryState, currentPagination]);

      useEffect(() => {
        updateTableQuery(tableQueryInitialState);

        return () => {
          thunkFetchTagsPromiseRef?.current?.abort?.();
          dispatch(lexiconTableActions.reset());
        };
      }, [shopId, locale]);

      useImperativeHandle(
        ref,
        () => {
          return { fetchLexiconTags, refetchTagsOnCurrentPage };
        },
        [fetchLexiconTags, refetchTagsOnCurrentPage]
      );

      return (
        <LexiconTableWrapperStyled>
          <TableV2<ILexiconTableBodyRow> options={tableOptions}>
            {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
              const headerGroup = headerGroups[0];

              return (
                <>
                  <div>
                    <LexiconBulkActionsRow
                      selectAllState={selectAllState}
                      includedTagKeys={includedIds}
                      excludedTagKeys={excludedIds}
                      onUnselectAll={onUnselectAll}
                      onBulkActionSuccess={onBulkActionSuccess}
                      totalItemsCount={lexiconTotalItems || 0}
                      shopId={shopId}
                      locale={locale}
                      filters={apiQueryOptions.filters}
                      dispatch={dispatch}
                      isSyteAdmin={isSyteAdmin}
                      tagType={tagType}
                    />
                    <TableV2.Head {...headerGroup.getHeaderGroupProps()} key='headers-columns'>
                      <LexiconTableHeaderColumns
                        includedIds={includedIds}
                        excludedIds={excludedIds}
                        headers={headerGroup.headers}
                        selectAllState={selectAllState}
                        onSelectAll={onSelectAll}
                        menuFilters={tableQueryState.menuFilters}
                        onHeaderMenuFiltersChange={onHeaderMenuFiltersChange}
                        sortState={tableQueryState.sortOptions}
                        onSortChange={onSortChange}
                      />
                    </TableV2.Head>
                    <TableFiltersHeadStyled
                      {...headerGroup.getHeaderGroupProps()}
                      key='headers-filters'
                    >
                      <LexiconTableHeaderFilters
                        headers={headerGroup.headers}
                        dispatch={dispatch}
                        shopId={shopId}
                        locale={locale}
                        tagType={tagType}
                        filters={tableQueryState.headerTagFieldFilters}
                        onChange={onHeaderFiltersChange}
                      />
                    </TableFiltersHeadStyled>
                  </div>
                  <TableV2.Body
                    {...getTableBodyProps()}
                    css={css`
                      overflow-y: clip;
                    `}
                  >
                    <TableBodyWrapperStyled>
                      <LexiconTableBody
                        showApplyAll={showApplyAll}
                        locale={locale}
                        rows={rows}
                        shopId={shopId}
                        tagType={tagType}
                        hasNextPage={
                          !!(
                            lexiconItems &&
                            lexiconTotalItems &&
                            lexiconItems?.length < lexiconTotalItems
                          )
                        }
                        isLoading={!lexiconItems}
                        onItemChange={refetchTagsOnCurrentPage}
                        onSelectRow={onSelectLexiconRow}
                        prepareRow={prepareRow}
                        dispatch={dispatch}
                        onLoadMoreItems={onLoadMoreItems}
                        editThematicTagRoute={editThematicTagRoute}
                        editLexiconRuleRoute={editLexiconRuleRoute}
                        isSyteAdmin={isSyteAdmin}
                      />
                    </TableBodyWrapperStyled>
                  </TableV2.Body>
                </>
              );
            }}
          </TableV2>
        </LexiconTableWrapperStyled>
      );
    }
  )
);
