import { User } from '../users';
import { DomainEntityPath } from '../versioning';

export enum NotificationSeverity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum NotificationType {
  MerchandisingRuleInvalidAiTag = 'merchandising_rules:invalid_ai_tag',
  AutomaticRedirectRulesNotPublished = 'merchandising_rules:automatic-redirect-rules-not-published',
  ShopCatalogConnect = 'shop_catalogs:connect',
  ShopCatalogCreate = 'shop_catalogs:create',
  ShopCatalogDelete = 'shop_catalogs:delete',
  LexiconPublish = 'lexicon:publish',
  VersionDraftPublish = 'shop-versions:draft-publish',
  VersionSystemPublish = 'shop-versions:system-publish',
  AugmentedSearchTuningChange = 'augmented_search.tuning:validation-change',
}

export interface Notification<T = unknown> {
  id: string;
  domainEntity?: DomainEntityPath;
  type?: NotificationType;
  shopId?: number;
  severity: NotificationSeverity;
  content: T;
  createdByUser?: User;
  message: string;
  isViewed: boolean;
  createdAt: Date;
}
