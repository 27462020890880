import React, { useRef } from 'react';
import { Page } from 'src/components-dummy';
import { AccountPlansForm } from 'src/components-bl';
import { useAppDispatch, useAppSelector } from 'src/hooks';

export const AccountPlansContainer = (): JSX.Element => {
  const currentAccount = useAppSelector(state => state.account.current);

  const dispatch = useAppDispatch();

  const formButtonRef = useRef<HTMLDivElement>(null);

  return (
    <Page className='syte-account-plans-container'>
      <Page.Header>
        <Page.Title>Plans</Page.Title>
        <div className='ml-auto syte-account-plans-container-header-buttons' ref={formButtonRef} />
      </Page.Header>
      <Page.Content>
        {currentAccount && (
          <AccountPlansForm
            account={currentAccount}
            dispatch={dispatch}
            formSaveButtonRef={formButtonRef}
          />
        )}
      </Page.Content>
    </Page>
  );
};
