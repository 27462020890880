import React, { memo } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { IndexedOffers, PreviewMerchRule } from '../../../types';
import { UpdateMerchRuleArgs } from '../../../../MerchandisingRules/components/MerchandisingRuleForm';
import { GridLayoutContainer } from './GridLayout.styles';
import { GridSkeleton } from './GridSkeleton';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import { useToggleAllItems } from '../../../hooks/useToggleAllItems';
import { useDragAndDropItems } from '../../../hooks/useDragAndDropItems';
import { DraggableProductTile } from '../ProductTile/DraggableProductTile';

export const GridLayoutEditMode = memo(
  ({
    offers,
    dataFieldsToDisplay,
    specialDataFieldsToDisplay,
    itemsCountPerRow,
    selectedRule,
    submitStrategyForm,
    shopId,
    uniqueByField,
  }: {
    offers: IndexedOffers;
    dataFieldsToDisplay: DataFieldToDisplay[];
    specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
    itemsCountPerRow: number;
    selectedRule: PreviewMerchRule | null;
    submitStrategyForm: (payload: UpdateMerchRuleArgs) => void;
    shopId: number;
    uniqueByField?: string;
  }): JSX.Element => {
    const { items, toggleAllItems } = useToggleAllItems(offers);
    const { draggedAndDroppedItems, onDrag, onDrop } = useDragAndDropItems(
      items,
      selectedRule,
      submitStrategyForm,
      shopId,
      uniqueByField
    );

    if (!draggedAndDroppedItems.length) return <GridSkeleton itemsCountPerRow={itemsCountPerRow} />;

    return (
      <DndProvider backend={HTML5Backend}>
        <GridLayoutContainer itemsCountPerRow={itemsCountPerRow}>
          {draggedAndDroppedItems.map((offer, index) => (
            <DraggableProductTile
              key={offer.sku}
              offer={offer}
              index={index}
              dataFieldsToDisplay={dataFieldsToDisplay}
              specialDataFieldsToDisplay={specialDataFieldsToDisplay}
              isOpen={offer?.isOpen || false}
              toggleAllItems={toggleAllItems}
              onDrag={onDrag}
              onDrop={onDrop}
            />
          ))}
        </GridLayoutContainer>
      </DndProvider>
    );
  }
);
