import React from 'react';
import { AvailableIcons, Icon, Skeleton } from 'src/components-dummy';
import { SkuConfiguration } from './types';
import { ImageStyled, StartAdornmentStyled } from './SkuAutoSuggestion.styles';

export const InputStartAdornment = ({
  configuration,
  loading,
  error,
}: {
  configuration: SkuConfiguration | undefined;
  loading: boolean;
  error: boolean;
}): JSX.Element => {
  let content = null;

  if (loading) {
    content = <Skeleton css={{ height: '100%', width: '100%' }} />;
  } else if (error) {
    content = <Icon name={AvailableIcons.CircleWarning} />;
  } else if (configuration?.imageUrl) {
    content = <ImageStyled src={configuration?.imageUrl} />;
  }

  return <StartAdornmentStyled>{content} </StartAdornmentStyled>;
};
