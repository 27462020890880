import React, {
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useDragAndDrop } from 'src/hooks/useDragAndDrop';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';

import {
  ActionIcon,
  AvailableIcons,
  Icon,
  NumericInput,
  TypographyType,
  TypographyVariant,
} from '../../../../../../../components-dummy';
import {
  convertConditionValueToRaw,
  dataFieldsToPartialSubRule,
  partialSubRuleToDataFields,
} from '../../../../DataFieldsCondition/DataFieldsCondition.helpers';

import { MAX_POSITION_VALUE, MIN_POSITION_VALUE } from './constants';

import { Dispatch } from '../../../../../../types';
import { RuleDraftCondition } from '../../../MerchandisingRuleForm.config';
import {
  ConditionValueInnerType,
  DataFieldConditionDataInnerType,
  DataFieldConditionDataType,
} from '../../../../DataFieldsCondition/DataFieldsCondition.types';
import { DataFieldLookupTableValue } from '../../../../useDataFieldsLookupTable';

import {
  ConditionRowDataStyled,
  ConditionRowErrors,
  ConditionRowStyled,
  ConditionText,
  DragHandleWrapperStyled,
} from '../RuleFormConditionsSection.styles';
import { SkuAutoSuggestion } from './SkuAutoSuggestion/SkuAutoSuggestion';
import { SkuConfiguration } from './SkuAutoSuggestion/types';

const PIN_TO_POSITION_RULE_DRAG_DROP_TYPE = 'PIN_TO_POSITION_RULE';

interface Props {
  rule: RuleDraftCondition;
  onConditionChange: (changedCondition: DataFieldConditionDataType) => void;
  onDeleteCondition: (conditionToDelete: RuleDraftCondition) => void;
  getDataFieldByName: (name: string | undefined) => DataFieldLookupTableValue | undefined;
  dispatch: Dispatch;
  shopId: number;
  rowErrorLabels: JSX.Element[];
  isValid: {
    isSkuValid: boolean;
    isPositionValid: boolean;
    isSkuUnique: boolean;
    isPositionUnique: boolean;
  };
  index: number;
  onDrag: (sourceIndex: number, targetIndex: number) => void;
  onBlur: (conditionId?: string | number) => void;
  onDragEnd: ({ didDrop }: { didDrop: boolean }) => void;
  isSelected: boolean;
  onSelected: (conditionId?: number | string) => void;
  skuConfiguration?: SkuConfiguration | null;
}

export const PinToPositionRule = memo(
  ({
    rule,
    onConditionChange,
    onDeleteCondition,
    getDataFieldByName,
    dispatch,
    shopId,
    rowErrorLabels,
    isValid,
    index,
    onDrag,
    onDragEnd,
    isSelected,
    onSelected,
    onBlur: onBlurExternal,
    skuConfiguration,
  }: Props): JSX.Element => {
    const conditionData = useMemo(() => partialSubRuleToDataFields(rule), [rule]);

    const { conditionType, dataField: dataFieldName, position } = conditionData;
    const selectedDataField = getDataFieldByName(dataFieldName);

    const positionInputRef = useRef<HTMLInputElement>(null);
    const rowDataRef = useRef<HTMLDivElement>(null);

    const onStateChange = useCallback(
      (partialData: Partial<DataFieldConditionDataInnerType>) => {
        const updatedState = { ...conditionData, ...partialData };

        onConditionChange?.(dataFieldsToPartialSubRule(updatedState));
      },
      [conditionData, onConditionChange]
    );

    const [positionInputValue, setPositionInputValue] = useState(position);

    const onBlur = useCallback(() => onBlurExternal(rule.tempId), [rule.tempId, onBlurExternal]);

    useEffect(() => {
      if (position !== positionInputValue) {
        setPositionInputValue(position);
      }
    }, [position, setPositionInputValue]);

    const onSkuChange = useCallback(
      (value: ConditionValueInnerType) => {
        const rawValues = convertConditionValueToRaw(
          conditionType,
          value,
          selectedDataField?.types
        );

        onStateChange({
          conditionValue: rawValues,
        });
      },
      [conditionType, onStateChange, selectedDataField?.types]
    );

    const { dragElementRef, dragPreviewRef, isDragging } = useDragAndDrop<HTMLDivElement>({
      acceptKey: PIN_TO_POSITION_RULE_DRAG_DROP_TYPE,
      index,
      onDrag,
      onDragEnd,
    });

    const skuValue = (rule.values?.[0] ?? '') as string;

    const onPositionChange = useCallback(
      (value?: number) => {
        setPositionInputValue(value);

        onStateChange({
          position: value,
        });
      },
      [onStateChange]
    );

    const onDelete = () => {
      onDeleteCondition(rule);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (!position) return;
      if (e.key === 'ArrowUp' && position < MAX_POSITION_VALUE) {
        onPositionChange(position + 1);
      }
      if (e.key === 'ArrowDown' && position > MIN_POSITION_VALUE) {
        onPositionChange(position - 1);
      }
      if (e.key === 'Enter') {
        positionInputRef.current?.blur();
      }
    };

    const { isPositionValid, isPositionUnique, isSkuValid, isSkuUnique } = isValid;

    const onDragHandleClick = useCallback(() => {
      if (!isSelected) {
        onSelected(rule.tempId);
      }
    }, [index]);

    useOnClickOutside(rowDataRef, () => {
      onSelected();
    });

    return (
      <ConditionRowStyled ref={dragPreviewRef}>
        <ConditionRowDataStyled ref={rowDataRef} isSelected={isSelected}>
          <DragHandleWrapperStyled
            ref={dragElementRef}
            isDragging={isDragging}
            onMouseDown={onDragHandleClick}
          >
            <Icon name={AvailableIcons.DragHandleVerticalDouble} />
          </DragHandleWrapperStyled>
          <ConditionText
            type={TypographyType.Body}
            variant={TypographyVariant.SmallMedium}
            minWidth={3}
          >
            Pin
          </ConditionText>
          <SkuAutoSuggestion
            shopId={shopId}
            sku={skuValue}
            onChange={onSkuChange}
            dispatch={dispatch}
            error={!(isSkuValid && isSkuUnique)}
            skuConfiguration={skuConfiguration}
          />
          <ConditionText
            type={TypographyType.Body}
            variant={TypographyVariant.SmallMedium}
            minWidth={5}
          >
            to position
          </ConditionText>
          <NumericInput
            inputRef={positionInputRef}
            value={positionInputValue}
            onChange={onPositionChange}
            onKeyDown={onKeyDown}
            minNumericValue={MIN_POSITION_VALUE}
            maxNumericValue={MAX_POSITION_VALUE}
            error={!isPositionValid || !isPositionUnique}
            autoComplete='off'
            onBlur={onBlur}
          />
          <ActionIcon iconName={AvailableIcons.TrashCan} onClick={onDelete} />
        </ConditionRowDataStyled>
        {rowErrorLabels.length > 0 ? (
          <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
        ) : null}
      </ConditionRowStyled>
    );
  }
);
