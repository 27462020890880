import React from 'react';
import { startCase } from 'lodash';
import { EntityDiffSchema } from '../../types';
import { Rule, RuleGroup } from '../../../../Collections';
import { DiffCardChangeValue } from '../../../DiffsCard';
import { MerchandisingRuleSubType } from '../../../../../services/src/service/types/shops';

export const convertConditionToText = (condition: Rule): JSX.Element => {
  const { field, fieldSource, operator, values } = condition;
  const fieldSourceName = startCase(fieldSource);
  const fieldName = field?.displayName;
  const [from, to] = values;

  let operatorText: string;
  let valueText = '';
  switch (operator) {
    case MerchandisingRuleSubType.Contains: {
      operatorText = 'Contains';
      valueText = (values as string[]).map(value => `“${value?.toString?.() || ''}”`).join(' OR ');
      break;
    }
    case MerchandisingRuleSubType.DoesNotContain: {
      operatorText = 'Does Not Contain';
      valueText = (values as string[]).map(value => `“${value?.toString?.() || ''}”`).join(' OR ');
      break;
    }
    case MerchandisingRuleSubType.Equals:
      operatorText = 'Equals';
      valueText = `“${from}”`;
      break;
    case MerchandisingRuleSubType.HasValue:
      operatorText = 'Has Value';
      valueText = '';
      break;
    case MerchandisingRuleSubType.DoesNotHaveValue:
      operatorText = 'Does Not Have Value';
      valueText = '';
      break;
    case MerchandisingRuleSubType.EqualsSource:
      operatorText = 'Equals Source';
      break;
    case MerchandisingRuleSubType.HigherThan:
      operatorText = 'Higher Than';
      valueText = `${from}`;
      break;
    case MerchandisingRuleSubType.LowerThan:
      operatorText = 'Lower Than';
      valueText = `${to}`;
      break;
    case MerchandisingRuleSubType.IsBetween:
      operatorText = 'is Between';
      valueText = `${from} to ${to}`;
      break;
    case MerchandisingRuleSubType.RelativeRange:
      operatorText = 'Relative Range of';
      valueText = `${from}% and ${to}%`;
      break;
    default:
      operatorText = `<Invalid Condition>`;
  }

  return <>{`${fieldSourceName} ${fieldName} ${operatorText} ${valueText}`}</>;
};

export const renderCollectionRules = (rules: Rule[]): DiffCardChangeValue => {
  const rulesJsx = rules.map(rule => {
    const conditionText = convertConditionToText(rule);
    return (
      <span key={`key-${rule.id}`}>
        <strong>{conditionText}</strong>
        <br />
      </span>
    );
  });

  return <>{rulesJsx}</>;
};

export const collectionRulesDiffsSchema: EntityDiffSchema<RuleGroup, RuleGroup> = {
  rules: {
    displayName: 'Rules',
    getValue({ entity }: { entity: RuleGroup }): JSX.Element {
      return renderCollectionRules(entity.rules) as JSX.Element;
    },
  },
};
