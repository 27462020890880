import styled from '@emotion/styled';
import { ExpandableSectionTrigger } from '../../../../../ExpandableSectionTrigger';

export const ExpandableOptionsStyled = styled.div`
  flex: 1;
`;

export const ExpandableSectionTriggerStyled = styled(ExpandableSectionTrigger)`
  margin-top: 0;
`;
