import { SyteProductPlacement } from 'src/services/src/service/types/syte-products';
import { RuleGroup } from '../../../types';
import { CollectionRulesDiffWithShopId } from '../helpers/types';

export enum CollectionRulesActionTypes {
  GetRuleGroupsRequest = 'CollectionRules/GetRuleGroupsRequest',
  GetRuleGroupsSuccess = 'CollectionRules/GetRuleGroupsSuccess',
  GetRuleGroupsError = 'CollectionRules/GetRuleGroupsError',
  SaveDiffRequest = 'CollectionRules/SaveDiffRequest',
  SaveDiffSuccess = 'CollectionRules/SaveDiffSuccess',
  SaveDiffError = 'CollectionRules/SaveDiffError',
}

export interface CollectionRulesActionPayload {
  shopId: number;
  collectionId: string;
  ruleGroups?: RuleGroup[];
}

export interface CollectionRulesErrorActionPayload {
  error: unknown;
}

export interface CollectionRulesGetRuleGroupsRequestAction {
  type: typeof CollectionRulesActionTypes.GetRuleGroupsRequest;
  payload: CollectionRulesActionPayload;
}

export interface CollectionRulesGetRuleGroupsSuccessAction {
  type: typeof CollectionRulesActionTypes.GetRuleGroupsSuccess;
  payload: CollectionRulesActionPayload;
}

export interface CollectionRulesGetRuleGroupsErrorAction {
  type: typeof CollectionRulesActionTypes.GetRuleGroupsError;
  payload: CollectionRulesErrorActionPayload;
}

export interface SaveDiffActionPayload {
  shopId: number;
  collectionId: string;
  collectionName: string;
  diff: CollectionRulesDiffWithShopId;
}

export interface SaveDiffSuccessActionPayload {
  ruleGroups: RuleGroup[];
}

export interface CollectionRulesSaveDiffRequestAction {
  type: typeof CollectionRulesActionTypes.SaveDiffRequest;
  payload: SaveDiffActionPayload;
}

export interface CollectionRulesSaveDiffSuccessAction {
  type: typeof CollectionRulesActionTypes.SaveDiffSuccess;
  payload: SaveDiffSuccessActionPayload;
}

export interface CollectionRulesSaveDiffErrorAction {
  type: typeof CollectionRulesActionTypes.SaveDiffError;
  payload: CollectionRulesErrorActionPayload;
}

export type CollectionRulesAction =
  | CollectionRulesGetRuleGroupsRequestAction
  | CollectionRulesGetRuleGroupsSuccessAction
  | CollectionRulesGetRuleGroupsErrorAction
  | CollectionRulesSaveDiffRequestAction
  | CollectionRulesSaveDiffSuccessAction
  | CollectionRulesSaveDiffErrorAction;

export enum CollectionSettingsActionTypes {
  SaveRequest = 'CollectionSettings/SaveRequest',
  SaveSuccess = 'CollectionSettings/SaveSuccess',
  SaveError = 'CollectionSettings/SaveError',
}

export interface CollectionSettingsErrorActionPayload {
  error: unknown;
}

export interface SaveSettingsActionPayload {
  shopId: number;
  collectionId: string;
  name: string;
  placement: SyteProductPlacement;
  uniqueByField: string | undefined;
}

export interface SaveSettingsSuccessActionPayload {
  name: string;
  placement: SyteProductPlacement;
  uniqueByField: string | undefined;
}

export interface CollectionSettingsSaveRequestAction {
  type: typeof CollectionSettingsActionTypes.SaveRequest;
  payload: SaveSettingsActionPayload;
}

export interface CollectionSettingsSaveSuccessAction {
  type: typeof CollectionSettingsActionTypes.SaveSuccess;
  payload: SaveSettingsSuccessActionPayload;
}

export interface CollectionSettingsSaveErrorAction {
  type: typeof CollectionSettingsActionTypes.SaveError;
  payload: CollectionSettingsErrorActionPayload;
}

export type CollectionSettingsAction =
  | CollectionSettingsSaveRequestAction
  | CollectionSettingsSaveSuccessAction
  | CollectionSettingsSaveErrorAction;
