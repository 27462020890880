import React, { MutableRefObject } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { ImageSize } from 'src/components-dummy';
import { CustomInspirationsGalleryImage, ImageTagWithId } from 'src/services';
import { AddNewTagsPopUp, ProductTagPopUp } from './components';

export interface ProductTagsProps {
  image: CustomInspirationsGalleryImage;
  dispatch: Dispatch;
  shopId: number;
  onAddNewTag?: (newTag: ImageTagWithId) => void;
  removeTagById?: (id: string) => void;
  canAddNewTags: boolean;
  imageRef: MutableRefObject<HTMLImageElement | null>;
  imageSize: ImageSize | null;
  showTags: boolean;
  canDeleteTags: boolean;
}

export const ProductTags = ({
  image,
  dispatch,
  shopId,
  onAddNewTag,
  canAddNewTags,
  imageRef,
  imageSize,
  removeTagById,
  showTags,
  canDeleteTags,
}: ProductTagsProps): JSX.Element => {
  return (
    <>
      {canAddNewTags && !!onAddNewTag ? (
        <AddNewTagsPopUp
          image={image}
          dispatch={dispatch}
          shopId={shopId}
          onAddNewTag={onAddNewTag}
          imageRef={imageRef}
          imageSize={imageSize}
        />
      ) : null}

      {showTags &&
        image.tags.map(tag => {
          const onRemove = () => removeTagById?.(tag.id);
          return (
            <ProductTagPopUp
              key={tag.id}
              tag={tag}
              onRemove={onRemove}
              enableDelete={canDeleteTags}
              imageSize={imageSize}
              showPopUp={false}
            />
          );
        })}
    </>
  );
};
