import { SyteProductPlacement } from 'src/services/src/service/types/syte-products';
import { Collection, RuleGroup } from '../../types';

export enum CreateCollectionButtonActionTypes {
  CreateCollectionRequest = 'CreateCollectionButton/CreateCollectionRequest',
  CreateCollectionSuccess = 'CreateCollectionButton/CreateCollectionSuccess',
  CreateCollectionError = 'CreateCollectionButton/CreateCollectionError',
}

export interface CreateCollectionActionPayload {
  shopId: number;
  name: string;
  placement: SyteProductPlacement;
  ruleGroups: RuleGroup[];
}

export type CreateCollectionRequestActionPayload = CreateCollectionActionPayload;

export interface CreateCollectionSuccessActionPayload {
  collection: Collection;
}

export interface CreateCollectionErrorActionPayload {
  error: unknown;
}

export interface CreateCollectionRequestAction {
  type: typeof CreateCollectionButtonActionTypes.CreateCollectionRequest;
  payload: CreateCollectionRequestActionPayload;
}

export interface CreateCollectionSuccessAction {
  type: typeof CreateCollectionButtonActionTypes.CreateCollectionSuccess;
  payload: CreateCollectionSuccessActionPayload;
}

export interface CreateCollectionErrorAction {
  type: typeof CreateCollectionButtonActionTypes.CreateCollectionError;
  payload: CreateCollectionErrorActionPayload;
}

export type CreateCollectionButtonCreateCollectionAction =
  | CreateCollectionRequestAction
  | CreateCollectionSuccessAction
  | CreateCollectionErrorAction;
