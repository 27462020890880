import { useEffect } from 'react';
import { collectionsListActionMethods } from './actions';
import type { UseCollectionsListArguments } from './types';

export const useGetCollectionsList = ({ shopId, dispatch }: UseCollectionsListArguments): void => {
  useEffect(() => {
    if (shopId) {
      dispatch(collectionsListActionMethods.getCollectionsList({ shopId }));
    }
  }, [shopId]);
};
