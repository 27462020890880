import React from 'react';
import { ButtonStyled } from '../../../../../containers/MerchandisingRules/CreateMerchandisingRuleContainer/CreateMerchandisingRuleContainer.styles';
import { RuleFormFooter } from '../../../../MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter';

interface Props {
  canSubmit: boolean;
  onSaveChanges: VoidFunction;
  onCancel: VoidFunction;
  onCreate: VoidFunction;
  isCreateMode?: boolean;
}

export const EditCollectionRulesFooter = ({
  isCreateMode,
  onCancel,
  canSubmit,
  onSaveChanges,
  onCreate,
}: Props): JSX.Element => {
  return (
    <RuleFormFooter>
      <ButtonStyled variant='tertiary' onClick={onCancel}>
        Cancel
      </ButtonStyled>
      {isCreateMode ? (
        <ButtonStyled disabled={!canSubmit} variant='primary' onClick={onCreate}>
          Create
        </ButtonStyled>
      ) : (
        <ButtonStyled disabled={!canSubmit} variant='primary' onClick={onSaveChanges}>
          Save
        </ButtonStyled>
      )}
    </RuleFormFooter>
  );
};
