import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const ProductCardStyled = styled.div`
  display: flex;
  flex-direction: column;

  width: 200px;
  padding: 8px;

  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
  border-radius: 6px;
  gap: 8px;
  overflow: hidden;
`;

export const ImageWrapperStyled = styled.div`
  width: 100%;
  height: 200px;

  display: flex;
  justify-content: center;
`;

export const ImageStyled = styled.img`
  height: 100%;
`;

export const BottomRowStyled = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PriceTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.red};
`;

export const OriginalPriceTypography = styled(Typography)`
  text-decoration: line-through;
`;
