import { useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import { RuleAction } from 'src/components-bl/FilterCard/types';
import { merchandisingRulesFormActions } from './MerchandisingRuleForm.actions';

const MAX_P2P_RULES = 400;

export function useValidateP2PRulesLimit({
  shopId,
  isExistingP2PRule,
  variantId,
  dispatch,
}: {
  shopId: number;
  isExistingP2PRule: boolean;
  variantId?: string;
  dispatch: Dispatch;
}): { isP2PActionDisabled: boolean } {
  const [isP2PActionDisabled, setIsP2PActionDisabled] = useState(false);

  useEffect(() => {
    const skipP2PLimitValidation = isExistingP2PRule;

    if (!skipP2PLimitValidation) {
      (
        dispatch(
          merchandisingRulesFormActions.getRulesCountForValidation({
            shopId,
            variantId,
            action: [RuleAction.PinToPosition],
            skip: 0,
            limit: 1,
          })
        ) as any
      )
        .unwrap()
        .then((totalCount: number) => {
          setIsP2PActionDisabled(totalCount >= MAX_P2P_RULES);
        })
        .catch((error: any) => console.error(error));
    }
  }, [isExistingP2PRule, shopId, variantId, dispatch]);

  return { isP2PActionDisabled };
}
