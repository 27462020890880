import Joi from 'joi';
import { conditionTypeToConditionValueValidationSchema } from '../../../../MerchandisingRules/components/MerchandisingRuleForm/validation-schema';
import { MerchandisingRuleSubType } from '../../../../../services/src/service/types/shops';
import { FieldSource } from '../FieldSourceDropdown/constants';

const conditionValueValidationSchema = (
  Object.keys(conditionTypeToConditionValueValidationSchema) as MerchandisingRuleSubType[]
).reduce(
  (result, current) => {
    return result.when('operator', {
      is: current,
      then: conditionTypeToConditionValueValidationSchema[current],
    });
  },
  Joi.array().items().required().messages({ 'array.min': 'Values can not be empty' })
);

const collectionRuleValidationSchema = {
  fieldSource: Joi.string()
    .valid(...Object.values(FieldSource))
    .required()
    .messages({ 'any.required': 'Field source should not be empty' }),
  field: Joi.object().required().messages({ 'any.required': 'Data field should not be empty' }),
  operator: Joi.string()
    .required()
    .valid(...Object.values(MerchandisingRuleSubType))
    .messages({ 'any.required': 'Operator should not be empty' }),
  values: Joi.alternatives()
    .conditional('operator', {
      not: undefined,
      then: conditionValueValidationSchema,
      otherwise: Joi.array().required(),
    })
    .messages({ 'any.required': 'Values can not be empty' }),
};

export const validationSchema = {
  name: Joi.string().required().trim().min(1).max(50).messages({
    'string.empty': 'Name can not be empty',
    'string.min': 'Name should be at least 1 character long',
    'string.max': 'Name should be less then 50 characters long',
  }),
  ruleGroups: Joi.array()
    .items(
      Joi.object().keys({
        rules: Joi.array().items(Joi.object().keys(collectionRuleValidationSchema)),
      })
    )
    .min(1)
    .messages({ 'array.min': 'Please, add at least one rule group' }),
};
