import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { TableV2, TableV2InstanceProps, TextBox } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { tableColumns } from '../LexiconTable.config';
import { TableAutoCompleteWrapperStyled, TableTextBoxWrapperStyled } from '../LexiconTable.styles';
import { LexiconItemAutoComplete } from './LexiconItemAutoComplete';
import { LexiconHeaderFilters } from '../types';

interface LexiconTableHeaderFiltersProps {
  headers: TableV2InstanceProps<any>['headerGroups'][0]['headers'];
  dispatch: Dispatch;
  shopId: number;
  locale: string;
  tagType: LexiconTagType;
  filters: LexiconHeaderFilters;
  onChange: (partialFilters: Partial<LexiconHeaderFilters>) => void;
}

const debouncedMethodCall = debounce(func => {
  func();
}, 500);

export const LexiconTableHeaderFilters = ({
  headers,
  dispatch,
  shopId,
  locale,
  tagType,
  filters,
  onChange,
}: LexiconTableHeaderFiltersProps): JSX.Element => {
  const onChangeDebouncedCallback = useCallback(
    (partialFilters: Partial<LexiconHeaderFilters>) => {
      debouncedMethodCall(() => onChange(partialFilters));
    },
    [onChange]
  );

  /**
   * Category
   */
  const onCategoryChange = (updatedSelectedValues: string[]) => {
    onChange({ categoriesTranslations: updatedSelectedValues });
  };

  /**
   * Attribute
   */
  const onAttributeChange = (updatedSelectedValues: string[]) => {
    onChange({ attributesTranslations: updatedSelectedValues });
  };

  /**
   * Value
   */
  const onValueChange = (updatedSelectedValues: string[]) => {
    onChange({ valuesTranslations: updatedSelectedValues });
  };

  /**
   * Master tag
   */
  const [masterTagSearchTerm, setMasterTagSearchTerm] = useState(filters.masterTag || '');

  const onMasterTagSearchChange = (newText: string) => {
    setMasterTagSearchTerm(newText);

    const masterTagTrimmed = newText.trim();
    onChangeDebouncedCallback({ masterTag: masterTagTrimmed });
  };

  const renderHeaderColumn = ({ column }: { column: any }) => {
    switch (column.id) {
      case tableColumns.category.accessor:
        return (
          <TableAutoCompleteWrapperStyled>
            <LexiconItemAutoComplete
              dispatch={dispatch}
              onChange={onCategoryChange}
              tagField='category'
              selectedValues={filters.categoriesTranslations}
              shopId={shopId}
              locale={locale}
              tagType={tagType}
              parentFieldsFilters={{
                attributes: filters.attributesTranslations,
                values: filters.valuesTranslations,
              }}
            />
          </TableAutoCompleteWrapperStyled>
        );
      case tableColumns.attribute.accessor:
        return (
          <TableAutoCompleteWrapperStyled>
            <LexiconItemAutoComplete
              dispatch={dispatch}
              onChange={onAttributeChange}
              tagField='attribute'
              selectedValues={filters.attributesTranslations}
              shopId={shopId}
              locale={locale}
              tagType={tagType}
              parentFieldsFilters={{
                categories: filters.categoriesTranslations,
                values: filters.valuesTranslations,
              }}
            />
          </TableAutoCompleteWrapperStyled>
        );
      case tableColumns.value.accessor:
        return (
          <TableAutoCompleteWrapperStyled>
            <LexiconItemAutoComplete
              dispatch={dispatch}
              onChange={onValueChange}
              tagField='value'
              selectedValues={filters.valuesTranslations}
              parentFieldsFilters={{
                categories: filters.categoriesTranslations,
                attributes: filters.attributesTranslations,
              }}
              tagType={tagType}
              shopId={shopId}
              locale={locale}
            />
          </TableAutoCompleteWrapperStyled>
        );
      case tableColumns.masterTag.accessor:
        return (
          <TableTextBoxWrapperStyled>
            <TextBox
              onChange={onMasterTagSearchChange}
              value={masterTagSearchTerm}
              placeholder='Category:Attribute:Value'
            />
          </TableTextBoxWrapperStyled>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {headers.map(column => (
        <TableV2.HeadRow
          {...column.getHeaderProps()}
          width={column.width as string}
          minWidth={column.minWidth}
          maxWidth={column.maxWidth}
          key={column.id}
        >
          {renderHeaderColumn({ column })}
        </TableV2.HeadRow>
      ))}
    </>
  );
};
