import React, { MouseEventHandler, useCallback } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { useOnLongClick } from 'src/hooks/useOnLongClick';
import { CustomInspirationsGalleryImage } from 'src/services';
import { GalleryImage } from '../../GalleryImage';
import { GridItemStyled } from './GalleryGridItem.styles';

interface GalleryGridItemProps {
  image: CustomInspirationsGalleryImage;
  index: number;
  onImageClick: (imageId: string) => void;
  onAddTags: (imageId: string) => void;
  onRemoveImage: (imageId: string) => void;
  onImageDrag: (sourceIndex: number, targetIndex: number) => void;
  onImageDrop: () => void;
  dispatch: Dispatch;
  shopId: number;
  onImageLoaded: () => void;
}

export function GalleryGridItem({
  image,
  index,
  onAddTags,
  onRemoveImage,
  onImageDrag,
  onImageDrop,
  shopId,
  dispatch,
  onImageClick,
  onImageLoaded,
}: GalleryGridItemProps): JSX.Element {
  const onShortClick: MouseEventHandler = useCallback(() => {
    onImageClick(image.id);
  }, [image.id, onImageClick]);

  const {
    isLongClick: enableDrag,
    onClick,
    onMouseDown,
    onMouseUp,
  } = useOnLongClick({
    msForLongClick: 100,
    onShortClick,
  });

  return (
    <GridItemStyled
      index={index}
      disableDrag={!enableDrag}
      onGridItemDrag={onImageDrag}
      onGridItemDrop={onImageDrop}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <GalleryImage
        image={image}
        draggable={enableDrag}
        onAddTags={onAddTags}
        onRemoveImage={onRemoveImage}
        dispatch={dispatch}
        shopId={shopId}
        onImageLoaded={onImageLoaded}
      />
    </GridItemStyled>
  );
}
