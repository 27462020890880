import { useCallback, useMemo } from 'react';
import {
  DataFieldLookupTable,
  getDataFieldConditionOperators,
} from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { UseRuleValueProps, UseRuleValueReturnType } from './types';
import { SubRuleOptionalValues } from '../../../../../services/src/service/types/shops';

export const useRuleValue = ({ data, onChange }: UseRuleValueProps): UseRuleValueReturnType => {
  const { field, operator } = data;

  const conditionData = {
    conditionType: operator,
    conditionValue: data.values,
    dataField: field?.name,
  };

  const dataFieldLookupTable = useMemo(() => {
    const result: DataFieldLookupTable = {};
    const conditionTypes = getDataFieldConditionOperators({ types: data.field?.types || [] });
    if (!data.field) {
      return {};
    }
    result[data.field?.name] = { ...data.field, conditionTypes };
    return result;
  }, [data.field]);

  const getDataFieldByName = useCallback(
    (name?: string) => {
      if (!name) {
        return undefined;
      }
      return dataFieldLookupTable[name];
    },
    [dataFieldLookupTable]
  );

  const onValuesChange = useCallback(
    (selectedValues: SubRuleOptionalValues) => {
      onChange({ ...data, values: selectedValues });
    },
    [operator, onChange]
  );

  return {
    getDataFieldByName,
    conditionData,
    onValuesChange,
  };
};
