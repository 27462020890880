import { createReducer } from '@reduxjs/toolkit';
import {
  shopApiKeysSettingsActions,
  shopApiKeysSettingsModalContainerActions,
} from 'src/containers';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';

export interface IApiKeysReducerState {
  apiKeys: IApiKey[];
  selectedApiKey: IApiKey | null;
}

const initialState: IApiKeysReducerState = {
  apiKeys: [],
  selectedApiKey: null,
};

export const apiKeysReducer = createReducer(initialState, builder => {
  builder.addCase(shopApiKeysSettingsActions.getApiKeys.fulfilled, (state, action) => {
    return {
      ...state,
      apiKeys: action.payload,
    };
  });
  builder.addCase(shopApiKeysSettingsModalContainerActions.getApiKey.fulfilled, (state, action) => {
    return {
      ...state,
      selectedApiKey: action.payload,
    };
  });
  builder.addCase(shopApiKeysSettingsModalContainerActions.resetSelectedApiKey, state => {
    return {
      ...state,
      selectedApiKey: null,
    };
  });
});
