import styled from '@emotion/styled';

export const ImageSettingsStyled = styled.div`
  height: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 14px 24px 14px 0;
  display: flex;
  flex-direction: column;
`;

export const SettingsFooterStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;
