import styled from '@emotion/styled';
import { Icon, Typography, Switch, Button } from 'src/components-dummy';

export const LexiconPageHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: 50px;
  margin-top: 15px;
`;

export const LexiconPageHeaderTitleContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
`;

export const LexiconPageHeaderActionContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const LexiconPageHeaderTitleRowStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LexiconPageMainHeaderTitle = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5em;
`;

export const LexiconPageHeaderTitleLocale = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const LexiconPageHeaderSubTitleRowStyled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-left: 25px;
`;

export const LexiconPageHeaderBackIconStyled = styled(Icon)`
  align-self: center;
  margin-right: 15px;
  width: 10px;
  height: 10px;
  transform: rotate(180deg);

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const BulletStyled = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.custom['gray-60']};
  margin: 0 5px;
  align-self: center;
`;

export const FlagIcon = styled(Icon)`
  width: 40px;
  padding: 0 10px;
`;

export const ActionsSwitchStyled = styled(Switch)`
  flex-direction: row-reverse;
  gap: 20px;
  div {
    margin-left: 0;
  }
  span {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const ImportButtonStyled = styled(Button)`
  .syte-button-text {
    display: flex;
    gap: 5px;
  }
`;
