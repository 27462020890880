import React from 'react';
import { ICollectionsProps } from '../types';
import { CreateCollectionButton } from '../CreateCollectionButton';
import { CollectionsHeaderWrapper, CollectionTitle } from './CollectionsHeader.styles';

export const CollectionsHeader: React.FC<ICollectionsProps> = ({ shopId, dispatch }) => {
  return (
    <CollectionsHeaderWrapper>
      <CollectionTitle>Collections</CollectionTitle>
      <CreateCollectionButton shopId={shopId} dispatch={dispatch} buttonText='Create collection' />
    </CollectionsHeaderWrapper>
  );
};
