import { AxiosResponse } from 'axios';
import {
  ProductOffers,
  SimilarItemsPayload,
  CollectionItemsPayload,
  GetMerchRules,
  DiscoveryButtonPayload,
  BrowsePlpPayload,
  SuggestedOffer,
} from 'src/components-bl';
import { ApiServiceBase } from '../api-service-base';
import { PreviewExperience } from '../types';
import { buildQueryParams } from '../../common/utils';
import { MerchandiseRule } from '../types/shops';
import { VisualEditorSettings } from '../../../../components-bl/VisualEditor/types/visual-editor-settings';
import { DataFieldToDisplay } from '../../../../components-bl/VisualEditor/types/data-field-to-display';
import { CategoryFilter } from '../../../../components-bl/VisualEditor/types/category-filter';
import { CategoryFilterRule } from '../../../../components-bl/VisualEditor/types/category-filter-rule';
import { SpecialDataFieldToDisplay } from '../../../../components-bl/VisualEditor/types/special-data-field-to-display';
import { TextSearchItemsPayload } from '../../../../components-bl/VisualEditor/types/text-search';

class VisualEditorService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getEndpoint(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/visual-editor`;
  }

  async getPreviewExperiences(
    {
      shopId,
    }: {
      shopId: number;
    },
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ experiences: PreviewExperience[] }>> {
    const url = `${this.getEndpoint(shopId)}/experiences`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getSearchResults(
    {
      shopId,
      sku,
    }: {
      shopId: number;
      sku: string;
    },
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ values: SuggestedOffer[] }>> {
    const queryParams = buildQueryParams({ sku });
    const url = `${this.getEndpoint(shopId)}/search${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getCollections(
    {
      sku,
      shopId,
      collectionId,
      size,
      from,
      fields,
      colorVariantFields,
      sizeVariantFields,
    }: CollectionItemsPayload,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ProductOffers>> {
    const queryParams = buildQueryParams({
      collectionId: encodeURIComponent(collectionId),
      sku,
      size,
      from,
      fields,
      colorVariantFields,
      sizeVariantFields,
    });
    const url = `${this.getEndpoint(shopId)}/collections${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getBrowsePlpItems(
    {
      shopId,
      field,
      operator,
      value,
      size,
      from,
      fields,
      colorVariantFields,
      sizeVariantFields,
    }: BrowsePlpPayload,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ProductOffers>> {
    const queryParams = buildQueryParams({
      field,
      operator,
      value: encodeURIComponent(value),
      size,
      from,
      fields,
      colorVariantFields,
      sizeVariantFields,
    });
    const url = `${this.getEndpoint(shopId)}/browse-plp${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getSimilarItems(
    { shopId, sku, feed, fields, colorVariantFields, sizeVariantFields }: SimilarItemsPayload,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ProductOffers>> {
    const queryParams = buildQueryParams({
      sku,
      feed,
      fields,
      colorVariantFields,
      sizeVariantFields,
    });
    const url = `${this.getEndpoint(shopId)}/similar-items${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getShopTheLook(
    { shopId, sku, feed, fields, colorVariantFields, sizeVariantFields }: SimilarItemsPayload,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ProductOffers>> {
    const queryParams = buildQueryParams({
      sku,
      feed,
      fields,
      colorVariantFields,
      sizeVariantFields,
    });
    const url = `${this.getEndpoint(shopId)}/shop-the-look${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getMerchRules(
    { shopId, experience, entityId }: GetMerchRules,
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ merchandisingRules: MerchandiseRule[] }>> {
    const queryParams = buildQueryParams({ experience, entityId });
    const url = `${this.getEndpoint(shopId)}/merchandising-rules${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getDiscoveryButton(
    {
      shopId,
      imageUrl,
      syteURLReferrer,
      sku,
      fields,
      colorVariantFields,
      sizeVariantFields,
    }: DiscoveryButtonPayload,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ProductOffers>> {
    const queryParams = buildQueryParams({
      sku,
      imageUrl: encodeURIComponent(imageUrl),
      syteURLReferrer,
      fields,
      colorVariantFields,
      sizeVariantFields,
    });
    const url = `${this.getEndpoint(shopId)}/discovery-button${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }

  async getVisualEditorSettings({
    shopId,
  }: {
    shopId: number;
  }): Promise<AxiosResponse<VisualEditorSettings>> {
    const url = `${this.getEndpoint(shopId)}/settings`;
    return this.httpService.get({ url });
  }

  async updateVisualEditorSettings({
    shopId,
    dataFieldsToDisplay,
    specialDataFieldsToDisplay,
    itemsCountPerRow,
  }: {
    shopId: number;
    dataFieldsToDisplay: DataFieldToDisplay[];
    specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
    itemsCountPerRow: number;
  }): Promise<AxiosResponse<VisualEditorSettings>> {
    const url = `${this.getEndpoint(shopId)}/settings`;
    return this.httpService.patch({
      url,
      data: { dataFieldsToDisplay, specialDataFieldsToDisplay, itemsCountPerRow },
    });
  }

  async getCategoryFilterRule({
    shopId,
  }: {
    shopId: number;
  }): Promise<AxiosResponse<CategoryFilter>> {
    const url = `${this.getEndpoint(shopId)}/category-filter`;
    return this.httpService.get({ url });
  }

  async updateCategoryFilterRule({
    shopId,
    rule,
  }: {
    shopId: number;
    rule?: CategoryFilterRule;
  }): Promise<AxiosResponse<CategoryFilter>> {
    const url = `${this.getEndpoint(shopId)}/category-filter`;
    return this.httpService.patch({ url, data: { rule } });
  }

  async getTextSearch(
    { shopId, query, size, from, fields }: TextSearchItemsPayload,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ProductOffers>> {
    const queryParams = buildQueryParams({
      query,
      size,
      from,
      fields,
    });
    const url = `${this.getEndpoint(shopId)}/text-search${queryParams}`;
    return this.httpService.get({ url, requestConfig: { signal } });
  }
}

export const visualEditorService = new VisualEditorService();
