import { createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService, LexiconTagField } from 'src/services';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';

export const lexiconItemAutoCompleteActions = {
  getAvailableTranslations: createAsyncThunk(
    'LexiconItemAutoComplete/GetAvailableTranslations',
    async (
      {
        shopId,
        locale,
        searchTerm,
        tagField,
        categories,
        attributes,
        values,
        tagType,
      }: {
        shopId: number;
        locale: string;
        tagType: LexiconTagType;
        searchTerm: string;
        tagField: LexiconTagField;
        categories?: string[];
        attributes?: string[];
        values?: string[];
      },
      { rejectWithValue, signal }
    ) => {
      try {
        const { availableValues } = await lexiconService.getAvailableTranslations(
          {
            shopId,
            locale,
            searchTerm,
            tagField,
            categories,
            attributes,
            values,
            tagType,
          },
          { abortSignal: signal }
        );

        return { availableValues };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getAvailableKeys: createAsyncThunk(
    'LexiconItemAutoComplete/GetAvailableKeys',
    async (
      parameters: Parameters<typeof lexiconService.getAvailableKeys>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.getAvailableKeys(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
};
