import Joi from 'joi';
import { planValuesToText } from 'src/services/src/service/accounts/constants';
import { AccountPlan } from 'src/services/src/service/types/accounts/account';

export const accountPlanDropdownOptions = Object.values(AccountPlan).map(accountPlan => {
  return {
    value: accountPlan,
    text: planValuesToText[accountPlan],
  };
});

export const formValidationSchema = {
  accountPlan: Joi.string()
    .optional()
    .valid(...Object.values(AccountPlan)),
};
