import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const ConfirmPasswordStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const TypographyStyled = styled(Typography)<{ isError: boolean }>`
  ${({ isError, theme }) => {
    if (isError) {
      return `color: ${theme.palette.custom.red};`;
    }
    return `color: ${theme.palette.custom['gray-10']};`;
  }}
`;
