import React from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { ErrorMessageStyled, TextBoxStyled, TextSectionStyled } from './TextSection.styles';

interface TextSectionProps {
  value?: string;
  label: string;
  errorMessage?: string;
  onChange: (newTitle: string) => void;
}

export function TextSection({
  label,
  value,
  errorMessage,
  onChange,
}: TextSectionProps): JSX.Element {
  return (
    <>
      <TextSectionStyled hasError={Boolean(errorMessage)}>
        <TextBoxStyled
          label={label}
          value={value || ''}
          onChange={onChange}
          placeholder='Up to 150 characters, no special characters'
          multiline
          rows={3}
        />
      </TextSectionStyled>
      {Boolean(errorMessage) && (
        <ErrorMessageStyled
          type={TypographyType.Body}
          variant={TypographyVariant.ExtraSmallRegular}
        >
          {errorMessage}
        </ErrorMessageStyled>
      )}
    </>
  );
}
