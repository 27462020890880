import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 40px;

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.common.black} !important;
    text-decoration: underline;
  }
`;
