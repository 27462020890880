import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService } from 'src/services/src/service/galleries';

export const editCustomInspirationsGalleryPageActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>(
    'EditCustomInspirationsGalleryPage/NotifyIsDirty'
  ),
  navigateTo: createAction<{ navigateTo: string }>('EditCustomInspirationsGalleryPage/NavigateTo'),
  resetCurrentGallery: createAction('EditCustomInspirationsGalleryPage/ResetCurrentGallery'),
  getGallery: createAsyncThunk(
    'EditCustomInspirationsGalleryPage/GetGallery',
    async (
      { shopId, galleryId, galleryType }: Parameters<typeof galleriesService.getGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const gallery = await galleriesService.getGallery({
          shopId,
          galleryId,
          galleryType,
        });
        return gallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateGallery: createAsyncThunk(
    'EditCustomInspirationsGalleryPage/UpdateGallery',
    async (
      {
        shopId,
        galleryId,
        payload,
      }: Parameters<typeof galleriesService.updateCustomInspirationsGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const gallery = await galleriesService.updateCustomInspirationsGallery({
          shopId,
          galleryId,
          payload,
        });
        return gallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
