import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const pinSize = 32;

export const ProductPin = styled.div`
  width: ${pinSize}px;
  height: ${pinSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const ProductPinOuterCircle = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  opacity: 0.3;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
`;

export const ProductPinInnerCircle = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 50%;
  height: 50%;
  border-radius: 50%;
  position: absolute;
`;

export const TagWrapper = styled.div`
  height: 32px;
  padding: 8px;
  max-width: 276px;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  gap: 8px;
`;

export const TagIconStyled = styled(Icon)`
  max-width: 10px;
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const TagTypographyStyled = styled(Typography)``;
