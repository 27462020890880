import { Types } from 'src/components-bl';
import { Auth } from 'src/services';

/* --= Action Types =-- */
export enum ToastMiddlewareActionTypes {
  ForceLogoutRequest = 'ToastMiddleware/ForceLogoutRequest',
  ForceLogoutFinished = 'ToastMiddleware/ForceLogoutFinished',
  ForceLogoutFailed = 'ToastMiddleware/ForceLogoutFailed',
}

/* --= Action Interfaces =-- */
interface ForceLogoutRequestAction {
  type: typeof ToastMiddlewareActionTypes.ForceLogoutRequest;
}

interface ForceLogoutFinishedAction {
  type: typeof ToastMiddlewareActionTypes.ForceLogoutFinished;
}

interface ForceLogoutFailedAction {
  type: typeof ToastMiddlewareActionTypes.ForceLogoutFailed;
}

export type ToastMiddlewareAction =
  | ForceLogoutRequestAction
  | ForceLogoutFinishedAction
  | ForceLogoutFailedAction;

/* --= Actions =-- */
const toastMiddlewareActions = {
  ForceLogoutRequest: (): ForceLogoutRequestAction => {
    return {
      type: ToastMiddlewareActionTypes.ForceLogoutRequest,
    };
  },
  ForceLogoutFinished: (): ForceLogoutFinishedAction => {
    return {
      type: ToastMiddlewareActionTypes.ForceLogoutFinished,
    };
  },
  ForceLogoutFailed: (): ForceLogoutFailedAction => {
    return {
      type: ToastMiddlewareActionTypes.ForceLogoutFailed,
    };
  },
};

/* --= Methods =-- */
const toastMiddlewareMethods = {
  ...toastMiddlewareActions,
};

export const toastMiddlewareActionMethods = {
  forceLogout() {
    return async (dispatch: Types.Dispatch): Promise<void> => {
      const authService = new Auth();
      try {
        dispatch(toastMiddlewareMethods.ForceLogoutRequest());
        await authService.logout();
        dispatch(toastMiddlewareMethods.ForceLogoutFinished());
      } catch (ex) {
        dispatch(toastMiddlewareMethods.ForceLogoutFailed());
      }
    };
  },
};
