import styled from '@emotion/styled';
import { TextBox, Typography } from 'src/components-dummy';

export const TextSectionStyled = styled.div<{ hasError: boolean }>`
  margin-top: 4px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.palette.custom.red : theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};
`;

export const TextBoxStyled = styled(TextBox)`
  padding: 10px;

  label {
    font-size: 14px !important;
    color: ${({ theme }) => theme.palette.custom['gray-10']} !important;
  }

  .input-base-wrapper {
    padding: unset !important;
    border: unset !important;
    outline: unset !important;

    &:focus-within {
      border: unset !important;
    }

    textarea,
    &::placeholder {
      font-size: 12px !important;

      &:focus {
        outline: unset;
      }
    }
  }
`;

export const ErrorMessageStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.red};
`;
