import { Dispatch } from 'src/components-bl/types/dispatch';
import { ShopFeatureToggles } from './features-toggles';

export interface Shop extends ShopBase {
  createdAt: Date;
  featureToggles?: ShopFeatureToggles;
  enableUiTest: boolean;
  shopType: ShopType;
  salesforceAccountId?: string;
}

export interface ShopBase {
  accountId: number;
  shopId: number;
  shopName: string;
  shopUrl?: string;
  updatedAt: Date;
  defaultLanguage?: string;
}

export interface CreateShopArgs {
  shopName: string;
  accountId: number;
  salesforceAccountId?: string;
  shopType: ShopType;
}

export interface UpdateShopArgs {
  shopId: number;
  generalSettings: GeneralSettings;
}

export interface GetShopArgs {
  shopId: number;
}

export interface GeneralSettings {
  defaultLanguage?: string;
  shopName: string;
  shopUrl?: string;
}

/**
 * Data from ingestion service
 */
export interface ShopProductsAPIv2Toggles {
  isDeepTagsPermitted?: boolean;
  isTaggingEnabled?: boolean;
}

export interface ShopFeatureTogglesFormGetProductsDeepTagPermissionToggleActionPayload {
  isDeepTagsPermitted: boolean;
  isTaggingEnabled: boolean;
}

export interface EnableUiTest {
  enableUiTest: boolean;
}
export interface EnableUiTestSettings extends EnableUiTest {
  shouldRefetch?: boolean;
  shopId: number;
  dispatch: Dispatch;
}

export interface UpdateEnableUiTest extends EnableUiTest {
  shopId: number;
  selectedVariantId?: string;
}

export enum ShopType {
  Internal = 'internal',
  Production = 'production',
  Sandbox = 'sandbox',
}
