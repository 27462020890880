import React from 'react';
import { AvailableIcons, Icon, TypographyType, TypographyVariant } from 'src/components-dummy';
import { LinkButton } from '../../../../../components-dummy/LinkButton';
import { CollectionRulesGroupList } from '../CollectionRulesGroupList';
import { CollectionDetails } from '../../CollectionDetails';
import { RuleFormSection } from '../../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormSection/RuleFormSection';
import { ConditionsGroup } from '../../../../MerchandisingRules/components/MerchandisingRuleForm/components/ConditionsGroup/ConditionsGroup';
import { EditCollectionRulesProps } from '../types';
import { SubTitleStyled } from '../../../../Lexicon/components/ThematicTags/ThematicTagForm/components/FormSection/FormSection.styles';
import { EditCollectionRulesStyled } from './EditCollectionRules.styles';
import { ErrorLabelStyled } from '../../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.styles';
import { CollectionSectionStyled } from '../../../styles/CollectionSection.styles';

export const EditCollectionRules: React.FC<EditCollectionRulesProps> = ({
  shopId,
  draftCollection,
  errors,
  onDeleteRule,
  onEditRule,
  onAddRule,
  onAddRuleGroup,
  onDeleteRuleGroup,
  handleFieldOnChange,
  dataFieldsFetchService,
  isCreateMode,
  withoutBorder,
}) => {
  const errorMessage = errors?.ruleGroups?.message;

  return (
    <EditCollectionRulesStyled>
      <CollectionSectionStyled withoutBorder={withoutBorder}>
        <RuleFormSection.Header>Collection rules</RuleFormSection.Header>
        <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          Curate your collection by specifying criteria below. Only products that meet these
          conditions will be included
        </SubTitleStyled>
        <ConditionsGroup>
          <ConditionsGroup.Content>
            <CollectionRulesGroupList
              shopId={shopId}
              ruleGroups={draftCollection.ruleGroups}
              onDeleteRuleGroup={onDeleteRuleGroup}
              onAddRule={onAddRule}
              onDeleteRule={onDeleteRule}
              onEditRule={onEditRule}
              dataFieldsFetchService={dataFieldsFetchService}
              errors={errors}
            />
            {errorMessage ? <ErrorLabelStyled> {errorMessage} </ErrorLabelStyled> : null}
            <LinkButton onClick={onAddRuleGroup}>
              <Icon name={AvailableIcons.Plus} />
              {draftCollection.ruleGroups.length > 0 ? 'Add OR group' : 'Add'}
            </LinkButton>
          </ConditionsGroup.Content>
        </ConditionsGroup>
      </CollectionSectionStyled>
      <CollectionDetails
        draftCollection={draftCollection}
        handleFieldOnChange={handleFieldOnChange}
        dataFieldsFetchService={dataFieldsFetchService}
        errors={errors}
        isCreateMode={isCreateMode}
        shouldRenderUniqueByField={false}
        withoutBorder={withoutBorder}
      />
    </EditCollectionRulesStyled>
  );
};
