import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { collectionNavigationActionMethods } from 'src/components-bl';
import { Page, PageSideNav } from 'src/components-dummy';
import { CollectionBreadCrumbs } from 'src/components-bl/Collections/CollectionBreadCrumbs';
import { PageNotFoundContainer } from '../PageNotFoundContainer';
import { EditCollectionRulesContainer } from '../../components-bl/Collections/EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesContainer';
import { useGetDefaultCollection } from './useGetDefaultCollection';
import { ComponentRoute } from '../EditCollection/types';
import { EditCollectionContainerStyled } from '../EditCollection/EditCollectionContainer.styles';

type CreateCollectionContainerProps = RoutedComponentProps & {
  shopId: number;
  isCreateMode?: boolean;
};

export const CreateCollectionContainer: React.FC<CreateCollectionContainerProps> = ({
  permittedRouteMap,
  shopId,
  isCreateMode,
}) => {
  const dispatch = useDispatch();
  const formHeaderRef = useRef<HTMLDivElement>(null);

  const { defaultCollection: currentCollection, collectionId } = useGetDefaultCollection(shopId);

  const relevantRoutes = useMemo(() => {
    const props = {
      shopId,
      currentCollection,
      formHeaderRef,
      dispatch,
      disabled: true,
      isCreateMode,
    };

    const routes = [
      {
        route: permittedRouteMap.createCollection,
        Component: EditCollectionRulesContainer,
        props: { ...props, disabled: false },
      },
      {
        route: permittedRouteMap.editCollectionMerchandisingRules,
        Component: null,
        props,
      },
      {
        route: permittedRouteMap.editCollectionRankingStrategy,
        Component: null,
        props,
      },
      {
        route: permittedRouteMap.editCollectionSettings,
        Component: null,
        props,
      },
    ].filter(routeComponent => Boolean(routeComponent.route)) as unknown as ComponentRoute[];

    return routes;
  }, [
    permittedRouteMap.createCollection,
    permittedRouteMap.editCollectionMerchandisingRules,
    permittedRouteMap.editCollectionRankingStrategy,
    permittedRouteMap.editCollectionSettings,
    formHeaderRef.current,
    shopId,
    currentCollection,
  ]);

  const routeComponents = useMemo(
    () =>
      relevantRoutes.map(({ route, Component, props }) => (
        <AppRoute key={route.path} route={route} Component={Component} componentProps={props} />
      )),
    [relevantRoutes]
  );

  const sideNavRoutes = useMemo(
    () => [
      {
        routes: relevantRoutes.map(({ route, props }) => ({
          url: generatePath(route.path, { collectionId, shopId }),
          title: route.title,
          pathTemplate: route.path,
          className: props.disabled ? 'disabled' : '',
        })),
      },
    ],
    [relevantRoutes, shopId]
  );

  const onNavigate = useCallback(
    url => {
      dispatch(collectionNavigationActionMethods.navigateTo({ navigateTo: url }));
    },
    [dispatch]
  );

  if (!currentCollection?.id) {
    return <PageNotFoundContainer />;
  }

  return (
    <EditCollectionContainerStyled layout='sidebar'>
      <Page.Header>
        <CollectionBreadCrumbs
          dispatch={dispatch}
          permittedRouteMap={permittedRouteMap}
          shopId={shopId}
          collectionId={collectionId}
        >
          Create Collection
        </CollectionBreadCrumbs>
      </Page.Header>
      <Page.SideBar>
        <PageSideNav routeGroups={sideNavRoutes} handleNavigate={onNavigate} />
      </Page.SideBar>
      <Page.Content className='collections'>
        <AppSwitch>{routeComponents}</AppSwitch>
      </Page.Content>
    </EditCollectionContainerStyled>
  );
};
