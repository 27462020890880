import React from 'react';
import {
  Button,
  CardItem,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ActionButtonsWrapperStyled, CardWrapperStyled } from './EmptyStateCard.styles';

interface EmptyStateCardProps {
  navigateToConnectCatalog: () => void;
  navigateToCreateNewCatalog: () => void;
  readOnly: boolean;
}

export const EmptyStateCard = ({
  navigateToConnectCatalog,
  navigateToCreateNewCatalog,
  readOnly,
}: EmptyStateCardProps): JSX.Element => {
  return (
    <CardWrapperStyled className='empty-state'>
      <CardItem className='catalog-list-card'>
        <Typography
          className='title'
          type={TypographyType.Body}
          variant={TypographyVariant.LargeBold}
        >
          Connect your product catalogue
        </Typography>
        <Typography
          className='subtitle'
          type={TypographyType.Paragraph}
          variant={TypographyVariant.MediumRegular}
        >
          {readOnly
            ? `Please contact Syte’s Customer Support to connect your Catalogue with the Catalogue Manager.`
            : `Connect your product catalogue to Syte and start optimizing your product discovery
              experiences today.`}
        </Typography>
        {!readOnly && (
          <ActionButtonsWrapperStyled>
            <Button onClick={navigateToCreateNewCatalog}>Connect your Catalogue</Button>
            <Button variant='tertiary' onClick={navigateToConnectCatalog}>
              Connect Existing Catalogue
            </Button>
          </ActionButtonsWrapperStyled>
        )}
      </CardItem>
    </CardWrapperStyled>
  );
};
