import React, { useMemo } from 'react';
import { ShopChangeLog } from 'src/services';
import { LogHistoryPanel, LogHistoryType } from 'src/components-bl';
import { findFeatureFromDomainEntity } from 'src/components-bl/Experiment/ExperimentForm/VariantPanel';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { compareDesc } from 'date-fns';

export const VariantLogHistoryPanelContainer = (): JSX.Element => {
  const variantLogPanelOpened = useAppSelector(state => state.experiments.variantLogPanelOpened);
  const variantLogPanelFeature = useAppSelector(state => state.experiments.variantLogPanelFeature);
  const selectedVariantId = useAppSelector(state => state.experiments.selectedVariantId);
  const selectedExperiment = useAppSelector(state => state.experiments.selectedExperiment);
  const dispatch = useAppDispatch();

  const filterChangeLogsByFeature = (changeLogs: ShopChangeLog[] | undefined) => {
    return changeLogs?.filter(
      changeLogItem =>
        findFeatureFromDomainEntity(changeLogItem.resource) === variantLogPanelFeature
    );
  };

  const selectedVariant = useMemo(() => {
    if (!selectedVariantId) {
      return undefined;
    }
    const variant = selectedExperiment?.variants.find(
      variantItem => variantItem.id === selectedVariantId
    );
    if (!variant) {
      return undefined;
    }
    return {
      ...variant,
      changeLogs: filterChangeLogsByFeature(variant.changeLogs)?.sort(
        (a: ShopChangeLog, b: ShopChangeLog) => {
          return compareDesc(a.changedAt, b.changedAt);
        }
      ),
    };
  }, [selectedExperiment, selectedVariantId, variantLogPanelFeature]);

  return (
    <LogHistoryPanel
      dispatch={dispatch}
      opened={variantLogPanelOpened}
      logs={selectedVariant?.changeLogs ?? []}
      type={LogHistoryType.Variant}
      variantName={selectedVariant?.name}
    />
  );
};
