import { AccountTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { AccountsMapper } from './accounts-mapper';
import { AccountBuilder } from '../../builders';

export class Accounts extends ApiServiceBase {
  constructor() {
    super('accounts');
  }

  async getAccount(accountId: number): Promise<AccountTypes.Account> {
    const url = `${this.serviceBaseUri}/${accountId}`;
    const response = await this.httpService.get({ url });
    return AccountsMapper.map(response);
  }

  async createAccount(name: string): Promise<AccountTypes.Account> {
    const data = AccountBuilder.getCreateAccountPayload(name);
    const response = await this.httpService.post({ url: this.serviceBaseUri, data });
    return AccountsMapper.map(response);
  }

  async searchAccounts({
    searchTerm,
    includeArchived = false,
    skip = 0,
    limit = 10,
  }: AccountTypes.SearchAccountsArgs): Promise<Array<AccountTypes.AccountSearchResult>> {
    const params = AccountBuilder.getSearchAccountsUrlParams({
      searchTerm,
      includeArchived,
      skip,
      limit,
    });
    const response = await this.httpService.get({
      url: this.serviceBaseUri,
      requestConfig: { params },
    });
    return AccountsMapper.mapSearchResults(response);
  }

  async updateAccountName({
    accountId,
    name,
  }: AccountTypes.UpdateAccountArgs): Promise<AccountTypes.Account> {
    const url = `${this.serviceBaseUri}/${accountId}`;
    const data = AccountBuilder.getPartialAccountPayload({ accountName: name });
    const response = await this.httpService.put({ url, data });
    return AccountsMapper.map(response);
  }

  async updateAccountStatus({
    accountId,
    status,
  }: AccountTypes.UpdateAccountStatusArgs): Promise<AccountTypes.Account> {
    const url = `${this.serviceBaseUri}/${accountId}`;
    const data = AccountBuilder.getPartialAccountPayload({ accountStatus: status });
    const response = await this.httpService.put({ url, data });
    return AccountsMapper.map(response);
  }

  async updateAccountPlan({
    accountId,
    accountPlan,
  }: AccountTypes.UpdateAccountPlanArgs): Promise<AccountTypes.Account> {
    const url = `${this.serviceBaseUri}/${accountId}`;
    const data = AccountBuilder.getPartialAccountPayload({ accountPlan });
    const response = await this.httpService.put({ url, data });
    return AccountsMapper.map(response);
  }
}
