import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { AddImagesModalForm } from 'src/components-bl/Galleries';
import { useAppDispatch } from 'src/hooks';
import { useCustomInspirationsGallerySelector } from './useCustomInspirationsGallerySelector';

interface AddImagesContainerProps extends RoutedComponentProps {
  shopId: number;
}

export const AddImagesContainer = ({
  shopId,
  permittedRouteMap,
}: AddImagesContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const gallery = useCustomInspirationsGallerySelector();
  return gallery ? (
    <AddImagesModalForm
      shopId={shopId}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
      gallery={gallery}
    />
  ) : null;
};
