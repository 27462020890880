import React from 'react';
import { EditCollectionRules } from './EditCollectionRules';
import { EditCollectionRulesFooter } from './EditCollectionRulesFooter';
import { useCollection } from '../useCollection';
import { EditCollectionRulesContainerProps } from '../types';

export const EditCollectionRulesContainer = ({
  shopId,
  currentCollection,
  dispatch,
  isCreateMode,
}: EditCollectionRulesContainerProps): JSX.Element => {
  const {
    draftCollection,
    canSubmit,
    errors,
    onAddRuleGroup,
    onDeleteRuleGroup,
    onAddRule,
    onDeleteRule,
    onEditRule,
    onSaveChanges,
    onCreateCollection,
    onCancel,
    handleFieldOnChange,
    dataFieldsFetchService,
    navigateToList,
  } = useCollection({
    shopId,
    currentCollection,
    dispatch,
    isCreateMode,
  });

  const onCreate = () => {
    onCreateCollection(navigateToList);
  };

  const onCancelChanges = () => {
    onCancel(navigateToList);
  };

  return (
    <>
      <EditCollectionRules
        shopId={shopId}
        draftCollection={draftCollection}
        isCreateMode={isCreateMode}
        onDeleteRule={onDeleteRule}
        onEditRule={onEditRule}
        onAddRule={onAddRule}
        onAddRuleGroup={onAddRuleGroup}
        onDeleteRuleGroup={onDeleteRuleGroup}
        handleFieldOnChange={handleFieldOnChange}
        dataFieldsFetchService={dataFieldsFetchService}
        errors={errors}
      />
      <EditCollectionRulesFooter
        isCreateMode={isCreateMode}
        canSubmit={canSubmit}
        onCancel={onCancelChanges}
        onSaveChanges={onSaveChanges}
        onCreate={onCreate}
      />
    </>
  );
};
