import { AxiosResponse } from 'axios';
import { downloadResponseFile } from '../../common/utils';
import {
  ILexiconMetadata,
  ILexiconTagsPagination,
  LexiconTagField,
  ILexiconItem,
  ILexiconTagsFilters,
  IThematicTag,
  ILexiconTagsSummary,
  ExcludeOrMatchTags,
  ShopLexiconSettings,
  ILexiconMetadataSlim,
  ILexiconRule,
} from '../types';
import { ApiServiceBase } from '../api-service-base';
import * as mapper from './mapper';
import {
  GetLexiconTagsArguments,
  RequestUpdateOneLexiconBody,
  CreateThematicTagArguments,
  UpdateThematicTagArguments,
  BaseServiceArguments,
  LexiconTagType,
  CreateLexiconRuleArguments,
  UpdateLexiconRuleArguments,
  GetLexiconRuleArguments,
} from './types';

class Lexicon extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, locale }: { shopId: number; locale: string }): string {
    return `${this.serviceBaseUri}/${shopId}/lexicons/${locale}`;
  }

  async getLexiconMetadataList({ shopId }: { shopId: number }): Promise<ILexiconMetadataSlim[]> {
    const url = `${this.serviceBaseUri}/${shopId}/lexicons`;

    const response = await this.httpService.get({
      url,
    });

    const mappedLexiconMetadataList = mapper.mapLexiconMetadataSlimList(response.data);

    return mappedLexiconMetadataList;
  }

  async getLexiconTags(
    { shopId, locale, filters, pagination, sortOptions, tagType }: GetLexiconTagsArguments,
    options?: { abortSignal?: AbortSignal }
  ): Promise<ILexiconTagsPagination> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}`;

    const params = {
      ...filters,
      ...pagination,
      ...sortOptions,
    } as GetLexiconTagsArguments['filters'] &
      GetLexiconTagsArguments['pagination'] &
      GetLexiconTagsArguments['sortOptions'];

    const response = await this.httpService.get({
      url,
      requestConfig: { params, signal: options?.abortSignal },
    });

    return mapper.mapLexiconTagsPagination(response.data);
  }

  async getLexiconTagsSummary({
    shopId,
    locale,
    filters,
    excludeTags,
    matchTags,
    tagType,
  }: GetLexiconTagsArguments & Partial<ExcludeOrMatchTags>): Promise<ILexiconTagsSummary> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/summary`;

    const params = {
      excludeTags,
      matchTags,
      ...filters,
    } as GetLexiconTagsArguments['filters'] &
      GetLexiconTagsArguments['pagination'] &
      GetLexiconTagsArguments['sortOptions'] &
      Partial<ExcludeOrMatchTags>;

    const response = await this.httpService.get({
      url,
      requestConfig: { params },
    });

    return response.data;
  }

  async getAvailableTranslations(
    {
      shopId,
      locale,
      searchTerm,
      tagField,
      categories,
      attributes,
      values,
      tagType,
    }: {
      searchTerm: string;
      tagField: LexiconTagField;
      categories?: string[];
      attributes?: string[];
      values?: string[];
    } & BaseServiceArguments,
    options?: { abortSignal?: AbortSignal }
  ): Promise<{
    availableValues: string[];
  }> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/available-translations/${tagField}`;

    const params: Partial<{
      searchTerm: string;
      categoriesTranslations: string[];
      attributesTranslations: string[];
      valuesTranslations: string[];
    }> = {
      searchTerm,
    };

    if (categories?.length) {
      params.categoriesTranslations = categories;
    }

    if (attributes?.length) {
      params.attributesTranslations = attributes;
    }

    if (values?.length) {
      params.valuesTranslations = values;
    }

    const response = await this.httpService.get({
      url,
      requestConfig: {
        params,
        signal: options?.abortSignal,
      },
    });

    return response.data;
  }

  async getAvailableKeys(
    {
      shopId,
      locale,
      searchTerm,
      tagField,
      categories,
      attributes,
      values,
    }: {
      shopId: number;
      locale: string;
      searchTerm: string;
      tagField: LexiconTagField;
      categories?: string[];
      attributes?: string[];
      values?: string[];
    },
    options?: { abortSignal?: AbortSignal }
  ): Promise<{
    availableValues: string[];
  }> {
    const url = `${this.getUrl({ shopId, locale })}/${
      LexiconTagType.LexiconTags
    }/available-keys/${tagField}`;

    const params: Partial<{
      searchTerm: string;
      categoriesKeys: string;
      attributesKeys: string;
      valuesKeys: string;
    }> = {
      searchTerm,
    };

    if (categories?.length) {
      params.categoriesKeys = categories?.join(',');
    }

    if (attributes?.length) {
      params.attributesKeys = attributes?.join(',');
    }

    if (values?.length) {
      params.valuesKeys = values?.join(',');
    }

    const response = await this.httpService.get({
      url,
      requestConfig: {
        params,
        signal: options?.abortSignal,
      },
    });

    return response.data;
  }

  async bulkActionIncludeTags({
    shopId,
    locale,
    tagType,
    matchTags,
    include,
  }: {
    matchTags: string[];
    include: boolean;
  } & BaseServiceArguments): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/bulk-actions/include`;

    await this.httpService.post({
      url,
      data: {
        matchTags,
        include,
      },
    });
  }

  async bulkActionIncludeAllTags({
    shopId,
    locale,
    tagType,
    excludeTags,
    include,
    filters,
  }: {
    include: boolean;
    filters: Partial<ILexiconTagsFilters>;
    excludeTags?: ExcludeOrMatchTags['excludeTags'];
  } & BaseServiceArguments): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/bulk-actions/include/all`;

    await this.httpService.post({
      url,
      data: {
        excludeTags,
        include,
        filters,
      },
    });
  }

  async bulkActionRenameTags({
    shopId,
    locale,
    tagType,
    matchTags,
    tagField,
    customTranslation,
  }: {
    tagField: LexiconTagField;
    customTranslation: string;
    matchTags?: ExcludeOrMatchTags['matchTags'];
  } & BaseServiceArguments): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/bulk-actions/rename`;

    await this.httpService.post({
      url,
      data: {
        matchTags,
        tagField,
        customTranslation,
      },
    });
  }

  async bulkActionRenameAllTags({
    shopId,
    locale,
    tagType,
    excludeTags,
    tagField,
    customTranslation,
    filters,
  }: {
    tagField: LexiconTagField;
    customTranslation: string;
    filters: Partial<ILexiconTagsFilters>;
  } & BaseServiceArguments &
    Partial<Pick<ExcludeOrMatchTags, 'excludeTags'>>): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/bulk-actions/rename/all`;

    await this.httpService.post({
      url,
      data: {
        excludeTags,
        tagField,
        customTranslation,
        filters,
      },
    });
  }

  async bulkActionRestoreTags({
    shopId,
    locale,
    matchTags,
  }: {
    shopId: number;
    locale: string;
    matchTags: ExcludeOrMatchTags['matchTags'];
  }): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${
      LexiconTagType.LexiconTags
    }/bulk-actions/restore`;

    await this.httpService.post({
      url,
      data: {
        matchTags,
      },
    });
  }

  async bulkActionRestoreAllTags({
    shopId,
    locale,
    excludeTags,
    filters,
  }: {
    shopId: number;
    locale: string;
    excludeTags: ExcludeOrMatchTags['excludeTags'];
    filters: Partial<ILexiconTagsFilters>;
  }): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${
      LexiconTagType.LexiconTags
    }/bulk-actions/restore/all`;

    await this.httpService.post({
      url,
      data: {
        excludeTags,
        filters,
      },
    });
  }

  async bulkActionDeleteTags({
    shopId,
    locale,
    matchTags,
    tagType,
  }: {
    shopId: number;
    locale: string;
    matchTags: ExcludeOrMatchTags['matchTags'];
    tagType: LexiconTagType;
  }): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/bulk-actions/delete`;

    await this.httpService.post({
      url,
      data: {
        matchTags,
      },
    });
  }

  async bulkActionDeleteAllTags({
    shopId,
    locale,
    excludeTags,
    filters,
    tagType,
  }: {
    shopId: number;
    locale: string;
    excludeTags: string[];
    filters: Partial<ILexiconTagsFilters>;
    tagType: LexiconTagType;
  }): Promise<void> {
    const url = `${this.getUrl({ shopId, locale })}/${tagType}/bulk-actions/delete/all`;

    await this.httpService.post({
      url,
      data: {
        excludeTags,
        filters,
      },
    });
  }

  async getLexiconMetadata({
    shopId,
    locale,
  }: {
    shopId: number;
    locale: string;
  }): Promise<ILexiconMetadata> {
    const url = this.getUrl({ shopId, locale });
    const response = await this.httpService.get({ url });
    return mapper.mapLexiconMetadata(response.data);
  }

  async publishLexicon({
    shopId,
    locale,
  }: {
    shopId: number;
    locale: string;
  }): Promise<ILexiconMetadata> {
    const url = `${this.getUrl({ shopId, locale })}/publish`;

    const response = await this.httpService.post({ url });

    return mapper.mapLexiconMetadata(response.data);
  }

  async updateOneLexiconTag({
    shopId,
    locale,
    tagToUpdate,
    tagKey,
  }: {
    shopId: number;
    locale: string;
    tagKey: string;
    tagToUpdate: RequestUpdateOneLexiconBody;
  }): Promise<ILexiconItem> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.LexiconTags}/${tagKey}`;

    const result = await this.httpService.put({
      url,
      data: tagToUpdate,
    });

    return result.data;
  }

  async deleteOneThematicTag({
    shopId,
    locale,
    tagKey,
  }: {
    shopId: number;
    locale: string;
    tagKey: string;
  }): Promise<boolean> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.ThematicTags}/${tagKey}`;

    const result = await this.httpService.delete({ url });

    return result.status === 204;
  }

  async getOneThematicTag({
    shopId,
    locale,
    id,
  }: {
    shopId: number;
    locale: string;
    id: string;
  }): Promise<IThematicTag> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.ThematicTags}/${id}`;

    const result = await this.httpService.get({ url });

    return mapper.mapThematicTag(result.data);
  }

  async createOneThematicTag({
    shopId,
    locale,
    payload,
  }: CreateThematicTagArguments): Promise<IThematicTag> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.ThematicTags}`;

    const result = await this.httpService.post({ url, data: payload });

    return mapper.mapThematicTag(result.data);
  }

  async updateOneThematicTag({
    shopId,
    locale,
    id,
    partialTagToUpdate,
  }: UpdateThematicTagArguments): Promise<IThematicTag> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.ThematicTags}/${id}`;

    const result = await this.httpService.patch({ url, data: partialTagToUpdate });

    return mapper.mapThematicTag(result.data);
  }

  async createLexiconRule({
    shopId,
    locale,
    payload,
  }: CreateLexiconRuleArguments): Promise<ILexiconRule> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.RenameRules}`;

    const result = await this.httpService.post({ url, data: payload });

    return mapper.mapLexiconRule(result.data);
  }

  async getLexiconRule({ shopId, locale, id }: GetLexiconRuleArguments): Promise<ILexiconRule> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.RenameRules}/${id}`;

    const result = await this.httpService.get({ url });

    return mapper.mapLexiconRule(result.data);
  }

  async deleteOneLexiconRule({
    shopId,
    locale,
    tagKey,
  }: {
    shopId: number;
    locale: string;
    tagKey: string;
  }): Promise<boolean> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.RenameRules}/${tagKey}`;

    const result = await this.httpService.delete({ url });

    return result.status === 204;
  }

  async updateLexiconRule({
    shopId,
    locale,
    id,
    partialRuleToUpdate,
  }: UpdateLexiconRuleArguments): Promise<ILexiconRule> {
    const url = `${this.getUrl({ shopId, locale })}/${LexiconTagType.RenameRules}/${id}`;

    const result = await this.httpService.patch({ url, data: partialRuleToUpdate });

    return mapper.mapLexiconRule(result.data);
  }

  async getShopLexiconsSettings({ shopId }: { shopId: number }): Promise<ShopLexiconSettings> {
    const url = `${this.serviceBaseUri}/${shopId}/lexicons/settings`;

    const result = await this.httpService.get({
      url,
    });

    return result.data;
  }

  async updateShopLexiconsSettings({
    shopId,
    locales,
  }: ShopLexiconSettings): Promise<ShopLexiconSettings> {
    const url = `${this.serviceBaseUri}/${shopId}/lexicons/settings`;

    const result = await this.httpService.put({
      url,
      data: { locales },
    });

    return result.data;
  }

  async importLexicon({
    shopId,
    locale,
    requestId,
    lexiconFile,
    cancellationSignal,
  }: {
    shopId: number;
    locale: string;
    requestId: string;
    lexiconFile: File;
    cancellationSignal?: AbortSignal;
  }): Promise<{ isPartialImport: boolean }> {
    const url = `${this.getUrl({ shopId, locale })}/import?requestId=${requestId}`;

    const formData = new FormData();
    formData.append('file', lexiconFile);

    const response: AxiosResponse<Blob | undefined> = await this.httpService.post({
      url,
      data: formData,
      requestConfig: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
        signal: cancellationSignal,
      },
    });

    const isFileReturned = Boolean(response.data?.size);

    if (isFileReturned) {
      downloadResponseFile({
        response,
        defaultName: mapper.getDefaultLexiconFileName({ shopId, locale }),
      });
    }

    return { isPartialImport: isFileReturned };
  }

  async exportLexicon({
    shopId,
    locale,
    requestId,
    cancellationSignal,
  }: {
    shopId: number;
    locale: string;
    requestId: string;
    cancellationSignal?: AbortSignal;
  }): Promise<AxiosResponse<Blob | undefined, any>> {
    const url = `${this.getUrl({ shopId, locale })}/export?requestId=${requestId}`;

    const response: AxiosResponse<Blob | undefined> = await this.httpService.post({
      url,
      requestConfig: {
        responseType: 'blob',
        signal: cancellationSignal,
      },
    });

    downloadResponseFile({
      response,
      defaultName: mapper.getDefaultLexiconFileName({ shopId, locale }),
    });

    return response;
  }
}

export const lexiconService = new Lexicon();
