import { Shop } from '../shops/shop';
import { User } from '../users/user';
import { AccountBase } from './account-base';

export interface Account extends AccountBase {
  accountStatus: AccountStatus;
  shops: Shop[];
  createdAt: Date;
  users?: User[];
  accountPlan?: AccountPlan;
}

export interface AccountSearchResult extends AccountBase {
  accountStatus: AccountStatus;
  createdAt: Date;
}

export enum AccountStatus {
  Active = 1,
  Disabled,
  Archived,
}

export enum AccountPlan {
  Subscriber = 'SUBSCRIBER',
  Trial = 'TRIAL',
  Demo = 'DEMO',
  SyteInternalAccount = 'SYTE_INTERNAL_ACCOUNT',
}

export interface SearchAccountsArgs {
  searchTerm: string;
  includeArchived?: boolean;
  skip?: number;
  limit?: number;
}

export interface UpdateAccountArgs {
  accountId: number;
  name: string;
}

export interface UpdateAccountStatusArgs {
  accountId: number;
  status: AccountStatus;
}

export interface UpdateAccountPlanArgs {
  accountId: number;
  accountPlan: AccountPlan;
}
