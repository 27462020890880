import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { CustomInspirationsGalleryLayoutType } from 'src/services';
import { CustomInspirationGalleryDraft } from '../../../../types';
import { GalleryGridContainerStyled, ImageGridStyled } from './GalleryGrid.styles';
import { GalleryGridItem } from './components';

interface GalleryGridProps {
  gallery: CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Grid>;
  onImageClick: (imageId: string) => void;
  onAddTags: (imageId: string) => void;
  onRemoveImage: (imageId: string) => void;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft>) => void;
  dispatch: Dispatch;
  shopId: number;
  onImageLoaded: () => void;
  hide: boolean;
}

export function GalleryGrid({
  gallery,
  onAddTags,
  onImageClick,
  onRemoveImage,
  onChange,
  dispatch,
  shopId,
  onImageLoaded,
  hide,
}: GalleryGridProps): JSX.Element {
  const [draggedAndDroppedImages, setDraggedAndDroppedImages] = useState([...gallery.images]);

  useEffect(() => {
    setDraggedAndDroppedImages(gallery.images);
  }, [gallery.images]);

  const onImageDrag = useCallback(
    (sourceIndex: number, targetIndex: number) => {
      setDraggedAndDroppedImages(previousImages => {
        const rearrangedImages = [...previousImages];
        const elementToMove = rearrangedImages[sourceIndex];

        rearrangedImages.splice(sourceIndex, 1);
        rearrangedImages.splice(targetIndex, 0, elementToMove);

        return rearrangedImages;
      });
    },
    [setDraggedAndDroppedImages]
  );

  const onImageDrop = useCallback(() => {
    onChange({ images: draggedAndDroppedImages });
  }, [draggedAndDroppedImages, onChange]);

  return (
    <GalleryGridContainerStyled hide={hide}>
      <ImageGridStyled variant='standard' columns={gallery.layout.gridImagesPerRow} gap={16}>
        {draggedAndDroppedImages.map((image, index) => (
          <GalleryGridItem
            key={image.id}
            image={image}
            index={index}
            onImageClick={onImageClick}
            onAddTags={onAddTags}
            onRemoveImage={onRemoveImage}
            onImageDrag={onImageDrag}
            onImageDrop={onImageDrop}
            shopId={shopId}
            dispatch={dispatch}
            onImageLoaded={onImageLoaded}
          />
        ))}
      </ImageGridStyled>
    </GalleryGridContainerStyled>
  );
}
