export enum ValidationErrorKey {
  RankingWeightsMinSum = 'RANKING_WEIGHTS_MIN_SUM',
  UserEmailAlreadyExists = 'USER_EMAIL_ALREADY_EXISTS',
  AuthUserLocked = 'AUTH_USER_LOCKED',
  MerchandisingRuleNameAlreadyExists = 'MERCHANDISING_RULE_NAME_ALREADY_EXISTS',
  ExperimentNameAlreadyExists = 'EXPERIMENT_NAME_ALREADY_EXISTS',
  ExperimentRunningExists = 'EXPERIMENT_RUNNING_EXISTS',
  VariantsTrafficAllocationSum = 'VARIANTS_TRAFFIC_ALLOCATION_SUM_INVALID',
  VariantsTrafficAllocationNotEqual = 'VARIANTS_TRAFFIC_ALLOCATION_NOT_EQUAL',
  VariantsIdsNotUnique = 'VARIANTS_IDS_NOT_UNIQUE',
  VariantsNameNotUnique = 'VARIANTS_NAME_NOT_UNIQUE',
  VariantsTypeConstraintViolation = 'VARIANTS_TYPE_CONSTRAINT_VIOLATION',
  ShopFeatureTogglesExperimentRunning = 'SHOP_FEATURE_TOGGLES_EXPERIMENT_RUNNING',
  ShopDataFieldsReadOnly = 'SHOP_DATA_FIELDS_READ_ONLY',
  ShopDataFieldsLengthReadOnly = 'SHOP_DATA_FIELDS_LENGTH_READ_ONLY',
  ShopDataFieldsFeatureIncompatible = 'SHOP_DATA_FIELDS_FEATURE_INCOMPATIBLE',
  ShopCatalogIsNotIndexed = 'SHOP_CATALOG_IS_NOT_INDEXED',
  ShopCatalogIsAlreadyExists = 'SHOP_CATALOG_IS_ALREADY_EXISTS',
  ShopCatalogIsPrimaryCanNotBeSet = 'SHOP_CATALOG_IS_PRIMARY_CAN_NOT_BE_SET',
  ShopCatalogIsAugmentedSearchCanNotBeSet = 'SHOP_CATALOG_IS_AUGMENTED_SEARCH_CAN_NOT_BE_SET',
  ShopCatalogMultipleEsHosts = 'SHOP_CATALOG_MULTIPLE_ES_HOSTS',
  ThematicTagAlreadyExists = 'THEMATIC_TAG_ALREADY_EXISTS',
  FeedNameAlreadyOccupied = 'FEED_NAME_ALREADY_OCCUPIED',
  FilterSourceFieldAlreadyTaken = 'FILTER_SOURCE_FIELD_ALREADY_TAKEN',
  MaxActiveFiltersExceeded = 'MAX_ACTIVE_FILTERS_EXCEEDED',
  MaxActiveCustomOrderFiltersExceeded = 'MAX_ACTIVE_CUSTOM_ORDER_FILTERS_EXCEEDED',
  InspirationalImagesDuplicateName = 'INSPIRATIONAl_IMAGES_DUPLICATE_NAME',
  InspirationalImagesInvalidFileStructure = 'INSPIRATIONAl_IMAGES_INVALID_FILE_STRUCTURE',
  CustomInspirationGalleryDuplicateName = 'CUSTOM_INSPIRATION_GALLERY_DUPLICATE_NAME',
  CollectionNameAlreadyExistsForShop = 'COLLECTION_NAME_ALREADY_EXISTS_FOR_SHOP',
  CollectionIdAlreadyExistsForShop = 'COLLECTION_ID_ALREADY_EXISTS_FOR_SHOP',
  CatalogManagerFeedFileNotExists = 'CATALOG_MANAGER_FEED_FILE_NOT_EXISTS',
  ShopNameDuplicateName = 'SHOP_NAME_DUPLICATE',
}
