import { DomainGroup, SubDomainGroupBase } from '../../types';
import { DomainEntityPath } from '../../../../../services';
import { AvailableIcons } from '../../../../../components-dummy';
import { RuleGroup } from '../../../../Collections';
import { collectionRulesDiffsSchema } from './collection-rules-diff-schema';

type EntityType = RuleGroup;

export const collectionRulesDomainGroup: DomainGroup & SubDomainGroupBase<EntityType> = {
  domainKey: DomainEntityPath.CollectionRules,
  title: 'Collection Rules',
  icon: AvailableIcons.Collections,
  getCardTitle: ({ newEntity, oldEntity }): string => {
    const collectionRuleGroup = newEntity ?? oldEntity;
    return `Collections > ${collectionRuleGroup?.collectionName || 'unnamed'} > Collection Rules`;
  },
  fieldsSchema: collectionRulesDiffsSchema,
};
