import React from 'react';
import { CollectionDetails } from '../CollectionDetails';
import { CollectionSnippet } from '../CollectionSnippet';
import { useCollection } from '../CollectionRules/useCollection';
import { RuleFormFooter } from '../../../MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter';
import { EditCollectionRulesContainerProps } from '../CollectionRules/types';
import { ButtonStyled } from '../../../../containers/MerchandisingRules/CreateMerchandisingRuleContainer/CreateMerchandisingRuleContainer.styles';

export const CollectionSummary: React.FC<EditCollectionRulesContainerProps> = ({
  shopId,
  currentCollection,
  dispatch,
  isCreateMode,
}) => {
  const {
    draftCollection,
    canSubmit,
    errors,
    onSaveChanges,
    onDiscardChanges,
    handleFieldOnChange,
    dataFieldsFetchService,
  } = useCollection({
    shopId,
    currentCollection,
    dispatch,
    isCreateMode,
  });

  return (
    <>
      <CollectionDetails
        draftCollection={draftCollection}
        handleFieldOnChange={handleFieldOnChange}
        dataFieldsFetchService={dataFieldsFetchService}
        shouldRenderUniqueByField
        errors={errors}
      />
      <CollectionSnippet draftCollection={draftCollection} dispatch={dispatch} />
      <RuleFormFooter>
        <ButtonStyled variant='tertiary' onClick={onDiscardChanges}>
          Cancel
        </ButtonStyled>
        <ButtonStyled disabled={!canSubmit} variant='primary' onClick={onSaveChanges}>
          Save
        </ButtonStyled>
      </RuleFormFooter>
    </>
  );
};
