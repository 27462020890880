import React from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  FormStyled,
  TitleTypographyStyled,
  SubTitleTypographyStyled,
  LinkTypographyStyled,
  InputActionTextStyled,
} from '../Login.styles';

export const ForgotPasswordFormSuccess = ({
  email,
  goToLoginForm,
}: {
  email: string;
  goToLoginForm: () => void;
}): JSX.Element => {
  return (
    <FormStyled>
      <TitleTypographyStyled type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        Check your inbox
      </TitleTypographyStyled>
      <SubTitleTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
      >
        A reset password link has been sent to {email}
      </SubTitleTypographyStyled>
      <InputActionTextStyled onClick={goToLoginForm} align='left' marginTop={24}>
        <LinkTypographyStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          Back to login
        </LinkTypographyStyled>
      </InputActionTextStyled>
    </FormStyled>
  );
};
