import { UserTypes, AuthTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { UsersMapper } from '../users/users-mapper';

export class Auth extends ApiServiceBase {
  constructor() {
    super('auth');
  }

  async me(): Promise<UserTypes.User> {
    const url = `${this.serviceBaseUri}/me`;
    const response = await this.httpService.get({ url });
    return UsersMapper.map(response);
  }

  async login(args: AuthTypes.LoginArgs): Promise<UserTypes.User> {
    // TODO: Password shouldn't be transferred in plain text. At least base64 encoding should be implemented
    const response = await this.httpService.post({ url: this.serviceBaseUri, data: args });
    return UsersMapper.map(response);
  }

  async logout(): Promise<string> {
    const url = `${this.serviceBaseUri}/logout`;
    const response = await this.httpService.get({ url });
    return response.data;
  }
}
