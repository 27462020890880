import React from 'react';

import { useAppSelector } from '../../../../hooks';

import { useGetCollectionsList } from '../../../../components-bl/Collections/CollectionsList/useGetCollectionsList';
import { CollectionsList, EmptyCollections } from '../../../../components-bl';
import { SearchInput } from '../../../../components-dummy';

import { ICollectionsProps } from '../../types';

import './CollectionsMain.scss';
import { useSortedAndFilteredCollections } from '../../../../components-bl/hooks';

export const CollectionsMain: React.FC<ICollectionsProps> = ({
  shopId,
  dispatch,
  permittedRouteMap,
}) => {
  const { sortingValue, collections } = useAppSelector(state => state.collections);

  useGetCollectionsList({ shopId, dispatch });

  const isListEmpty = !collections.length;

  const { filteredItems, searchInputValue, onSearchInputChange } = useSortedAndFilteredCollections({
    sortingValue,
    collections,
  });

  return (
    <main className='collections-main'>
      {isListEmpty ? (
        <EmptyCollections shopId={shopId} dispatch={dispatch} />
      ) : (
        <>
          <div className='collections-sorting-and-filtering'>
            <div className='collections-search'>
              <SearchInput
                value={searchInputValue}
                onChange={onSearchInputChange}
                placeholder='Search...'
              />
            </div>
          </div>
          <CollectionsList
            shopId={shopId}
            collectionsList={filteredItems}
            editCollectionRulesRoute={permittedRouteMap.editCollectionRules?.path}
            dispatch={dispatch}
          />
        </>
      )}
    </main>
  );
};
