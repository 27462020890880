import React, { useEffect } from 'react';
import { SuggestedOffer } from '../../types';
import { AutoCompleteForm, useAutoCompleteFetchState } from '../AutoComplete';
import { ProductCard } from '../ProductCard';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { useSimilarItemsSettings } from './useSimilarItemsSettings';
import { PermittedRouteMap } from '../../../../app-routes';
import { PreviewFeature } from '../../../../services';

interface Props {
  shopId: number;
  offer: SuggestedOffer | null;
  onOfferChange: (offer: SuggestedOffer | null) => void;
  stateController: StateController<string>;
  selectedExperience?: PreviewFeature;
  permittedRouteMap: PermittedRouteMap;
}

export function SimilarItemsSettings({
  shopId,
  offer,
  onOfferChange,
  stateController: { value, setValue },
  selectedExperience,
  permittedRouteMap,
}: Props): JSX.Element {
  useSimilarItemsSettings(shopId);

  const { reset, ...autoCompleteProps } = useAutoCompleteFetchState({
    shopId,
    onOfferChange,
    selectedExperience,
    initialValue: value,
    permittedRouteMap,
  });

  const offersImage = offer?.imageUrl || offer?.ctlImageUrl || '';
  const offersSKU = offer ? offer.sku : '';
  const suggestedOffer = {
    ...offer,
    sku: offersSKU,
    imageUrl: offersImage,
  };

  useEffect(() => {
    setValue(autoCompleteProps.selectedValue);
  }, [autoCompleteProps.selectedValue]);

  return (
    <>
      <AutoCompleteForm
        title='When shopper is viewing product...'
        placeholder='Type SKU here...'
        {...autoCompleteProps}
      />
      {!!offer && <ProductCard key={offer.sku} offer={suggestedOffer} onClose={reset} />}
    </>
  );
}
