import * as AppRoutes from 'src/app-routes';
import { buildComponentUrlArray } from './util';

export enum AppHeaderComponentName {
  ShopPublishStatusIndication = 'shop-publish-status-indication',
  ExperimentPublishStatusIndication = 'experiment-publish-status-indication',
  RunningExperimentsIndicator = 'running-experiment-notification',
  ChangeLogIcon = 'change-log-icon',
  UserAvatarList = 'user-avatar-list',
  DomainEventsWebSocket = 'domain-events-web-sockets',
  UserLocation = 'user-location',
  VisualEditor = 'visual-editor',
  InternalFeatureIndication = 'internal-feature-indication',
  NotificationsIndication = 'notifications-indications',
}

export const appHeaderComponentRoutes: Record<AppHeaderComponentName, string[]> = {
  [AppHeaderComponentName.ShopPublishStatusIndication]: buildComponentUrlArray([
    AppRoutes.baseAppRouteMap,
  ]),
  [AppHeaderComponentName.ExperimentPublishStatusIndication]: buildComponentUrlArray([
    AppRoutes.experimentRoutes,
  ]),
  [AppHeaderComponentName.RunningExperimentsIndicator]: buildComponentUrlArray([
    AppRoutes.shopSettingsSubRoutes,
    AppRoutes.shopRoutes,
    AppRoutes.experimentRoutes,
  ]),
  [AppHeaderComponentName.ChangeLogIcon]: buildComponentUrlArray([
    AppRoutes.shopSettingsSubRoutes,
    AppRoutes.shopRoutes,
    AppRoutes.experimentRoutes,
    AppRoutes.discoveryStoriesRoutes,
    AppRoutes.augmentedSearchFiltersSubRoutes,
    AppRoutes.collectionsRoutes,
    AppRoutes.uiTemplateRoutes,
    AppRoutes.filtersRoutes,
  ]),
  [AppHeaderComponentName.UserAvatarList]: buildComponentUrlArray([AppRoutes.baseAppRouteMap]),
  [AppHeaderComponentName.DomainEventsWebSocket]: buildComponentUrlArray([
    AppRoutes.baseAppRouteMap,
  ]),
  [AppHeaderComponentName.UserLocation]: buildComponentUrlArray([AppRoutes.baseAppRouteMap]),
  [AppHeaderComponentName.VisualEditor]: buildComponentUrlArray([AppRoutes.baseAppRouteMap]),
  [AppHeaderComponentName.InternalFeatureIndication]: buildComponentUrlArray([
    AppRoutes.baseAppRouteMap,
  ]),
  [AppHeaderComponentName.NotificationsIndication]: buildComponentUrlArray([
    AppRoutes.baseAppRouteMap,
  ]),
};
