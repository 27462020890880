import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './RowActionsMenu.styles';

interface RowActionMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onSetPrimaryCatalogClick?: () => void;
  onEditCatalogClick?: () => void;
  onFeedUploadsLogClick?: () => void;
  onCatalogDeleteClick: () => void;
  readOnly: boolean;
}

export const RowActionsMenu = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  // onSetPrimaryCatalogClick,
  onCatalogDeleteClick,
  onEditCatalogClick,
  onFeedUploadsLogClick,
  readOnly,
}: RowActionMenuProps): JSX.Element => {
  const isOpen = Boolean(menuAnchorElement);

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        {/* {!readOnly && (
          <MenuItemStyled
            key='set-primary'
            disabled={!onSetPrimaryCatalogClick}
            onClick={() => onItemClick(onSetPrimaryCatalogClick)}
          >
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Set as Primary
            </Typography>
          </MenuItemStyled>
        )} */}
        {!!onFeedUploadsLogClick && (
          <MenuItemStyled key='feed-upload-log' onClick={() => onItemClick(onFeedUploadsLogClick)}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Feed uploads log
            </Typography>
          </MenuItemStyled>
        )}
        {!readOnly && !!onEditCatalogClick && (
          <MenuItemStyled key='edit' onClick={() => onItemClick(onEditCatalogClick)}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Edit
            </Typography>
          </MenuItemStyled>
        )}
        {!readOnly && (
          <MenuItemStyled key='delete' onClick={onCatalogDeleteClick} shouldHighlightRed>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Delete
            </Typography>
          </MenuItemStyled>
        )}
      </Menu>
    </MenuActionsStyled>
  );
};
