import React from 'react';
import { useAppSelector, useIsFeaturePermitted } from 'src/hooks';

import {
  MerchandisingRulesManagement,
  RulePages,
  useMerchandisingRulesManagement,
} from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement';
import { ShopFeature } from 'src/app-types';
import { createOptionalPortal } from 'src/components-bl/helpers';
import { SyteProductType } from 'src/services/src/service/types/syte-products';
import { ComponentProps } from '../types';
import { RuleFormFooter } from '../../../components-bl/MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter/RuleFormFooter';
import { ButtonStyled } from '../../MerchandisingRules/CreateMerchandisingRuleContainer/CreateMerchandisingRuleContainer.styles';

export const EditMerchandisingRulesContainer: React.FC<ComponentProps> = ({
  shopId,
  currentCollection,
  formHeaderRef,
  dispatch,
}) => {
  const selectedRule = useAppSelector(state => state.merchandisingRules.selectedRule);
  const rules = useAppSelector(state => state.merchandisingRules.rules);
  const duplicateRuleDraft = useAppSelector(state => state.merchandisingRules.duplicateRuleDraft);
  const overview = useAppSelector(state => state.merchandisingRules.overview);
  const totalRulesCount = useAppSelector(state => state.merchandisingRules.totalCount);
  const currentPage = useAppSelector(state => state.merchandisingRules.currentPage);
  const dataFields = useAppSelector(state => state.dataFields.dataFields);
  const availableRegions = useAppSelector(state => state.shop.availableRegions);
  const isAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);
  const categoryFilterRule = useAppSelector(state => state.visualEditor.categoryFilterRule);
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  const merchandisingRulesSettings = useMerchandisingRulesManagement({ dispatch });

  const formButtons = (
    <>
      {currentPage !== RulePages.List && (
        <RuleFormFooter>
          <ButtonStyled variant='tertiary' onClick={merchandisingRulesSettings.onCancel}>
            Cancel
          </ButtonStyled>
          <ButtonStyled
            variant='primary'
            onClick={merchandisingRulesSettings.onSubmit}
            disabled={!merchandisingRulesSettings.formStatus.canSubmit}
            loading={merchandisingRulesSettings.formStatus.isSubmitting}
          >
            Save
          </ButtonStyled>
        </RuleFormFooter>
      )}
    </>
  );

  const buttonsPortal = createOptionalPortal({
    portalContent: formButtons,
    targetContainerRef: formHeaderRef,
    fallback: undefined,
  });

  return (
    <>
      <MerchandisingRulesManagement
        shopId={shopId}
        dataFields={dataFields}
        overview={overview}
        dispatch={dispatch}
        selectedRule={selectedRule}
        rules={rules}
        totalRulesCount={totalRulesCount}
        duplicateRuleDraft={duplicateRuleDraft}
        onFormStatusChange={merchandisingRulesSettings.onFormStatusChange}
        formApiRef={merchandisingRulesSettings.formApiRef}
        onPageChange={merchandisingRulesSettings.onPageChange}
        currentPage={currentPage}
        entityId={currentCollection?.id}
        predefinedProduct={SyteProductType.Collections}
        availableRegions={availableRegions}
        isAiTagsEnabled={isAiTagsEnabled}
        categoryFilterRule={categoryFilterRule}
        featureToggles={featureToggles}
      />
      {buttonsPortal}
    </>
  );
};
