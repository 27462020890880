import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Users, UserTypes } from 'src/services';

export interface UserFormNotifyIsDirtyActionPayload {
  isDirty: boolean;
}

export interface UserFormUpdateUserRequestActionPayload
  extends Pick<UserTypes.User, 'email' | 'role' | 'userId' | 'firstName' | 'lastName'> {
  accountId: number;
  password?: string;
}
export type UserFormCreateUserRequestActionPayload = Omit<
  UserFormUpdateUserRequestActionPayload,
  'firstName' | 'lastName'
>;

const userService = new Users();

const notifyIsDirtyActionName = 'UserForm/NotifyIsDirty';

export const userFormActions = {
  updateUser: createAsyncThunk(
    'UserForm/UpdateUser',
    async (
      { role, accountId, ...rest }: UserFormUpdateUserRequestActionPayload,
      { rejectWithValue }
    ) => {
      try {
        const updatedUser = await userService.updateUser({
          accountId,
          userData: { accessLevel: role, ...rest },
        });
        return { user: updatedUser };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createUser: createAsyncThunk(
    'UserForm/CreateUser',
    async (
      { role, accountId, ...rest }: UserFormCreateUserRequestActionPayload,
      { rejectWithValue }
    ) => {
      try {
        const createdUser = await userService.createUser({
          accountId,
          userData: { accessLevel: role, ...rest },
        });
        return { user: createdUser };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  notifyIsDirty: createAction<UserFormNotifyIsDirtyActionPayload>(notifyIsDirtyActionName),
};
