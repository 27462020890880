import React, { useMemo } from 'react';
import { Password, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ConfirmPasswordProps } from './types';
import { lengthErrorTypes, lengthErrorMessage } from './constants';
import { ConfirmPasswordStyled, TypographyStyled } from './ConfirmPassword.styles';

export const ConfirmPassword = ({ state, onChange, errors }: ConfirmPasswordProps): JSX.Element => {
  const validationStatus = useMemo(() => {
    const isLengthValid =
      lengthErrorTypes.has((errors?.password as any)?.type) ||
      lengthErrorTypes.has((errors?.confirmPassword as any)?.type);
    const passwordErrorMessage =
      errors?.password?.type === 'string.max' && errors?.password?.message;

    return {
      isLengthValid,
      passwordErrorMessage,
    };
  }, [errors]);

  const confirmPasswordErrorMessage =
    !!state.confirmPassword?.length &&
    state.confirmPassword !== state.password &&
    "Passwords don't match";

  return (
    <ConfirmPasswordStyled>
      <TypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
        isError={validationStatus.isLengthValid}
      >
        {lengthErrorMessage}
      </TypographyStyled>
      <Password
        className='syte-confirm-password-new-password'
        value={state.password || ''}
        onChange={password => onChange({ password })}
        error={validationStatus.passwordErrorMessage || !!errors?.password}
      />
      <Password
        className='syte-confirm-password-confirm-password'
        value={state.confirmPassword || ''}
        onChange={confirmPassword => onChange({ confirmPassword })}
        error={confirmPasswordErrorMessage}
      />
    </ConfirmPasswordStyled>
  );
};
