import { UserTypes, UserBuilder } from '../../service/types';

class UsersRequestBuilder {
  static getCreateUserPayload({
    userData,
    accountId,
  }: UserTypes.CreateUserArgs): UserBuilder.CreateUserPayload {
    return { user: userData, accountId };
  }

  static getUpdateUserPayload({
    userData,
    accountId,
  }: UserTypes.UpdateUserArgs): UserBuilder.UpdateUserPayload {
    return { updateObject: userData, accountId };
  }

  static getForgotPasswordPayload(email: string): UserBuilder.ForgotPasswordPayload {
    return { userEmail: email };
  }
}

export { UsersRequestBuilder };
