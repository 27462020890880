import Joi from 'joi';

export const emailValidationSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required()
  .messages({ 'string.empty': 'Email can not be empty', 'string.email': 'Email is invalid' });

export const passwordLengthMin = 6;
export const passwordLengthMax = 40;

export const passwordValidationSchema = Joi.string()
  .min(passwordLengthMin)
  .max(passwordLengthMax)
  .required()
  .messages({
    'string.empty': 'Incorrect password',
    'string.min': `Incorrect password`,
    'string.max': `Incorrect password`,
  });
