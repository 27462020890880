import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { startCase } from 'lodash';
import { SkuConfiguration } from './types';
import {
  ProductCardStyled,
  ImageStyled,
  ImageWrapperStyled,
  BottomRowStyled,
  OriginalPriceTypography,
  PriceTypography,
} from './ProductCard.styles';

interface ProductCardProps {
  skuConfiguration: SkuConfiguration;
}
function formatPrice({ price, currency }: Pick<SkuConfiguration, 'price' | 'currency'>) {
  return `${currency}${price}`;
}
export const ProductCard = ({ skuConfiguration }: ProductCardProps): JSX.Element => {
  return (
    <ProductCardStyled>
      <ImageWrapperStyled>
        <ImageStyled src={skuConfiguration.imageUrl} />
      </ImageWrapperStyled>

      <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
        {skuConfiguration.title}
      </Typography>

      <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        {skuConfiguration.brand}
      </Typography>

      <BottomRowStyled>
        <PriceTypography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
          {formatPrice({ price: skuConfiguration.price, currency: skuConfiguration.currency })}{' '}
        </PriceTypography>

        {skuConfiguration.originalPrice && (
          <OriginalPriceTypography
            type={TypographyType.Body}
            variant={TypographyVariant.ExtraSmallRegular}
          >
            {formatPrice({
              price: skuConfiguration.originalPrice,
              currency: skuConfiguration.currency,
            })}
          </OriginalPriceTypography>
        )}

        <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
          {' '}
          | {startCase(skuConfiguration.gender)}
          {skuConfiguration.categories[0] && ` | ${startCase(skuConfiguration.categories[0])}`}
        </Typography>
      </BottomRowStyled>
    </ProductCardStyled>
  );
};
