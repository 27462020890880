import { useCallback, useEffect, useMemo, useState } from 'react';
import { AdData } from '../../../../../../services';

export const useGetVariant = (offer: AdData) => {
  const [variant, setVariant] = useState(offer);
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);

  const variants = useMemo(() => offer?.variants || [], [offer]);

  useEffect(() => {
    setVariant(variants?.[0] || offer);
  }, [variants]);

  const onColorVariantClick = useCallback(
    (variantIndex: number) => {
      setVariant(variants[variantIndex]);
      setSelectedColorIndex(variantIndex);
    },
    [variants]
  );

  const onSizeVariantClick = useCallback(
    (variantIndex: number) => {
      setVariant(variants[selectedColorIndex].size_variants[variantIndex]);
      setSelectedSizeIndex(variantIndex);
    },
    [variants, selectedColorIndex]
  );

  return {
    variant,
    variants,
    selectedColorIndex,
    selectedSizeIndex,
    onColorVariantClick,
    onSizeVariantClick,
  };
};
