import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Accordion, AvailableIcons, Icon } from 'src/components-dummy';
import { CustomInspirationsGalleryImage } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { getQueryStringParams, queryKey } from '../../../../../common/utils';
import { ExpandableSectionTrigger } from '../../../../../ExpandableSectionTrigger';
import { TextSection } from '../TextSection';
import {
  ExpandableOptionsStyled,
  ExpandableSectionTriggerStyled,
} from './ExpandableOptions.styled';
import { TagsSection } from '../TagsSection';

export enum ExpandableSection {
  AltText = 'alt_text',
  Caption = 'caption',
  ProductTags = 'product_tags',
}

interface ExpandableOptionsProps {
  image: CustomInspirationsGalleryImage;
  disabled: boolean;
  errors: ParseJoiValidateResponse<Pick<CustomInspirationsGalleryImage, 'altText' | 'caption'>>;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
  removeTagById: (id: string) => void;
  onSectionExpand: (newSections: ExpandableSection[] | null) => void;
}

const allExpandedOptions = Object.values(ExpandableSection);

export function ExpandableOptions({
  image,
  disabled,
  errors,
  onChange,
  removeTagById,
  onSectionExpand,
}: ExpandableOptionsProps): JSX.Element {
  const { push } = useHistory();
  const { pathname, search: queryString } = useLocation();

  const queryObject = useMemo(() => new URLSearchParams(queryString), [queryString]);
  const initialExpandedSections = useMemo(() => {
    const queryValuesOnLoad = queryObject.getAll(queryKey) as ExpandableSection[];

    if (queryValuesOnLoad.every(param => allExpandedOptions.includes(param))) {
      return queryValuesOnLoad;
    }
    return null;
  }, [queryObject]);

  const [expandedSections, setExpandedSections] = useState<ExpandableSection[] | null>(
    initialExpandedSections
  );

  const updateExpandedOptionsQueryString = useCallback(
    (sections: ExpandableSection[] | null) => {
      let targetQueryString: string;

      if (sections === null) {
        queryObject.delete(queryKey);
        targetQueryString = queryObject.toString();
      } else {
        targetQueryString = getQueryStringParams(sections);
      }

      if (targetQueryString === queryString) return;

      const path = targetQueryString ? `${pathname}?${targetQueryString}` : pathname;
      push(path);
    },
    [pathname, queryString, push, queryObject]
  );

  const onExpandSelectionChange = useCallback(
    (sections: string[]): void => {
      setExpandedSections(sections as ExpandableSection[]);
    },
    [setExpandedSections]
  );

  const onTextChange = useCallback(
    (textType: keyof Pick<CustomInspirationsGalleryImage, 'altText' | 'caption'>) =>
      (updatedText: string) => {
        onChange({ [textType]: updatedText || undefined });
      },
    [onChange]
  );

  useEffect(() => {
    updateExpandedOptionsQueryString(expandedSections);
    onSectionExpand(expandedSections);
  }, [expandedSections, updateExpandedOptionsQueryString, onSectionExpand]);

  const isSectionExpanded = useCallback(
    (section: ExpandableSection) => expandedSections?.includes(section) || false,
    [expandedSections]
  );

  return (
    <ExpandableOptionsStyled>
      <Accordion
        onSelectionChanged={onExpandSelectionChange}
        expandedIds={expandedSections as string[]}
        disabled={disabled}
        canExpandMultiple
      >
        <Accordion.Item id={ExpandableSection.AltText} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTriggerStyled
              title='Alt Text'
              isExpanded={isSectionExpanded(ExpandableSection.AltText)}
              isDisabled={disabled}
            />
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TextSection
              label='Alt text'
              value={image.altText}
              errorMessage={errors.altText?.message}
              onChange={onTextChange('altText')}
            />
          </Accordion.Item.Content>
        </Accordion.Item>

        <Accordion.Item id={ExpandableSection.Caption} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTrigger
              title='Caption'
              isExpanded={isSectionExpanded(ExpandableSection.Caption)}
              isDisabled={disabled}
            />
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TextSection
              label='Caption'
              value={image.caption}
              errorMessage={errors.caption?.message}
              onChange={onTextChange('caption')}
            />
          </Accordion.Item.Content>
        </Accordion.Item>

        <Accordion.Item id={ExpandableSection.ProductTags} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTrigger
              title='Product Tags'
              isExpanded={isSectionExpanded(ExpandableSection.ProductTags)}
              isDisabled={disabled}
            >
              <ExpandableSectionTrigger.TitleIcon>
                <Icon name={AvailableIcons.ProductTags} />
              </ExpandableSectionTrigger.TitleIcon>
            </ExpandableSectionTrigger>
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TagsSection tags={image.tags} removeTagById={removeTagById} />
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </ExpandableOptionsStyled>
  );
}
