import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResetPasswordForm, ResetPasswordFormSuccess } from './components';
import { LoginLayout } from '../Login';

interface ResetPasswordContainerProps {
  loginPath: string;
}

export const ResetPasswordContainer = ({ loginPath }: ResetPasswordContainerProps): JSX.Element => {
  const { push } = useHistory();
  const [resetSuccess, setResetSuccess] = useState(false);

  const goToLogin = () => {
    push(loginPath);
  };

  return (
    <LoginLayout>
      {resetSuccess ? (
        <ResetPasswordFormSuccess goToLogin={goToLogin} />
      ) : (
        <ResetPasswordForm onSuccess={() => setResetSuccess(true)} goToLogin={goToLogin} />
      )}
    </LoginLayout>
  );
};
