import React from 'react';
import { useParams } from 'react-router';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { EditCustomInspirationsGalleryPage } from 'src/components-bl/Galleries';
import { useHasDraft } from 'src/components-bl/hooks';
import { useAppDispatch } from 'src/hooks';
import { useCustomInspirationsGallerySelector } from './useCustomInspirationsGallerySelector';
import { AddImagesContainer } from './AddImagesContainer';
import { TagImageContainer } from './TagImageContainer';

interface EditCustomInspirationsContainerProps extends RoutedComponentProps {
  shopId: number;
}

export const EditCustomInspirationsContainer = ({
  permittedRouteMap,
  shopId,
}: EditCustomInspirationsContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { galleryId } = useParams<{ galleryId: string }>();

  const gallery = useCustomInspirationsGallerySelector();
  const hasDraft = useHasDraft();

  return (
    <>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.addImagesToCustomInspirationsGallery}
          Component={AddImagesContainer}
          componentProps={{ permittedRouteMap, shopId }}
          exact
        />
        <AppRoute
          route={permittedRouteMap.tagImageInCustomInspirationsGallery}
          Component={TagImageContainer}
          componentProps={{ permittedRouteMap, shopId }}
          exact
        />
      </AppSwitch>

      <EditCustomInspirationsGalleryPage
        dispatch={dispatch}
        gallery={gallery}
        galleryId={galleryId}
        shopId={shopId}
        permittedRouteMap={permittedRouteMap}
        hasDraft={hasDraft}
      />
    </>
  );
};
