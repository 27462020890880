import React from 'react';
import { MerchandisingRuleTypes } from 'src/services';
import {
  TypographyType,
  TypographyVariant,
  ActionIcon,
  AvailableIcons,
} from 'src/components-dummy';
import { reduceErrorsByPrefix } from 'src/utils';
import { DataFieldsCondition } from 'src/components-bl/MerchandisingRules/components/DataFieldsCondition/DataFieldsCondition';
import { UseDataFieldsLookupTableArgumentsReturnType } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { DataFieldConditionDataType } from 'src/components-bl/MerchandisingRules/components/DataFieldsCondition/DataFieldsCondition.types';
import { Dispatch } from 'src/components-bl';
import { ConditionsGroup } from '../../ConditionsGroup/ConditionsGroup';
import {
  ConditionRowStyled,
  ConditionRowDataStyled,
  ConditionRowErrors,
  ConditionText,
} from '../RuleFormConditionsSection.styles';
import { RuleDraft, RuleDraftCondition } from '../../../MerchandisingRuleForm.config';
import { RuleConditionsAddGroup } from '../../RuleConditionsAddGroup/RuleConditionsAddGroup';
import { ruleFormMappers } from '../../../MerchandisingRuleForm.mappers';
import { getConditionTextPrefix } from '../RuleFormConditionsSection.helpers';
import { mapErrorMessages } from '../../../../../mapErrorMessages';

const TITLE_TEXT = 'Condition (Apply when)';
const SUB_TITLE_TEXT =
  'You can add a condition to trigger this rule (for example, a specific product page or search term or locale)';

export interface ConditionsApplyWhenGroupProps extends UseDataFieldsLookupTableArgumentsReturnType {
  conditions: RuleDraftCondition[];
  conditionTypesOptions: MerchandisingRuleTypes.MerchandisingRuleSubType[];
  shopId: number;
  onFieldChange: (data: Partial<Pick<RuleDraft, 'action' | 'weight' | 'sourceCondition'>>) => void;
  dispatch: Dispatch;
  errors: any; // TODO
  isReadOnly?: boolean;
  isAiTagsEnabled: boolean;
}

export const ConditionsApplyWhenGroup = ({
  shopId,
  conditions,
  dataFieldsLookupTable,
  getDataFieldByName,
  conditionTypesOptions,
  errors,
  isReadOnly,
  isAiTagsEnabled,
  onFieldChange,
  dispatch,
}: ConditionsApplyWhenGroupProps): JSX.Element => {
  const onConditionChange = (changedCondition: DataFieldConditionDataType) => {
    const changedIndex = conditions.findIndex(
      condition => condition.tempId === changedCondition.tempId
    );

    const updatedConditions = [...conditions];
    updatedConditions[changedIndex] = changedCondition;

    onFieldChange({ sourceCondition: updatedConditions });
  };

  const onAddNewCondition = () => {
    const updatedConditions = [...conditions, ruleFormMappers.createEmptyCondition()];
    onFieldChange({ sourceCondition: updatedConditions });
  };

  const onDeleteCondition = (conditionToDelete: RuleDraftCondition) => {
    const updatedConditions = conditions.filter(
      condition => condition.tempId !== conditionToDelete.tempId
    );

    onFieldChange({ sourceCondition: updatedConditions });
  };

  const isInAddMode = conditions.length === 0;

  return isInAddMode && !isReadOnly ? (
    <RuleConditionsAddGroup
      title={TITLE_TEXT}
      subTitle={SUB_TITLE_TEXT}
      onClick={onAddNewCondition}
    />
  ) : (
    <ConditionsGroup>
      <ConditionsGroup.Title>{TITLE_TEXT}</ConditionsGroup.Title>
      <ConditionsGroup.SubTitle>{SUB_TITLE_TEXT}</ConditionsGroup.SubTitle>
      <ConditionsGroup.Content>
        {conditions.map((condition, index) => {
          const localErrors = reduceErrorsByPrefix({
            errors,
            prefix: `sourceCondition.${index}`,
          });

          const rowErrorLabels = mapErrorMessages(localErrors);

          return (
            <ConditionRowStyled key={condition.tempId}>
              <ConditionRowDataStyled>
                <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                  {getConditionTextPrefix(index)}
                </ConditionText>

                <DataFieldsCondition
                  shopId={shopId}
                  data={condition}
                  dataFieldsLookupTable={dataFieldsLookupTable}
                  getDataFieldByName={getDataFieldByName}
                  conditionTypes={conditionTypesOptions}
                  onChange={onConditionChange}
                  errors={localErrors}
                  dispatch={dispatch}
                  disabled={isReadOnly}
                  isAiTagsEnabled={isAiTagsEnabled}
                />
                {!isReadOnly && (
                  <ActionIcon
                    iconName={AvailableIcons.TrashCan}
                    onClick={() => onDeleteCondition(condition)}
                  />
                )}
              </ConditionRowDataStyled>
              {rowErrorLabels.length > 0 ? (
                <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
              ) : null}
            </ConditionRowStyled>
          );
        })}
      </ConditionsGroup.Content>
    </ConditionsGroup>
  );
};
