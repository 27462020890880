import React, { useEffect, useState } from 'react';
import { LoginForm, ForgotPasswordForm } from './components';
import { FormNames } from './types';
import { LoginLayout } from './LoginLayout';

export const LoginContainer = (): JSX.Element => {
  const [forgotPassWordEmail, setForgotPasswordEmail] = useState('');
  const [selectedForm, setSelectedForm] = useState(FormNames.LoginForm);

  const goToLoginForm = () => setSelectedForm(FormNames.LoginForm);
  const goToForgotPasswordForm = (email: string | undefined) => {
    setSelectedForm(FormNames.ForgotPasswordForm);

    if (email) {
      setForgotPasswordEmail(email);
    }
  };

  useEffect(() => {
    setForgotPasswordEmail('');
  }, [selectedForm]);

  return (
    <LoginLayout>
      {selectedForm === FormNames.LoginForm ? (
        <LoginForm goToForgotPasswordForm={goToForgotPasswordForm} />
      ) : (
        <ForgotPasswordForm goToLoginForm={goToLoginForm} email={forgotPassWordEmail} />
      )}
    </LoginLayout>
  );
};
