import { AxiosResponse } from 'axios';
import { UserTypes } from '../types';

export class UsersMapper {
  static map({ data }: AxiosResponse): UserTypes.User {
    const backendUser: UserTypes.UserBackEnd = data.data.user || data.data;
    const { userId, email, accessLevel: role, accounts, firstName, lastName, online } = backendUser;

    const user: UserTypes.User = {
      userId,
      email,
      online,
      firstName,
      lastName,
      role,
      accountId: accounts?.[0] || 0,
    };
    return user;
  }

  static mapSuccessMessage({ data }: AxiosResponse): string {
    return data.data.message;
  }
}
