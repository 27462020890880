import { ToastMiddlewareActionTypes } from 'src/middleware-actions';
import { resetPasswordActions } from 'src/containers';
import { ToastTypesRegex } from './types';

export const toastTypesRegex: ToastTypesRegex = {
  error: /error|notfound|rejected|ErrorNotification/i,
  success: /Success|fulfilled|Notification/i,
  toastNotification: /CreateToast/i,
};

export const specificErrorActions: Set<string> = new Set([
  ToastMiddlewareActionTypes.ForceLogoutFinished,
  ToastMiddlewareActionTypes.ForceLogoutFailed,
  resetPasswordActions.invalidToken.type,
]);
