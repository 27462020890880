import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { visualEditorFetchOffersActions } from '../state';
import { useDebounce } from '../../../components-dummy';
import { useVisualEditorProvider } from '../context';
import { useGetDataFieldsToDisplay } from './useGetDataFieldsToDisplay';
import { AsyncThunkPromise } from '../../../types';
import { BrowsePlpPayload, IndexedProductOffers } from '../types';

export const useFetchPLPOffers = (shopId: number) => {
  const { categoryFilterRule } = useAppSelector(state => state.visualEditor);

  const { dispatchOffersThunk } = useVisualEditorProvider();
  const thunkPromise = useRef<AsyncThunkPromise<IndexedProductOffers, BrowsePlpPayload> | null>(
    null
  );

  const [categoryValue, setCategoryValue] = useState<string>('');

  const { fields } = useGetDataFieldsToDisplay({ shopId });
  const { field, operator } = categoryFilterRule || {};

  const fetchPlpOffers = useCallback(
    (payload: BrowsePlpPayload) => {
      thunkPromise.current = dispatchOffersThunk(
        visualEditorFetchOffersActions.getBrowsePLPItems,
        payload
      );
    },
    [dispatchOffersThunk]
  );

  const fetchOffersDebounced = useDebounce(fetchPlpOffers, 300);

  const updateCategoryValue = useCallback(
    (searchValue: string) => {
      const searchValueTrimmed = searchValue.trim();

      if (searchValueTrimmed.length < 2) {
        fetchOffersDebounced.cancel();
        thunkPromise.current?.abort();
        setCategoryValue('');
        return;
      }
      fetchOffersDebounced({
        shopId,
        field,
        operator,
        value: searchValueTrimmed,
        fields,
        colorVariantFields: fields,
        sizeVariantFields: fields,
      });
      setCategoryValue(searchValue);
    },
    [shopId, fetchOffersDebounced, fields, field, operator]
  );

  useEffect(() => {
    updateCategoryValue(categoryValue);
  }, [fields]);

  return {
    updateCategoryValue,
  };
};
