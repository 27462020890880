import { Dispatch } from '../../../types';
import {
  LogHistoryPanelActionTypes,
  LogHistoryPanelSetOpenedActionPayload,
  LogHistoryPanelSetOpenedAction,
} from './types';

const logHistoryPanelActions = {
  setOpened(payload: LogHistoryPanelSetOpenedActionPayload): LogHistoryPanelSetOpenedAction {
    return {
      type: LogHistoryPanelActionTypes.SetOpened,
      payload,
    };
  },
};

export const logHistoryPanelActionMethods = {
  setOpened(payload: LogHistoryPanelSetOpenedActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(logHistoryPanelActions.setOpened(payload));
    };
  },
};
