import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';
import { BlurredImageContainerStyled } from '../../../common/styles';

export const TaggableImageBackgroundStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const TaggableImageWrapperStyled = styled(BlurredImageContainerStyled)<{
  isClickable: boolean;
}>`
  width: 480px;
  height: 480px;
  .syte-blurred-image {
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
  }
`;

export const EditModeTextWrapperStyled = styled.div`
  height: 20px;
`;

export const EditModeTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
`;
