import { AvailableIcons, NotificationType } from 'src/components-dummy';
import { DomainEntityPath, ExperimentSlim, ShopDraft } from 'src/services';
import { Dispatch } from '../../types';

export enum PublishStatusIndicationMode {
  UpToDate = 'UpToDate',
  AutomaticPublish = 'AutomaticPublish',
  CanPublish = 'CanPublish',
}

export interface PublishStatusIndicationProps {
  draft?: ShopDraft | undefined;
  shopId: number;
  dispatch: Dispatch;
  mode: PublishStatusIndicationMode.AutomaticPublish | PublishStatusIndicationMode.CanPublish;
  text?: string;
  runningExperiment?: ExperimentSlim;
}

export type PublishStatusIndicationArguments = PublishStatusIndicationProps;

export interface PublishDiscardParameters {
  isAllSelected: boolean;
  selectedDomainGroups: DomainEntityPath[];
  totalChanges: number;
}

export interface UsePublishStatusIndicationReturnType {
  onDiscardDraft: (parameters: PublishDiscardParameters) => void;
  onPublishDraft: (parameters: PublishDiscardParameters) => Promise<void>;
  modalIsOpened: boolean;
  setModalIsOpened: (isOpened: boolean) => void;
  modeConfig: ModeConfig;
  actualMode: PublishStatusIndicationMode;
}

export interface ReviewBeforePublishModalProps
  extends Pick<
      UsePublishStatusIndicationReturnType,
      'onPublishDraft' | 'onDiscardDraft' | 'setModalIsOpened'
    >,
    Pick<PublishStatusIndicationProps, 'draft' | 'runningExperiment'> {
  show: boolean;
  // children: ReactNode;
}

export interface UnsavedChangesActionsPopupProps {
  children: JSX.Element;
  onReviewAndPublishClick: () => void;
}

export enum DraftActions {
  Publish = 'Publish',
  Discard = 'Discard',
}

export interface ModeConfig {
  icon?: AvailableIcons;
  notificationType: NotificationType;
  text: string;
}
