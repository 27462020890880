import {
  CreateCollectionButtonActionTypes,
  CreateCollectionActionPayload,
  CreateCollectionRequestActionPayload,
  CreateCollectionSuccessActionPayload,
  CreateCollectionErrorActionPayload,
  CreateCollectionErrorAction,
  CreateCollectionRequestAction,
  CreateCollectionSuccessAction,
} from './types';
import { Dispatch } from '../../../types';
import { Collection } from '../../types';
import { collectionsService } from '../../../../services';
import { collectionRulesActionMethods } from '../../EditCollectionRules';
import { convertRuleGroupsToRuleGroupsWithShopId } from '../../EditCollectionRules/CollectionRules/helpers/convertRuleGroupsToRuleGroupsWithShopId';

const CreateCollectionActions = {
  CreateCollectionRequest(
    payload: CreateCollectionRequestActionPayload
  ): CreateCollectionRequestAction {
    return {
      type: CreateCollectionButtonActionTypes.CreateCollectionRequest,
      payload,
    };
  },

  CreateCollectionSuccess(
    payload: CreateCollectionSuccessActionPayload
  ): CreateCollectionSuccessAction {
    return {
      type: CreateCollectionButtonActionTypes.CreateCollectionSuccess,
      payload,
    };
  },

  CreateCollectionError(payload: CreateCollectionErrorActionPayload): CreateCollectionErrorAction {
    return {
      type: CreateCollectionButtonActionTypes.CreateCollectionError,
      payload,
    };
  },
};

export const CreateCollectionActionMethods = {
  CreateCollection(
    payload: CreateCollectionActionPayload,
    onSuccess: (dispatch: Dispatch, collection?: Collection) => void
  ) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(CreateCollectionActions.CreateCollectionRequest(payload));
        const collection = await collectionsService.createCollection(payload);
        dispatch(
          collectionRulesActionMethods.saveCollectionRulesDiff({
            shopId: collection.shopId,
            collectionId: collection.id,
            collectionName: collection.name,
            diff: {
              addedRuleGroups: convertRuleGroupsToRuleGroupsWithShopId(
                payload.ruleGroups,
                collection.shopId,
                collection.id
              ),
            },
          })
        );
        dispatch(CreateCollectionActions.CreateCollectionSuccess({ collection }));
        onSuccess(dispatch, collection);
      } catch (error) {
        dispatch(CreateCollectionActions.CreateCollectionError({ error }));
      }
    };
  },
};
