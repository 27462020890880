import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Dispatch } from 'src/components-bl/types';
import { AvailableIcons, Icon, ImageSize } from 'src/components-dummy';
import { CustomInspirationsGalleryImage, ImageTagCoordinates, ImageTagWithId } from 'src/services';
import { DataFieldAutoSuggestion } from '../DataFieldAutoSuggestion';
import { PopUpWithOffset } from '../PopUpWithOffset';
import { calculatePosition } from '../utils';

export interface AddNewTagsPopUpProps {
  image: CustomInspirationsGalleryImage;
  dispatch: Dispatch;
  shopId: number;
  onAddNewTag: (newTag: ImageTagWithId) => void;
  imageRef: MutableRefObject<HTMLImageElement | null>;
  imageSize: ImageSize | null;
}

export const frameBoxSize = {
  width: 100,
  height: 92,
};

export const AddNewTagsPopUp = ({
  image,
  dispatch,
  shopId,
  onAddNewTag,
  imageRef,
  imageSize,
}: AddNewTagsPopUpProps): JSX.Element | null => {
  const [showPopup, setShowPopup] = useState(false);
  const [frameBoxCoordinates, setFrameBoxCoordinates] = useState<ImageTagCoordinates | null>(null);

  const [pendingPinCoordinates, setPendingPinCoordinates] = useState<ImageTagCoordinates | null>(
    null
  );

  const onImageClick = useCallback(
    (event: MouseEvent) => {
      setShowPopup(false);

      if (!event.target || !imageSize) return;

      const imageElement = event.target as any;

      const x = event.offsetX;
      const y = event.offsetY;

      const percentageX = Math.floor((x / imageElement.width) * 100);
      const percentageY = Math.floor((y / imageElement.height) * 100);

      setPendingPinCoordinates({ x: percentageX, y: percentageY });

      const offsetX = imageSize.offsetLeft;
      const offsetY = imageSize.offsetTop;

      const maxPossibleXValue = imageSize.width - frameBoxSize.width + offsetX;
      const maxPossibleYValue = imageSize.height - frameBoxSize.height + offsetY;

      const frameBoxX = calculatePosition({
        position: offsetX + (x - frameBoxSize.width / 2),
        min: offsetX,
        max: maxPossibleXValue,
      });

      const frameBoxY = calculatePosition({
        position: offsetY + (y - frameBoxSize.width / 2),
        min: offsetY,
        max: maxPossibleYValue,
      });

      setFrameBoxCoordinates({
        x: frameBoxX,
        y: frameBoxY,
      });

      setShowPopup(true);
    },
    [setPendingPinCoordinates, setFrameBoxCoordinates, imageSize, setShowPopup]
  );

  const resetPendingCoordinates = useCallback(() => {
    setFrameBoxCoordinates(null);
    setPendingPinCoordinates(null);
  }, [setPendingPinCoordinates, setFrameBoxCoordinates]);

  const onSelectValue = useCallback(
    (title: string, sku: string) => {
      setShowPopup(false);

      if (pendingPinCoordinates) {
        onAddNewTag({
          id: v4().toString(),
          title,
          sku,
          coordinates: pendingPinCoordinates,
        });
      }

      resetPendingCoordinates();
    },
    [setShowPopup, resetPendingCoordinates, pendingPinCoordinates, onAddNewTag]
  );

  useEffect(() => {
    const reference = imageRef.current;
    reference?.addEventListener('click', onImageClick);

    return () => {
      reference?.removeEventListener('click', onImageClick);
    };
  }, [imageRef, onImageClick]);

  const onPopupShow = useCallback(
    (show: boolean) => {
      if (!show) {
        resetPendingCoordinates();
        setShowPopup(false);
      }
    },
    [resetPendingCoordinates, setShowPopup]
  );

  useEffect(() => {
    resetPendingCoordinates();
    setShowPopup(false);
  }, [image.imageUrl, resetPendingCoordinates, setShowPopup]);

  return frameBoxCoordinates ? (
    <PopUpWithOffset
      triggerWidth={frameBoxSize.width}
      triggerHeight={frameBoxSize.height}
      offsetTop={frameBoxCoordinates?.y || 0}
      offsetLeft={frameBoxCoordinates?.x || 0}
      show={showPopup}
      onPopupShow={onPopupShow}
      closeOnClickOutside
    >
      <PopUpWithOffset.Trigger>
        <Icon name={AvailableIcons.Frame} />
      </PopUpWithOffset.Trigger>
      <PopUpWithOffset.Content>
        <DataFieldAutoSuggestion onChange={onSelectValue} dispatch={dispatch} shopId={shopId} />
      </PopUpWithOffset.Content>
    </PopUpWithOffset>
  ) : null;
};
