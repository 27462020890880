import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';
import { tableColumns } from './tableColumns';

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)<{ cellName: string }>`
  display: flex;
  align-items: center;
  color: ${({ cellName, theme }) =>
    cellName === tableColumns.name.accessor
      ? theme.palette.custom['primary-main']
      : theme.palette.common.black};

  max-height: 40px;
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;
