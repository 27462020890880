import React from 'react';
import {
  TypographyVariant,
  EllipsisWithTooltip,
  AvailableIcons,
} from '../../../../../components-dummy';
import { ProductDescriptionItem } from './ProductDescriptionItem';
import { renderSpecialDataFields } from './render-special-data-fields';
import { convertDataFieldValueToString } from './helpers/convert-data-field-value-to-string';
import { AdData, ColorVariant } from '../../../../../services';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import {
  Collapse,
  CollapseContent,
  CollapseTrigger,
  ProductDescriptionContainer,
  ProductDescriptionHeader,
} from './ProductTile.styles';

interface ProductDescriptionProps {
  offer: AdData;
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  isOpen: boolean;
  toggleAllItems: VoidFunction;
  variants: ColorVariant[];
  selectedColorIndex: number;
  onColorVariantClick: (index: number) => void;
  selectedSizeIndex: number;
  onSizeVariantClick: (index: number) => void;
}

export function ProductDescription({
  offer,
  dataFieldsToDisplay,
  specialDataFieldsToDisplay,
  isOpen,
  toggleAllItems,
  variants,
  selectedColorIndex,
  onColorVariantClick,
  selectedSizeIndex,
  onSizeVariantClick,
}: ProductDescriptionProps): JSX.Element {
  const activeDataFields = specialDataFieldsToDisplay.filter(field => field.isActive);

  const end = 3 - activeDataFields.length;
  const first3rows = [...dataFieldsToDisplay].slice(0, end);
  const restRows = [...dataFieldsToDisplay].slice(end);

  return (
    <ProductDescriptionContainer>
      {renderSpecialDataFields({
        offer,
        specialDataFieldsToDisplay,
        variants,
        selectedColorIndex,
        onColorVariantClick,
        selectedSizeIndex,
        onSizeVariantClick,
      })}
      <>
        {first3rows.map((field, index) => {
          const fieldValue = convertDataFieldValueToString(offer, field);
          if (index === 0 && activeDataFields.length === 0) {
            return (
              <ProductDescriptionHeader key='static' variant={TypographyVariant.MediumRegular}>
                <EllipsisWithTooltip tooltipText={fieldValue}>{fieldValue}</EllipsisWithTooltip>
              </ProductDescriptionHeader>
            );
          }
          return <ProductDescriptionItem key={field.id} text={field.text} value={fieldValue} />;
        })}

        {restRows.length !== 0 && (
          <Collapse style={{ maxHeight: isOpen ? 'fit-content' : '0px' }}>
            {isOpen && (
              <CollapseContent>
                {restRows.map(field => {
                  const fieldValue = convertDataFieldValueToString(offer, field);
                  return (
                    <ProductDescriptionItem key={field.id} text={field.text} value={fieldValue} />
                  );
                })}
              </CollapseContent>
            )}
            <CollapseTrigger
              name={AvailableIcons.RightArrow}
              onClick={toggleAllItems}
              className={!isOpen ? 'closed' : ''}
            />
          </Collapse>
        )}
      </>
    </ProductDescriptionContainer>
  );
}
