import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, generatePath } from 'react-router';
import { RoutedComponentProps, AppSwitch, AppRoute } from 'src/app-routes';
import { CatalogManagerListContainer } from './components';
import { useCatalogManagerWS } from './useCatalogManagerWS';
import { CatalogFormContainer } from './CatalogFormContainer';
import { catalogManagerContainerActions } from './CatalogManagerContainer.actions';
import { CatalogFeedUploadLogContainer } from './CatalogFeedUploadLogContainer/CatalogFeedUploadLogContainer';
import { CatalogUploadErrorReportListContainer } from './CatalogUploadErrorReportListContainer';

interface CatalogManagerContainerProps extends RoutedComponentProps {
  shopId?: number;
}

export const CatalogManagerContainer = ({
  permittedRouteMap,
  shopId,
}: CatalogManagerContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();

  useCatalogManagerWS({ shopId, dispatch });

  useEffect(() => {
    if (shopId) {
      dispatch(catalogManagerContainerActions.getCatalogs({ shopId }));
    }
  }, [shopId, dispatch]);

  return shopId ? (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.catalogManagerList}
        Component={CatalogManagerListContainer}
        componentProps={{ permittedRouteMap }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.catalogManagerCreateCatalog}
        Component={CatalogFormContainer}
        componentProps={{ permittedRouteMap }}
      />

      <AppRoute
        route={permittedRouteMap.catalogManagerUpdateCatalog}
        Component={CatalogFormContainer}
        componentProps={{ permittedRouteMap }}
      />

      <AppRoute
        route={permittedRouteMap.catalogManagerFeedUploadLog}
        Component={CatalogFeedUploadLogContainer}
        componentProps={{ permittedRouteMap }}
      />

      <AppRoute
        route={permittedRouteMap.catalogManagerUploadErrorReportList}
        Component={CatalogUploadErrorReportListContainer}
        componentProps={{ permittedRouteMap }}
      />

      {permittedRouteMap.catalogManagerList && shopId && (
        <Redirect to={generatePath(permittedRouteMap.catalogManagerList.path, { shopId })} />
      )}
    </AppSwitch>
  ) : null;
};
