import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Accounts } from 'src/services';
import { UpdateAccountPlanArgs } from '../../services/src/service/types/accounts/account';

const accountService = new Accounts();

export const accountPlansActions = {
  updateAccountPlan: createAsyncThunk(
    'AccountPlans/UpdateAccountPlan',
    async ({ accountId, accountPlan }: UpdateAccountPlanArgs, { rejectWithValue }) => {
      try {
        const updatedAccount = await accountService.updateAccountPlan({
          accountId,
          accountPlan,
        });
        return { account: updatedAccount };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  notifyIsDirty: createAction<{ isDirty: boolean }>('AccountPlans/NotifyIsDirty'),
};
