import { ShopChangeLog } from 'src/services';
import { Dispatch } from '../../types';

export enum LogHistoryType {
  Version = 'Version',
  Variant = 'Variant',
}

export interface LogHistoryPanelProps {
  dispatch: Dispatch;
  type?: LogHistoryType;
  logs: ShopChangeLog[];
  opened: boolean;
  versionId?: number | undefined;
  variantName?: string | undefined;
}

export interface LogHistoryProps {
  log: ShopChangeLog;
}
