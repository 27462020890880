import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { MenuItem, TableV2, EllipsisWithTooltip, Icon } from 'src/components-dummy';

/**
 * Menu actions
 */
export const MenuActionsStyled = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;

export const MenuItemDeleteStyled = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.custom.red};

  svg > path {
    stroke: ${({ theme }) => theme.palette.custom.red};
  }
`;

/**
 * Edited text
 */
export const EditedTextStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const MasterTagStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom.purple};
`;

export const TableFiltersHeadStyled = styled(TableV2.Head)`
  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const TableAutoCompleteWrapperStyled = styled.div`
  width: 100%;

  & > .syte-popup-trigger {
    width: 100%;
  }

  .MuiAutocomplete-root {
    /* Attention temp until implemented autoComplete*/
    /* background: ${({ theme }) => theme.palette.custom.white}; */
    #auto-complete {
      /* background: ${({ theme }) => theme.palette.custom.white}; */
    }
  }
`;

export const TableTextBoxWrapperStyled = styled.div``;

export const TableBodyWrapperStyled = styled.div`
  height: calc(100vh - 305px);
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const TableBodyRowStyled = styled(TableV2.BodyRow)<{ isOff: boolean; isSelected?: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #f0f7ff;
    `}

  ${({ isOff }) =>
    isOff &&
    css`
      & > .category,
      .attribute,
      .value,
      .masterTag {
        opacity: 0.3;
      }
    `}
`;

export const LexiconTableWrapperStyled = styled.div`
  & > div:first-of-type {
    min-width: 700px;
    height: 77vh;
  }
`;

export const LexiconChangesHeaderStyled = styled.div`
  display: flex;

  align-content: center;
  & > div:nth-of-type(2) {
    margin-left: 8px;
  }
`;

export const MultiSelectMenuWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 8px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;

  ul {
    height: unset;

    &:hover {
      overflow-y: unset;
    }
  }

  .MuiMenuItem-root {
    background-color: unset !important;
  }
`;

export const CheckIconStyled = styled(Icon)<{ checked: boolean }>`
  opacity: ${({ checked }) => (checked ? '1' : '0')};
  margin-right: 13px;

  width: 14px;
  height: 14px;
  path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }

  transition: opacity 0.1s;
`;

// TODO: need icon that is flat for proper fill color, currently the border is the shape.
const lexiconChangesIconActiveStyle = ({
  isFilterActive,
  theme,
}: {
  isFilterActive: boolean;
  theme?: Theme;
}) => {
  const activeColor = theme?.palette.custom['primary-main'];
  return isFilterActive
    ? css`
        path {
          stroke: ${activeColor};
          fill: ${activeColor};
        }
      `
    : null;
};

export const LexiconChangesIconStyled = styled(Icon)`
  align-self: center;
  ${lexiconChangesIconActiveStyle}
`;

export const EllipsisWithTooltipStyled = styled(EllipsisWithTooltip)``;
