import React, { CSSProperties, LegacyRef, useCallback, useEffect, useRef } from 'react';
import { FixedSizeList, ListOnItemsRenderedProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { AutoSizer } from 'react-virtualized';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';

/**
 * For more features - to add - checkout:
 * https://www.npmjs.com/package/react-window-infinite-loader
 */

export type RenderBodyRow = ({
  rowData,
  rowStyle,
  index,
}: {
  rowData: any;
  rowStyle: CSSProperties;
  index: number;
}) => JSX.Element;

/**
 * Default component for loading more items
 */
const LoadingMoreItems = ({ style }: { style: CSSProperties }) => {
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
        Loading...
      </Typography>
    </div>
  );
};

/**
 * Main
 */
interface VirtualTableBodyProps {
  data: any[];
  itemHeight: number;
  isLoading?: boolean;
  hasNextPage?: boolean;
  scrollToLastVirtualPosition?: {
    pageSize: number;
  };
  renderBodyRow: RenderBodyRow;
  onLoadMoreItems?: (startIndex: number, stopIndex: number) => Promise<void> | void;
  renderLoadingMoreItems?: (style: CSSProperties) => JSX.Element;
}

export const VirtualTableBody = React.memo(
  ({
    data = [],
    itemHeight,
    isLoading = false,
    hasNextPage = false,
    scrollToLastVirtualPosition,
    renderBodyRow,
    onLoadMoreItems,
    renderLoadingMoreItems,
  }: VirtualTableBodyProps): JSX.Element => {
    const scrollPosition = useRef<number>(0);
    const listRef = useRef<FixedSizeList<any> | null>(null);

    const isItemExists = (index: number): boolean => !!data[index];
    const itemCount = hasNextPage ? data.length + 1 : data.length;

    const renderLoadingMoreItemsComponent = useCallback((style: CSSProperties) => {
      return renderLoadingMoreItems ? (
        renderLoadingMoreItems(style)
      ) : (
        <LoadingMoreItems style={style} />
      );
    }, []);

    /**
     * Renders row
     */
    const Row = useCallback(
      ({ index, style }) => {
        const rowData: any = data[index];
        return (
          <>
            {isItemExists(index)
              ? renderBodyRow({ rowData, rowStyle: style, index })
              : renderLoadingMoreItemsComponent(style)}
          </>
        );
      },
      [renderBodyRow, data]
    );

    const handleForceScroll = (itemIndex: number) => {
      if (listRef?.current && listRef.current.scrollToItem) {
        listRef.current.scrollToItem(itemIndex, 'smart');
      }
    };

    const loadMoreItems = (startIndex: number, stopIndex: number): void => {
      onLoadMoreItems?.(startIndex, stopIndex);
    };

    /**
     * Called when the items rendered by the list (react-window) change.
     */
    const handleItemsRendered = ({
      visibleStartIndex,
      visibleStopIndex,
    }: ListOnItemsRenderedProps) => {
      if (!scrollToLastVirtualPosition?.pageSize) return;

      // keeps the last item index position
      // Use to prevent from storing temporary positions (due to loading data chunks)
      if (visibleStopIndex % scrollToLastVirtualPosition.pageSize !== 0) {
        scrollPosition.current = visibleStartIndex;
      }
    };

    // For scroll to a position which doesn't yet loaded
    useEffect(() => {
      if (scrollToLastVirtualPosition?.pageSize) {
        handleForceScroll(scrollPosition.current);
      }
    }, [data, scrollToLastVirtualPosition, scrollToLastVirtualPosition?.pageSize]);

    return (
      <>
        <AutoSizer>
          {({ height, width }): React.ReactNode => (
            <InfiniteLoader
              isItemLoaded={isItemExists}
              loadMoreItems={loadMoreItems}
              itemCount={itemCount}
              data-id='InfiniteLoader'
            >
              {
                // eslint-disable-next-line react/no-unused-prop-types
                ({ onItemsRendered, ref }: { onItemsRendered: any; ref: LegacyRef<any> }) => {
                  const setListRef = (listWindowRef: FixedSizeList<any>) => {
                    listRef.current = listWindowRef;
                    return ref;
                  };

                  const onListItemsRendered = (info: ListOnItemsRenderedProps) => {
                    if (scrollToLastVirtualPosition?.pageSize) {
                      handleItemsRendered(info);
                    }

                    onItemsRendered(info);
                  };

                  return (
                    <>
                      {/* TODO: Add loading handling */}
                      {!isLoading && data.length ? (
                        <FixedSizeList
                          height={height}
                          width={width}
                          itemCount={itemCount}
                          itemSize={itemHeight}
                          onItemsRendered={onListItemsRendered}
                          ref={setListRef}
                        >
                          {Row}
                        </FixedSizeList>
                      ) : null}
                    </>
                  );
                }
              }
            </InfiniteLoader>
          )}
        </AutoSizer>
      </>
    );
  }
);

export default VirtualTableBody;
