import { AxiosResponse } from 'axios';
import { orderBy } from 'lodash';
import { parseISO } from 'date-fns';
import { ChangesMapper } from '../../mappers/change-mappers.ts/changes-mapper';
import {
  ShopDraft,
  ShopVersion,
  EntityChangesDetails,
  ChangesSummaryLookupTable,
  DomainEntityPath,
  ChangesSummaryLookUpTableValue,
  ChangesSummaryDetails,
  ShopVersionApi,
} from '../types';

const rankingDomainEntities = [
  DomainEntityPath.AugmentedSearchRanking,
  DomainEntityPath.CollectionGlobalRanking,
  DomainEntityPath.DiscoveryButtonRanking,
  DomainEntityPath.PersonalizationRanking,
  DomainEntityPath.ShopTheLookRanking,
  DomainEntityPath.SimilarItemsRanking,
  DomainEntityPath.VisualSearchRanking,
];

const initialValue: Partial<Record<DomainEntityPath, string>> = {
  [DomainEntityPath.MerchandisingRules]: 'id',
  [DomainEntityPath.Collections]: 'id',
};

const domainEntityPathToIdField = rankingDomainEntities.reduce((accumulator, domainEntity) => {
  accumulator[domainEntity] = 'field';
  return accumulator;
}, initialValue);

export const convertChangesSummaryToLookupTable = <TEntity = unknown>(
  changesSummary: EntityChangesDetails[]
): ChangesSummaryLookupTable => {
  const result = changesSummary.reduce((accumulator, { domain, oldEntity, newEntity }) => {
    const idField = domainEntityPathToIdField[domain] as keyof TEntity;

    if (!idField) {
      return accumulator;
    }

    const domainData = (newEntity || oldEntity) as TEntity | undefined;

    if (domainData) {
      const id = domainData[idField] as unknown as string;
      if (!accumulator[domain]) {
        accumulator[domain] = {};
      }

      (accumulator[domain] as ChangesSummaryLookUpTableValue)[id] = domainData;
    }

    return accumulator;
  }, {} as ChangesSummaryLookupTable);
  return result;
};

export const mapDraftResponse = ({ data }: AxiosResponse): ShopDraft => {
  const sortedChangeLog = orderBy(data.changeLogs, ['changedAt'], ['desc']);

  const baseChangesSummary = ChangesMapper.mapChangesSummaryFromResponse(data.changesSummary);

  const changesSummaryLookupTable = convertChangesSummaryToLookupTable(
    baseChangesSummary.changedEntities
  );

  const mappedChangesSummary: ChangesSummaryDetails = {
    ...baseChangesSummary,
    changesSummaryLookupTable,
  };

  return {
    ...data,
    changeLogs: sortedChangeLog,
    changesSummary: mappedChangesSummary,
  };
};

export function mapVersionResponse({ data }: AxiosResponse): ShopVersion {
  const sortedChangeLog = orderBy(data.version.changeLogs, ['changedAt'], ['desc']);
  const mappedChanges = data.version?.changesSummary.map((change: any) =>
    ChangesMapper.mapDiffChange(change)
  );
  return { ...data.version, changeLogs: sortedChangeLog, changesSummary: mappedChanges };
}

export function mapCreateVersionResponse({
  data: { version },
}: AxiosResponse<{ version: ShopVersionApi }>): ShopVersion {
  const publishedAt = parseISO(version.publishedAt);

  return { ...version, publishedAt };
}

export function mapVersionsResponse({ data }: AxiosResponse): ShopVersion[] {
  return data.versions.map((version: Omit<ShopVersionApi, 'isProduction'>, index: number) => {
    const publishedAt = parseISO(version.publishedAt);

    const isProduction = index === 0;

    return { ...version, publishedAt, isProduction };
  });
}
