import { createAction } from '@reduxjs/toolkit';
import { ExperimentSlim } from 'src/services';
import {
  AppHeaderActionTypes,
  AppHeaderNavigateToActionPayload,
  AppHeaderNavigateToAction,
} from './types';

export const appHeaderActions = {
  navigateTo(payload: AppHeaderNavigateToActionPayload): AppHeaderNavigateToAction {
    return {
      type: AppHeaderActionTypes.NavigateTo,
      payload,
    };
  },
  experimentCreated: createAction<{ experiment: ExperimentSlim }>('AppHeader/ExperimentCreated'),
  experimentUpdated: createAction<{ experiment: ExperimentSlim }>('AppHeader/ExperimentUpdated'),
  experimentDeleted: createAction<{ experimentId: string; shopId: number }>(
    'AppHeader/ExperimentDeleted'
  ),

  experimentNotification: createAction<{ customMessage: string }>(
    `AppHeader/ExperimentNotification`
  ),
};
