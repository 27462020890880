import { UserTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { UserBuilder } from '../../builders';
import { UsersMapper } from './users-mapper';

export class Users extends ApiServiceBase {
  constructor() {
    super('users');
  }

  async getUser(userId: number): Promise<UserTypes.User> {
    const url = `${this.serviceBaseUri}/${userId}`;
    const response = await this.httpService.get({ url });
    return UsersMapper.map(response);
  }

  async createUser({ userData, accountId }: UserTypes.CreateUserArgs): Promise<UserTypes.User> {
    const data = UserBuilder.getCreateUserPayload({ userData, accountId });
    const response = await this.httpService.post({ url: this.serviceBaseUri, data });
    return UsersMapper.map(response);
  }

  async updateUser({ userData, accountId }: UserTypes.UpdateUserArgs): Promise<UserTypes.User> {
    const url = `${this.serviceBaseUri}/${userData.userId}`;
    const data = UserBuilder.getUpdateUserPayload({ userData, accountId });
    const response = await this.httpService.put({ url, data });
    return UsersMapper.map(response);
  }

  async deleteUser(userId: number): Promise<void> {
    const url = `${this.serviceBaseUri}/${userId}`;
    await this.httpService.delete({ url });
  }

  async forgotPassword(email: string): Promise<void> {
    const url = `${this.serviceBaseUri}/forgot_password`;
    const data = UserBuilder.getForgotPasswordPayload(email);
    await this.httpService.post({ url, data });
  }

  async resetPassword(data: UserTypes.ResetPasswordArgs): Promise<string> {
    const url = `${this.serviceBaseUri}/reset_unauth_password`;
    const response = await this.httpService.post({ url, data });
    return UsersMapper.mapSuccessMessage(response);
  }
}
