import React, { useCallback, useRef, useState } from 'react';
import { BlurredImage, ImageSize, TypographyType, TypographyVariant } from 'src/components-dummy';
import { CustomInspirationsGalleryImage, ImageTagWithId } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import {
  EditModeTextWrapperStyled,
  EditModeTypographyStyled,
  TaggableImageBackgroundStyled,
  TaggableImageWrapperStyled,
} from './TaggableImage.styles';
import { ProductTags } from '../../../components';

interface TaggableImageProps {
  image: CustomInspirationsGalleryImage;
  onImageErrorStatusChange: (status: { hasError: boolean }) => void;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
  dispatch: Dispatch;
  shopId: number;
  removeTagById: (id: string) => void;
  isInEditMode: boolean;
  showTags: boolean;
}

export function TaggableImage({
  image,
  dispatch,
  shopId,
  onImageErrorStatusChange,
  onChange,
  removeTagById,
  isInEditMode,
  showTags,
}: TaggableImageProps): JSX.Element {
  const imageRef = useRef(null);
  const [currentImageSize, setCurrentImageSize] = useState<ImageSize | null>(null);

  const onAddNewTag = useCallback(
    (newTag: ImageTagWithId) => {
      const newTags = [...image.tags, newTag];

      onChange({ tags: newTags });
    },
    [onChange, image]
  );

  return (
    <TaggableImageBackgroundStyled>
      <TaggableImageWrapperStyled isClickable={isInEditMode}>
        <ProductTags
          image={image}
          imageRef={imageRef}
          imageSize={currentImageSize}
          dispatch={dispatch}
          shopId={shopId}
          onAddNewTag={onAddNewTag}
          removeTagById={removeTagById}
          canAddNewTags={isInEditMode}
          showTags={showTags}
          canDeleteTags={isInEditMode}
        />
        <BlurredImage
          imageSrc={image.imageUrl}
          onImageErrorStatusChange={onImageErrorStatusChange}
          onImageSizeChange={setCurrentImageSize}
          ref={imageRef}
        />
      </TaggableImageWrapperStyled>
      <EditModeTextWrapperStyled>
        {isInEditMode && (
          <EditModeTypographyStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
          >
            Click on the image to start tagging
          </EditModeTypographyStyled>
        )}
      </EditModeTextWrapperStyled>
    </TaggableImageBackgroundStyled>
  );
}
