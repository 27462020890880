import React, { useEffect, useState, useCallback } from 'react';
import { ShopTypes } from 'src/services';
import {
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
} from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { enableUiTestActionsMethods, NotifyEnableUiTestIsDirtyActionPayload } from './Actions';
import { Dispatch } from '../types';
import { enableUiTestValidationSchema } from './constants';
import './EnableUiTest.scss';
import { createOptionalPortal } from '../helpers';
import { isDataDirty } from '../../utils';

export interface EnableUiTestFormProps {
  shopId: number;
  selectedVariantId?: string;
  enableUiTest: boolean;
  formHeaderElementRef?: React.RefObject<HTMLElement | null>;
  disabled?: boolean;
  dispatch: Dispatch;
  shouldRefetch?: boolean;
  onIsDirty?: (payload: NotifyEnableUiTestIsDirtyActionPayload) => void;
}

export const EnableUiTest = ({
  enableUiTest: originalEnableUiTest,
  shopId,
  selectedVariantId,
  formHeaderElementRef,
  disabled,
  dispatch,
  shouldRefetch = false,
  onIsDirty,
}: EnableUiTestFormProps): JSX.Element | null => {
  const [enableUiTestDraft, setEnableUiTestDraft] = useState(originalEnableUiTest);

  const isDirty = isDataDirty(originalEnableUiTest, enableUiTestDraft);

  const { validate, isValid } = useValidateSchema<ShopTypes.EnableUiTest>({
    schema: enableUiTestValidationSchema,
  });

  const fetchEnableUiTest = useCallback(() => {
    dispatch(
      enableUiTestActionsMethods.fetchEnableUiTest({
        shopId,
        selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  useEffect((): void => {
    setEnableUiTestDraft(originalEnableUiTest);
  }, [originalEnableUiTest]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(enableUiTestActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        enableUiTestActionsMethods.fetchEnableUiTest({
          shopId,
          selectedVariantId,
        })
      );
    }
  }, [shopId]);

  useEffect(() => {
    dispatch(enableUiTestActionsMethods.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  useEffect(() => {
    fetchEnableUiTest();
  }, [fetchEnableUiTest]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchEnableUiTest();
    }
  }, [shouldRefetch]);

  const onChange = (partialData: Partial<ShopTypes.EnableUiTest>) => {
    const updatedState = { enableUiTestDraft, ...partialData } as ShopTypes.EnableUiTest;
    validate({ dataToValidate: updatedState });
    setEnableUiTestDraft(updatedState.enableUiTest);
  };

  const onSubmit = () => {
    if (isValid) {
      dispatch(
        enableUiTestActionsMethods.updateEnableUiTest({
          shopId,
          selectedVariantId,
          enableUiTest: enableUiTestDraft,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setEnableUiTestDraft(originalEnableUiTest);
  };

  const formButtons = (
    <>
      <Button onClick={onDiscard} variant='secondary' disabled={isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} variant='primary' disabled={!(isValid && isDirty)}>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='enable-ui-test-button-wrapper'>{formButtons}</div>,
  });

  return (
    <div className='enable-ui-test-container'>
      {formButtonsSection}
      <Switch
        checked={enableUiTestDraft}
        onChange={() => {
          onChange({
            enableUiTest: !enableUiTestDraft,
          });
        }}
        disabled={disabled}
        className='enable-ui-test-toggle'
      >
        <Switch.TitleTemplate className='enable-ui-test-toggle-text'>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            Activate UI test
          </Typography>
        </Switch.TitleTemplate>
      </Switch>
    </div>
  );
};
