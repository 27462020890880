import { useEffect } from 'react';
import {
  experimentsWebSocketService,
  ExperimentUpdateEvent,
  ExperimentDeleteEvent,
  ExperimentEventTopicNames,
} from 'src/services';
import { Dispatch } from 'src/components-bl';
import { appHeaderActions } from './Actions';

export const useExperimentsWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const issueToastNotification = (payload: ExperimentUpdateEvent | ExperimentDeleteEvent) => {
    if (payload.eventIssuedByCurrentUser) return;

    let customMessage;

    switch (payload.topicKey) {
      case ExperimentEventTopicNames.Create: {
        const title = (payload as ExperimentUpdateEvent).data.experiment.name;
        customMessage = `New experiment was created: ${title}`;
        break;
      }
      case ExperimentEventTopicNames.Update: {
        const title = (payload as ExperimentUpdateEvent).data.experiment.name;
        customMessage = `Experiment was updated: ${title}`;
        break;
      }
      case ExperimentEventTopicNames.Start: {
        const title = (payload as ExperimentUpdateEvent).data.experiment.name;
        customMessage = `Experiment was started: ${title}`;
        break;
      }
      case ExperimentEventTopicNames.Stop: {
        const title = (payload as ExperimentUpdateEvent).data.experiment.name;
        customMessage = `Experiment was stopped: ${title}`;
        break;
      }
      case ExperimentEventTopicNames.Delete:
      default: {
        customMessage = 'Experiment was deleted';
        break;
      }
    }

    dispatch(appHeaderActions.experimentNotification({ customMessage }));
  };

  const onExperimentCreate = (payload: ExperimentUpdateEvent) => {
    if (shopId === payload.data.experiment.shopId) {
      dispatch(appHeaderActions.experimentCreated(payload.data));

      issueToastNotification(payload);
    }
  };

  const onExperimentUpdate = (payload: ExperimentUpdateEvent) => {
    if (shopId === payload.data.experiment.shopId) {
      dispatch(appHeaderActions.experimentUpdated(payload.data));

      issueToastNotification(payload);
    }
  };

  const onExperimentDelete = (payload: ExperimentDeleteEvent) => {
    if (shopId === payload.data.shopId) {
      dispatch(appHeaderActions.experimentDeleted(payload.data));

      issueToastNotification(payload);
    }
  };

  useEffect(() => {
    experimentsWebSocketService.subscribeToExperimentCreateEvents(onExperimentCreate);
    experimentsWebSocketService.subscribeToExperimentDeleteEvents(onExperimentDelete);
    experimentsWebSocketService.subscribeToExperimentUpdateEvents(onExperimentUpdate);
    experimentsWebSocketService.subscribeToExperimentStartEvents(onExperimentUpdate);
    experimentsWebSocketService.subscribeToExperimentStopEvents(onExperimentUpdate);

    return () => {
      experimentsWebSocketService.clearListeners();
    };
  }, [shopId]);
};
