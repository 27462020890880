import React, { RefObject, forwardRef, useEffect, useState } from 'react';
import { RelevancyTuning, FormApiRef } from 'src/components-bl';
import { IShopCatalog } from 'src/services';
import { Page } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { ContainerComponentProps } from 'src/containers/AugSearchFilters/augmented-search-filters.types';
import { PageHeaderStyled } from '../AugmentedSearchContainer.styles';
import { augmentedSearchContainerActions } from '../Actions';

export const RelevancyTuningContainer = forwardRef<FormApiRef, ContainerComponentProps>(
  ({ shopId, dispatch, onSubmitStatusChange }, ref) => {
    const [hasAugmentedSearchCatalog, setHasAugmentedSearchCatalog] = useState<boolean | null>(
      null
    );

    const relevancyTuning = useAppSelector(state => state.augmentedSearch.relevancyTuning);

    useEffect(() => {
      const validateAugmentedSearchCatalogExists = async () => {
        if (shopId) {
          try {
            const shopCatalogs: IShopCatalog[] = await (
              dispatch(augmentedSearchContainerActions.getShopCatalogs({ shopId })) as any
            ).unwrap();

            const augmentedSearchCatalogs = shopCatalogs.filter(
              catalog => catalog.isAugmentedSearchCatalog
            );

            setHasAugmentedSearchCatalog(augmentedSearchCatalogs.length > 0);
          } catch (error) {
            console.error(error);
          }
        }
      };

      validateAugmentedSearchCatalogExists();
    }, [shopId, dispatch, setHasAugmentedSearchCatalog]);

    return (
      <>
        <PageHeaderStyled>
          <Page.Title>Search Relevancy Tuning</Page.Title>
          {hasAugmentedSearchCatalog && (
            <Page.SubTitle>
              Adjust the importance of each field to refine search results, and use the preview to
              simulate changes in the visual editor.
            </Page.SubTitle>
          )}
        </PageHeaderStyled>
        <RelevancyTuning
          formApiRef={ref as RefObject<FormApiRef>}
          shopId={shopId}
          hasAugmentedSearchCatalog={hasAugmentedSearchCatalog}
          relevancyTuning={relevancyTuning}
          dispatch={dispatch}
          onSubmitStatusChange={onSubmitStatusChange}
        />
      </>
    );
  }
);
