import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Keys } from 'src/types';
import { Types, passwordValidationSchema, emailValidationSchema } from 'src/components-bl';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { useValidateSchema } from '../../../hooks';
import { LoginFormKeys, ILoginForm, LoginFormArguments } from '../types';
import { loginActions } from '../Actions';
import {
  FormStyled,
  ActionButtonStyled,
  LinkTypographyStyled,
  TextBoxStyled,
  TitleTypographyStyled,
  PasswordStyled,
  InputActionTextStyled,
  ContactUsTypographyStyled,
} from '../Login.styles';

const schema = {
  [LoginFormKeys.Email]: emailValidationSchema,
  [LoginFormKeys.Password]: passwordValidationSchema,
};

const initialFormState: ILoginForm = {
  [LoginFormKeys.Email]: '',
  [LoginFormKeys.Password]: '',
};

export const LoginForm = ({ goToForgotPasswordForm }: LoginFormArguments): JSX.Element => {
  const dispatch: Types.Dispatch = useDispatch();

  const emailInputRef = useRef<HTMLInputElement>(null);

  const [formState, setFormState] = useState(initialFormState);

  const {
    errors,
    validate,
    isValid: formIsValid,
  } = useValidateSchema<ILoginForm>({
    schema,
  });

  const setPartialFormState = (partialState: Partial<ILoginForm>) => {
    const updatedState = { ...formState, ...partialState };
    setFormState(updatedState);
    validate({ dataToValidate: updatedState });
  };

  const onSubmit = useCallback(() => {
    if (formIsValid) {
      dispatch(
        loginActions.login({
          email: formState.email,
          password: formState.password,
        })
      );
    }
  }, [dispatch, formState.email, formState.password, formIsValid]);

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      const key = e.key as Keys;

      if (key === Keys.Enter && formIsValid) {
        onSubmit();
      }
    },
    [formIsValid, onSubmit]
  );

  const onGoToForgotPassword = () => {
    if (!errors.email) {
      goToForgotPasswordForm(formState.email);
    } else {
      goToForgotPasswordForm(undefined);
    }
  };

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [emailInputRef]);

  return (
    <FormStyled>
      <TitleTypographyStyled type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        Log in to Syte
      </TitleTypographyStyled>
      <TextBoxStyled
        placeholder='Email address'
        onChange={email => setPartialFormState({ email })}
        error={errors.email?.message}
        onKeyDown={onInputKeyDown}
        ref={emailInputRef}
      />
      <PasswordStyled
        placeholder='Password'
        onChange={password => setPartialFormState({ password })}
        error={errors.password?.message}
        onKeyDown={onInputKeyDown}
      />
      <InputActionTextStyled onClick={onGoToForgotPassword} align='right'>
        <LinkTypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          Forgot password?
        </LinkTypographyStyled>
      </InputActionTextStyled>
      <ActionButtonStyled onClick={onSubmit} variant='primary' disabled={!formIsValid}>
        Log in
      </ActionButtonStyled>

      <ContactUsTypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
        Don&apos;t have an account?
        <a href='https://www.syte.ai/schedule-demo/' target='_blank' rel='noopener noreferrer'>
          <LinkTypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            Schedule a Demo
          </LinkTypographyStyled>
        </a>
      </ContactUsTypographyStyled>
    </FormStyled>
  );
};
