import { useState, useCallback, MouseEvent } from 'react';
import { generatePath } from 'react-router-dom';
import type { UseCollectionReturnType, UseCollectionArguments } from './types';
import { collectionActionMethods } from './actions';
import { collectionNavigationActionMethods } from '../actions';
import { DuplicateCollectionActionMethods } from '../DuplicateCollection';

export const useCollectionMenu = ({
  shopId,
  collectionId,
  dispatch,
  editCollectionRulesRoute,
}: UseCollectionArguments): UseCollectionReturnType => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const closeDeleteDialog = (): void => {
    setShowDeleteDialog(false);
  };

  const onDeleteCollectionClicked = () => {
    setShowDeleteDialog(true);
  };

  const onDeleteCollection = useCallback(() => {
    dispatch(
      collectionActionMethods.deleteCollection({
        params: { shopId, collectionId },
      })
    );
  }, [shopId, collectionId]);

  const onDuplicateCollection = useCallback(() => {
    dispatch(
      DuplicateCollectionActionMethods.DuplicateCollection({
        shopId,
        collectionId,
      })
    );
  }, [shopId, collectionId]);

  const onEditCollectionClicked = () => {
    if (editCollectionRulesRoute) {
      dispatch(
        collectionNavigationActionMethods.navigateTo({
          navigateTo: generatePath(editCollectionRulesRoute, {
            collectionId,
            shopId,
          }),
        })
      );
    }
  };

  return {
    showDeleteDialog,
    closeDeleteDialog,
    onDeleteCollectionClicked,
    onDeleteCollection,
    onDuplicateCollection,
    onEditCollectionClicked,
    menuAnchorElement,
    onMenuClicked,
    onMenuClose,
  };
};
