import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { GalleriesReducerState } from 'src/app-state-types/reducer-state-types';
import {
  addImagesModalFormActions,
  editCustomInspirationsGalleryPageActions,
  editInspirationalImagesGalleryPageActions,
  galleriesTablePageActions,
  viewInspirationalImagesGalleryPageActions,
  tagImageModalActions,
} from 'src/components-bl/Galleries';

const initialState: GalleriesReducerState = {
  galleries: undefined,
};

export const galleriesReducer = createReducer(initialState, builder => {
  builder
    .addCase(galleriesTablePageActions.getGalleries.pending, (state, _) => {
      return { ...state, galleries: initialState.galleries };
    })

    .addCase(galleriesTablePageActions.getGalleries.fulfilled, (state, action) => {
      return { ...state, galleries: action.payload };
    })

    .addCase(galleriesTablePageActions.deleteGallery.fulfilled, (state, action) => {
      return {
        ...state,
        galleries: state.galleries?.filter(
          gallery => gallery.id !== action.payload.deletedGalleryId
        ),
      };
    })
    .addMatcher(
      isAnyOf(
        editInspirationalImagesGalleryPageActions.getGallery.fulfilled,
        viewInspirationalImagesGalleryPageActions.getGallery.fulfilled,
        editCustomInspirationsGalleryPageActions.getGallery.fulfilled,
        addImagesModalFormActions.updateGallery.fulfilled,
        editCustomInspirationsGalleryPageActions.updateGallery.fulfilled,
        tagImageModalActions.updateGallery.fulfilled
      ),
      (state, action) => {
        return { ...state, currentGallery: action.payload };
      }
    )
    .addMatcher(
      isAnyOf(
        editInspirationalImagesGalleryPageActions.resetCurrentGallery,
        viewInspirationalImagesGalleryPageActions.resetCurrentGallery,
        editCustomInspirationsGalleryPageActions.resetCurrentGallery
      ),
      state => {
        return { ...state, currentGallery: undefined };
      }
    );
});
