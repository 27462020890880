import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Joi from 'joi';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { Types, passwordValidationSchema, ConfirmPassword } from 'src/components-bl';
import { resetPasswordActions } from '../Actions';
import { IResetPasswordForm } from '../types';
import { useValidateSchema } from '../../../hooks';
import {
  ActionButtonStyled,
  TitleTypographyStyled,
  InputActionTextStyled,
  LinkTypographyStyled,
} from '../../Login/Login.styles';

const schema = {
  password: passwordValidationSchema.required(),
  confirmPassword: passwordValidationSchema
    .valid(Joi.ref('password'))
    .messages({ 'any.only': "Passwords don't match" }),
  token: Joi.string().guid().required(),
};

const tokenKey = 'resetToken';

interface ResetPasswordFormProps {
  onSuccess: () => void;
  goToLogin: () => void;
}

export const ResetPasswordForm = ({
  onSuccess,
  goToLogin,
}: ResetPasswordFormProps): JSX.Element => {
  const token = new URLSearchParams(useLocation().search).get(tokenKey) || '';

  const initialState = {
    password: '',
    confirmPassword: '',
    token,
  };

  const dispatch: Types.Dispatch = useDispatch();
  const [state, setState] = useState<IResetPasswordForm>(initialState);

  const {
    errors,
    validate,
    isValid: formIsValid,
    validatePartial,
  } = useValidateSchema<IResetPasswordForm>({
    schema,
    initialData: state,
    validateOnStart: false,
  });

  const onChange = (args: Partial<IResetPasswordForm>) => {
    const updatedState = { ...state, ...args };
    setState(updatedState);
    validate({ dataToValidate: updatedState });
  };

  const onSubmit = () => {
    if (formIsValid && state.password) {
      (
        dispatch(
          resetPasswordActions.submit({ newPassword: state.password, resetToken: token })
        ) as any
      )
        .unwrap()
        .then(() => {
          onSuccess();
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    validatePartial({ dataToValidate: { token: state.token } });
  }, [state.token]);

  useEffect(() => {
    if (errors.token) {
      dispatch(resetPasswordActions.invalidToken());
      goToLogin();
    }
  }, [errors.token]);

  return (
    <>
      <TitleTypographyStyled type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        Enter new password
      </TitleTypographyStyled>
      <ConfirmPassword state={state} onChange={onChange} errors={errors} />
      <ActionButtonStyled onClick={onSubmit} variant='primary' disabled={!formIsValid}>
        Set new password
      </ActionButtonStyled>
      <InputActionTextStyled onClick={goToLogin} align='center'>
        <LinkTypographyStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          Back to login
        </LinkTypographyStyled>
      </InputActionTextStyled>
    </>
  );
};
