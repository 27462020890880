import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
// import { Password } from 'src/components-dummy';
import { ConfirmPassword, PasswordState } from '../ConfirmPassword';
import { PasswordInputProps, UserFormState } from './types';

export const PasswordInput = ({
  state,
  errors,
  setPartialState,
  canChangePassword,
}: PasswordInputProps): JSX.Element | null => {
  const onPasswordsChange = (partialPasswordState: Partial<PasswordState>) => {
    const updatedState = {} as Partial<Pick<UserFormState, 'newPassword' | 'confirmPassword'>>;
    if ('password' in partialPasswordState) {
      updatedState.newPassword = partialPasswordState.password;
    }
    if ('confirmPassword' in partialPasswordState) {
      updatedState.confirmPassword = partialPasswordState.confirmPassword;
    }
    setPartialState(updatedState);
  };

  return canChangePassword ? (
    <>
      <div
        className='syte-user-form-change-password'
        onClick={_ => setPartialState({ changePasswordEnabled: !state.changePasswordEnabled })}
      >
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Change Password
        </Typography>
      </div>
      {state.changePasswordEnabled && (
        <ConfirmPassword
          state={{
            password: state.newPassword,
            confirmPassword: state.confirmPassword,
          }}
          onChange={onPasswordsChange}
          errors={{
            password: errors.newPassword,
            confirmPassword: errors.confirmPassword,
          }}
        />
      )}
    </>
  ) : null;
};
