import React from 'react';
import { CollectionsMain } from '../CollectionsMain';
import { ICollectionsProps } from '../../types';

export const CollectionsContent: React.FC<ICollectionsProps> = ({
  shopId,
  dispatch,
  permittedRouteMap,
}) => {
  return (
    <CollectionsMain shopId={shopId} dispatch={dispatch} permittedRouteMap={permittedRouteMap} />
  );
};
