import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RoutedCollectionsProps } from './types';
import { Page } from '../../components-dummy';
import { CollectionsHeader } from '../../components-bl';
import { CollectionsContent } from './components/CollectionsContent';
import './Collections.scss';

export const Collections: React.FC<RoutedCollectionsProps> = ({ permittedRouteMap }) => {
  const dispatch = useAppDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  return shopId ? (
    <Page className='collections'>
      <Page.Header>
        <CollectionsHeader shopId={shopId} dispatch={dispatch} />
      </Page.Header>
      <Page.Content className='collections'>
        <CollectionsContent
          shopId={shopId}
          dispatch={dispatch}
          permittedRouteMap={permittedRouteMap}
        />
      </Page.Content>
    </Page>
  ) : null;
};
