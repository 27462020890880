import { Vertical } from '../constants';

/**
 * API
 */
export interface ILexiconItemBaseAPI {
  key: string;
  defaultTranslation?: string;
  translation: string;
  customTranslation?: string;
  isThematic?: boolean;
}

export interface ILexiconItemAPI {
  tagKey: string;
  category: ILexiconItemBaseAPI;
  attribute: ILexiconItemBaseAPI;
  value: ILexiconItemBaseAPI;
  include: boolean;
  isThematic: boolean;
  isLexiconRule: boolean;
  hasUnpublishedChanges: boolean;
}

export interface ILexiconAPI {
  items: ILexiconItemAPI[];
  verticals: Vertical[];
  isPublished: boolean;
}

export interface ILexiconTagsPaginationAPI {
  data: ILexiconItemAPI[];
  pagination: {
    totalCount: number;
  };
}

export interface ExcludeOrMatchTags {
  excludeTags: string[];
  matchTags: string[];
}

/** ****
 * VM
 ***** */
export type ILexiconItemBase = ILexiconItemBaseAPI;
export interface ILexiconItem {
  tagKey: string;
  category: ILexiconItemBaseAPI;
  attribute: ILexiconItemBaseAPI;
  value: ILexiconItemBaseAPI;
  include: boolean;
  isThematic: boolean;
  isLexiconRule: boolean;
  hasUnpublishedChanges: boolean;
}

/**
 * VM Categories map (for DeepTagReports)
 */
export interface ILexiconCategories {
  [categoryKey: string]: ILexiconCategory;
}

export interface ILexiconCategory extends ILexiconItemBase {
  attributes: { [attributeKey: string]: ILexiconAttribute };
}

export interface ILexiconAttribute extends ILexiconItemBase {
  values: { [valueKey: string]: ILexiconValue };
}

export type ILexiconValue = ILexiconItemBase;

export interface ILexiconMetadata {
  locale: string;
  shopId: number;
  lastUpdatedBy: string | undefined;
  publishedBy: string | undefined;
  publishedAt: Date | undefined;
  isPublished: boolean;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

export type ILexiconMetadataSlim = Pick<
  ILexiconMetadata,
  'shopId' | 'locale' | 'publishedAt' | 'createdAt' | 'updatedAt'
>;
export interface ILexiconMetadataAPI
  extends Omit<ILexiconMetadata, 'publishedAt' | 'updatedAt' | 'createdAt'> {
  publishedAt: string | undefined;
  createdAt: string;
  updatedAt: string | undefined;
}

export type ILexiconMetadataSlimAPI = Pick<
  ILexiconMetadataAPI,
  'shopId' | 'locale' | 'publishedAt' | 'createdAt' | 'updatedAt'
>;

export interface ILexiconTagsFilters {
  categoriesTranslations: string[];
  attributesTranslations: string[];
  valuesTranslations: string[];
  masterTag: string;
  changes: ChangesFilterValues[];
  include: boolean;
  published: boolean;
}

export interface ILexiconTagsPaginationInfo {
  skip?: number;
  limit?: number;
}

export interface ILexiconTagsSort {
  sortBy: LexiconSortBy;
  sortAscending: boolean;
}

/** Filters & Sort in Body structure */
export interface ILexiconTagsQueryAPI {
  filters?: Partial<ILexiconTagsFilters>;
  pagination?: ILexiconTagsPaginationInfo;
  sortOptions?: ILexiconTagsSort;
}

export enum LexiconSortBy {
  Category = 'category',
  Attribute = 'attribute',
  Value = 'value',
  MasterTag = 'masterTag',
}

export enum ChangesFilterValues {
  Renamed = 'renamed',
  Original = 'original',
  On = 'on',
  Off = 'off',
  Published = 'published',
  Draft = 'draft',
}

export interface ILexiconTagsPagination {
  data: {
    tags: ILexiconItem[];
  };
  pagination: {
    totalCount: number;
  };
}

export interface ILexiconTagsSummary {
  totalTags: number;
}

export type LexiconTagField = 'category' | 'attribute' | 'value';

/* Thematic tag */
export enum ThematicTagConditionOperatorsEnum {
  ValueIs = 'value-is',
  ValueIsEither = 'value-is-either',
  ValueIsNot = 'value-is-not',
  HasNoValue = 'has-no-value',
  HasAnyValue = 'has-any-value',
}

export interface ILexiconConditions {
  operator: ThematicTagConditionOperatorsEnum;
  categoryKey: string;
  attributeKey: string;
  valuesKeys: string[];
}

interface ILexiconConditionsGroup {
  conditions: ILexiconConditions[];
}

export interface IThematicTagAPI {
  id: string;
  category: { customTranslation: string };
  attribute: { customTranslation: string };
  value: { customTranslation: string };
  conditionsGroups: ILexiconConditionsGroup[];
  include: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IThematicTag extends Omit<IThematicTagAPI, 'createdAt' | 'updatedAt'> {
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ILexiconRuleAPI {
  id: string;
  category?: { categoryKey: string; customTranslation: string };
  attribute?: { attributeKey: string; customTranslation: string };
  value?: {
    attributeKey: string;
    valueKey: string;
    customTranslation: string;
  };
  conditionsGroups: ILexiconConditionsGroup[];
  include: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ILexiconRule extends Omit<ILexiconRuleAPI, 'createdAt' | 'updatedAt'> {
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ILexicon {
  items?: ILexiconItemAPI[];
  categories?: ILexiconCategories;
  verticals: Vertical[];
  isPublished: boolean;
}

export interface ShopLexiconSettings {
  shopId: number;
  locales: string[];
}
