import { Collection } from '../../../components-bl';

export enum EditCollectionActionTypes {
  GetCollectionRequest = 'EditCollectionList/GetCollectionRequest',
  GetCollectionSuccess = 'EditCollectionList/GetCollectionSuccess',
  GetCollectionError = 'EditCollectionList/GetCollectionError',
}

export interface EditCollectionActionPayload {
  shopId: number;
  collectionId: string;
}

export type EditCollectionSuccessActionPayload = Collection;

export interface EditCollectionErrorActionPayload {
  error: unknown;
}

export interface EditCollectionGetCollectionRequestAction {
  type: typeof EditCollectionActionTypes.GetCollectionRequest;
  payload: EditCollectionActionPayload;
}

export interface EditCollectionGetCollectionSuccessAction {
  type: typeof EditCollectionActionTypes.GetCollectionSuccess;
  payload: EditCollectionSuccessActionPayload;
}

export interface EditCollectionGetCollectionErrorAction {
  type: typeof EditCollectionActionTypes.GetCollectionError;
  payload: EditCollectionErrorActionPayload;
}

export type EditCollectionAction =
  | EditCollectionGetCollectionRequestAction
  | EditCollectionGetCollectionSuccessAction
  | EditCollectionGetCollectionErrorAction;
