import { useCallback, useEffect, useState } from 'react';
import { dataFieldHasTypes } from 'src/utils/data-field-has-types';
import { UseFieldDropdownProps, UseFieldDropdownReturnType } from './types';
import { ShopDataField } from '../../../../../services/src/service/types';
import { FieldSource } from '../FieldSourceDropdown/constants';
import { useMappedSelectValues } from '../../../../hooks/useMappedSelectValues';

export const useFieldDropdown = ({
  data,
  onChange,
  dataFieldsFetchService,
}: UseFieldDropdownProps): UseFieldDropdownReturnType => {
  const { fieldSource, field } = data;
  const [availableFields, setAvailableFields] = useState<ShopDataField[]>(field ? [field] : []);

  useEffect(() => {
    if (!fieldSource) {
      return () => {};
    }
    let isActual = true;
    dataFieldsFetchService.getDataFields(fieldSource).then(newFields => {
      if (isActual && fieldSource === FieldSource.Catalog) {
        setAvailableFields(newFields.filter(dataFieldHasTypes));
      }
    });
    return () => {
      isActual = false;
    };
  }, [fieldSource]);

  const dataFieldsDropdownOptions = useMappedSelectValues(availableFields);

  const onFieldChange = useCallback(
    (fieldName?: string) => {
      onChange({ ...data, field: availableFields.find(z => z.name === fieldName) });
    },
    [availableFields, onChange]
  );

  return {
    dataFieldsDropdownOptions,
    onFieldChange,
  };
};
