export enum AvailableIcons {
  Setting = 'setting',
  Add = 'add',
  AddLight = 'add-light',
  AddCircle = 'add-circle',
  Arrows = 'arrows',
  ArrowRight = 'arrow-right',
  ArrowRightLight = 'arrow-right-light',
  AngleRightLight = 'angle-right-light',
  ArrowLeft = 'arrow-left',
  Back = 'back',
  BackSmall = 'back-small',
  Bell = 'bell',
  Close = 'close',
  CloseLight = 'close-light',
  CollapseMenu = 'collapse-menu',
  Delete = 'delete',
  Pencil = 'pencil',
  EllipsisMenu = 'ellipsis-menu',
  Empty = 'empty',
  Error = 'error',
  AbTest = 'ab-test',
  Log = 'log',
  MenuPlaceholder = 'menu-placeholder',
  DottedCircle = 'dotted-circle',
  Graph = 'graph',
  Search = 'search',
  SearchShop = 'search-shop',
  SearchBold = 'search-bold',
  Star = 'star',
  Start = 'start',
  Stop = 'stop',
  Warning = 'warning',
  WarningOutline = 'warning-outline',
  UploadOld = 'upload-old',
  Valid = 'valid',
  Documents = 'documents',
  Shop = 'shop',
  Team = 'team',
  TrashCan = 'trash-can',
  Trash = 'trash',
  CloudError1 = 'cloud-error-1',
  CloudError2 = 'cloud-error-2',
  CloudSuccess = 'cloud-success',
  Eye = 'eye',
  EyeCrossed = 'eye-crossed',
  Edit = 'edit',
  Check = 'check',
  Preview = 'preview',
  TooltipQuestionmark = 'tooltip-questionmark',
  TooltipQuestionmarkBlue = 'tooltip-questionmark-blue',
  MobileOff = 'mobile-off',
  MobileOn = 'mobile-on',
  DesktopOff = 'desktop-off',
  DesktopOn = 'desktop-on',
  DDListItem = 'd&d-list-item',
  User = 'user',
  HollowStar = 'hollow-star',
  ArmChair = 'armchair',
  Drill = 'drill',
  TShirt = 't-shirt',
  PriceTag = 'price-tag',
  Diamond = 'diamond',
  Triangle = 'triangle',
  Arrow = 'arrow',
  Information = 'information',
  Camera = 'camera',
  SocialDiscovery = 'social-discovery',
  Collections = 'collections',
  SelectCaret = 'select-caret',
  Player = 'player',
  TagLabels = 'tag-labels',
  UiTemplates = 'ui-templates',
  CloudPush = 'cloud-push',
  Download = 'download',
  ReviewItems = 'reviewitems',
  ArrowRounded = 'arrow-rounded',
  Page = 'page',
  PageLarge = 'page-large',
  Upload = 'upload',
  ArrowRec = 'arrow-from-rec',
  Dot = 'dot',
  DotSeparatorBig = 'dotSeparatorBig',
  CircleWarning = 'circle-warning',
  ExternalLink = 'external-link',
  CheckRounded = 'check-rounded',
  Duplicate = 'duplicate',
  CsvFile = 'csv-file',
  JsonFile = 'json-file',
  XlsFile = 'xls-file',
  Plus = 'plus',
  Filter = 'filter',
  Minus = 'minus',
  ResetArrow = 'reset-arrow',
  CatalogManager = 'catalog-manager',
  HomeDecor = 'home-decor',
  carpentryAndDIY = 'carpentry&diy',
  ShopperExperiences = 'shopper-experiences',
  ShopMonitor = 'shop-monitor',
  CollectionsNew = 'collections-new',
  MerchRules = 'merch-rules',
  Sorting = 'sorting',
  Group = 'group',
  Lexicon = 'lexicon',
  TagManagement = 'tag-managment',
  PreviewButton = 'preview-button',
  Copy = 'copy',
  Plans = 'plans',
  ChevronLeft = 'chevron-left',
  Lock = 'lock',
  DragHandle = 'drag-handle',
  DragHandleVerticalDouble = 'drag-handle-vertical-double',
  GenerateStars = 'generate-stars',
  RefreshRegenerate = 'refresh-regenerate',
  EditV2 = 'editV2',
  RightArrow = 'right-arrow',
  AssignTo = 'assign-to',
  Portrait = 'portrait',
  AddTag = 'add-tag',
  GridLayout = 'grid-layout',
  CarouselLayout = 'carousel-layout',
  InformationV2 = 'informationV2',
  CollageLayout = 'collage-layout',
  ProductTags = 'product-tags',
  Frame = 'frame',
  NotificationsBell = 'notifications-bell',
  ListView = 'list-view',
  Dashboard = 'dashboard',
  QuestionCircle = 'question-circle',
  Gear = 'gear',
  Import = 'import',
  Export = 'export',
  Comments = 'comments',
  CommentsAdd = 'comments-add',
  UserCircle = 'user-circle',
  Cart = 'cart',
  Clock = 'clock',
  AITag = 'ai-tag',
  ThematicTag = 'thematic-tag',
  Prompt = 'prompt',
  Generate = 'generate',
  Pin = 'pin',
}
