import React from 'react';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { ShopChangeLog, systemUser } from 'src/services';
import { List, Tooltip, SidePanel } from 'src/components-dummy';
import { LogHistoryPanelProps, LogHistoryProps, LogHistoryType } from './types';
import { logHistoryTypeToTitleMap, dateFormat, logActionTranslationMap } from './constants';

import { logHistoryPanelActionMethods } from './Actions';
import './LogHistoryPanel.scss';

const Log = ({ log }: LogHistoryProps): JSX.Element => {
  const actionText = logActionTranslationMap[log.action];

  const domainText = startCase(log.resource.toLowerCase());

  const isSystemUser = log.changedBy === systemUser.userId;
  const userIdentifierText = isSystemUser ? systemUser.email : log.user?.email;

  const tooltipText = `${userIdentifierText} ${actionText} ${domainText}`;

  return (
    <div className='log-history-list-item'>
      <span className='log-date'>{format(new Date(log.changedAt), dateFormat)}</span>
      <Tooltip value={tooltipText}>
        {userIdentifierText && <span className='log-user'>{userIdentifierText}</span>}
        <span className='log-action'>{actionText}</span>
        <span className='log-domain-entity'>{domainText}</span>
      </Tooltip>
    </div>
  );
};
export const LogHistoryPanel = ({
  dispatch,
  opened,
  logs,
  type,
  versionId,
  variantName,
}: LogHistoryPanelProps): JSX.Element | null => {
  const onPanelClose = () => {
    dispatch(logHistoryPanelActionMethods.setOpened({ opened: false }));
  };

  let title = 'LOG';
  let subtitle = 'All changes that were made in the shop since the previous published version.';
  if (variantName) {
    title = `${logHistoryTypeToTitleMap[LogHistoryType.Variant]} ${variantName ?? ''}`;
    subtitle = 'All changes that were made made to the selected feature.';
  } else if (type) {
    title = `${logHistoryTypeToTitleMap[type]} ${versionId ?? ''}`;
  }

  return (
    <SidePanel
      className='syte-log-history-panel'
      onClose={onPanelClose}
      opened={opened}
      closeOnClickOutside
    >
      <div className='syte-log-history-panel-header'>
        <h1 className='syte-log-history-panel-title'>{title}</h1>
      </div>
      <span className='syte-log-history-panel-sub-title'>{subtitle}</span>
      <List items={logs} idField='changedAt' className='log-history-list'>
        <List.CardTemplate>{(log: ShopChangeLog) => <Log log={log} />}</List.CardTemplate>
        <List.EmptyTemplate>
          <span className='empty-log-history'>Log is empty</span>
        </List.EmptyTemplate>
      </List>
    </SidePanel>
  );
};
