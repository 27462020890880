import React from 'react';
import { RankingStrategy, RankingStrategyProps } from '../../../RankingStrategy';
import './EditRankingStrategy.scss';

export const EditRankingStrategy: React.FC<RankingStrategyProps> = ({
  entityId: collectionId,
  dispatch,
  weights,
  type,
  selectedSyteProduct,
  shopId,
  formHeaderElementRef,
  dataFields,
  prefixId,
  featureToggles,
}) => {
  return collectionId ? (
    <div className='edit-collection-ranking-strategy'>
      <RankingStrategy
        dispatch={dispatch}
        weights={weights}
        type={type}
        selectedSyteProduct={selectedSyteProduct}
        shopId={shopId}
        formHeaderElementRef={formHeaderElementRef}
        dataFields={dataFields}
        entityId={collectionId}
        prefixId={prefixId}
        featureToggles={featureToggles}
      />
    </div>
  ) : null;
};
