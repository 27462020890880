import React, { useCallback } from 'react';
import { Rule, RuleGroup } from 'src/components-bl/Collections';
import { AvailableIcons } from 'src/components-dummy';
import { CollectionRulesList } from '../CollectionRulesList';
import { CollectionRulesDataFieldsFetchService } from '../../../../../services/src/service/collections/CollectionRulesDataFieldsFetchService';
import { ParseJoiValidateResponse } from '../../../../../utils';
import {
  OnDeleteRuleGroupCallback,
  OnAddRuleCallback,
  OnDeleteRuleCallback,
  OnEditRuleCallback,
} from '../types';
import { DeleteRuleGroupButtonStyled } from './DeleteRuleGroupButton.styles';

interface CollectionRulesRuleGroupProps {
  shopId: number;
  ruleGroup: RuleGroup;
  index: number;
  onDeleteRuleGroup: OnDeleteRuleGroupCallback;
  onAddRule: OnAddRuleCallback;
  onDeleteRule: OnDeleteRuleCallback;
  onEditRule: OnEditRuleCallback;
  dataFieldsFetchService: CollectionRulesDataFieldsFetchService;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const CollectionRuleGroup: React.FC<CollectionRulesRuleGroupProps> = ({
  ruleGroup,
  index,
  onDeleteRuleGroup,
  onAddRule,
  onDeleteRule,
  onEditRule,
  dataFieldsFetchService,
  shopId,
  errors,
}) => {
  const addRule = () => {
    onAddRule(ruleGroup.id);
  };

  const deleteRule = (ruleIndex: number) => {
    onDeleteRule(ruleGroup.id, ruleIndex);
  };

  const editRule = (ruleIndex: number, rule: Rule) => {
    onEditRule(ruleGroup.id, ruleIndex, rule);
  };

  const deleteRuleGroup = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onDeleteRuleGroup(ruleGroup.id);
    },
    [ruleGroup.id, onDeleteRuleGroup]
  );

  return (
    <>
      {index > 0 && (
        <DeleteRuleGroupButtonStyled
          variant='primary'
          endIcon={AvailableIcons.CloseLight}
          onClick={deleteRuleGroup}
        >
          OR
        </DeleteRuleGroupButtonStyled>
      )}
      <CollectionRulesList
        shopId={shopId}
        rules={ruleGroup.rules}
        ruleGroupIndex={index}
        ruleGroupId={ruleGroup.id}
        onDeleteRuleGroup={onDeleteRuleGroup}
        addRule={addRule}
        deleteRule={deleteRule}
        onChange={editRule}
        dataFieldsFetchService={dataFieldsFetchService}
        errors={errors}
      />
    </>
  );
};
