import { ResourceAction } from 'src/services';
import { LogHistoryType } from './types';

const titleBase = 'CHANGE LOG HISTORY';

export const logHistoryTypeToTitleMap: Record<LogHistoryType, string> = {
  [LogHistoryType.Version]: `${titleBase} - Version`,
  [LogHistoryType.Variant]: `${titleBase} - `,
};

export const dateFormat = 'dd MMM yyyy, hh:mm aaa';

export const logActionTranslationMap: Record<ResourceAction, string> = {
  [ResourceAction.Create]: 'created',
  [ResourceAction.Update]: 'updated',
  [ResourceAction.Delete]: 'deleted',
};
