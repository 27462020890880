export enum LogHistoryPanelActionTypes {
  SetOpened = 'LogHistoryPanel/SetOpened',
}

export interface LogHistoryPanelSetOpenedActionPayload {
  opened: boolean;
}

export interface LogHistoryPanelSetOpenedAction {
  type: typeof LogHistoryPanelActionTypes.SetOpened;
  payload: LogHistoryPanelSetOpenedActionPayload;
}

export type LogHistoryPanelAction = LogHistoryPanelSetOpenedAction;
