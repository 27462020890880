import { DomainEntityPath, NotificationType } from 'src/services';
import {
  DefaultNotificationCardMessage,
  LexiconPublishMessage,
  InvalidAiTagMessage,
  ShopCatalogConnectMessage,
  ShopCatalogCreateMessage,
  ShopCatalogDeleteMessage,
  ShopVersionsDraftPublishMessage,
  AutomaticRedirectRulesNotPublishedMessage,
} from './components';
import { NotificationCardMessageCustomComponentMap } from './types';
import { TuningValidationChange } from './components/AugmentedSearch';

export const notificationCardMessageCustomComponentMap: NotificationCardMessageCustomComponentMap =
  {
    default: DefaultNotificationCardMessage,
    [DomainEntityPath.ShopCatalogs]: {
      [NotificationType.ShopCatalogConnect]: ShopCatalogConnectMessage,
      [NotificationType.ShopCatalogDelete]: ShopCatalogDeleteMessage,
      [NotificationType.ShopCatalogCreate]: ShopCatalogCreateMessage,
    },
    [DomainEntityPath.Lexicon]: {
      [NotificationType.LexiconPublish]: LexiconPublishMessage,
    },
    [DomainEntityPath.Shop]: {
      [NotificationType.VersionDraftPublish]: ShopVersionsDraftPublishMessage,
      [NotificationType.VersionSystemPublish]: ShopVersionsDraftPublishMessage,
    },
    [DomainEntityPath.MerchandisingRules]: {
      [NotificationType.MerchandisingRuleInvalidAiTag]: InvalidAiTagMessage,
      [NotificationType.AutomaticRedirectRulesNotPublished]:
        AutomaticRedirectRulesNotPublishedMessage,
    },
    [DomainEntityPath.AugmentedSearchTuning]: {
      [NotificationType.AugmentedSearchTuningChange]: TuningValidationChange,
    },
  };
