import React from 'react';
import { ImageTagWithId } from 'src/services';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  TagsContainerStyled,
  TagsSectionStyled,
  TitleTypographyStyled,
} from './TagsSection.styles';
import { Tag } from './Tag';

interface TagsSectionProps {
  tags: ImageTagWithId[];
  removeTagById: (id: string) => void;
}

export const TagsSection = ({ tags, removeTagById }: TagsSectionProps): JSX.Element => {
  return (
    <TagsSectionStyled>
      <TitleTypographyStyled
        type={TypographyType.Body}
        variant={TypographyVariant.ExtraSmallRegular}
      >
        Tagged in image
      </TitleTypographyStyled>
      <TagsContainerStyled>
        {tags.map(tag => (
          <Tag key={tag.id} removeTagById={removeTagById} tag={tag} />
        ))}
      </TagsContainerStyled>
    </TagsSectionStyled>
  );
};
