import type { Collection } from '../../types';
import { CollectionsSortingOptionValue } from '../types';

export enum CollectionsListActionTypes {
  GetCollectionsRequest = 'CollectionsList/GetCollectionsRequest',
  GetCollectionsSuccess = 'CollectionsList/GetCollectionsSuccess',
  GetCollectionsError = 'CollectionsList/GetCollectionsError',
  UpdateCollectionsError = 'CollectionsList/UpdateCollectionsError',
  SortCollectionsSuccess = 'SortCollectionsSuccess',
}

export interface CollectionsListActionPayload {
  shopId: number;
  collections?: Collection[];
}

export interface CollectionsListErrorActionPayload {
  error: unknown;
}

export interface SortCollectionsSuccessActionPayload {
  sortingValue: CollectionsSortingOptionValue;
}

export type UpdateCollectionsListErrorActionPayload = CollectionsListUpdateCollectionsErrorAction;

export interface CollectionsListGetCollectionsRequestAction {
  type: typeof CollectionsListActionTypes.GetCollectionsRequest;
  payload: CollectionsListActionPayload;
}

export interface CollectionsListGetCollectionsSuccessAction {
  type: typeof CollectionsListActionTypes.GetCollectionsSuccess;
  payload: CollectionsListActionPayload;
}

export interface CollectionsListGetCollectionsErrorAction {
  type: typeof CollectionsListActionTypes.GetCollectionsError;
  payload: CollectionsListErrorActionPayload;
}

export interface CollectionsListUpdateCollectionsErrorAction {
  type: typeof CollectionsListActionTypes.UpdateCollectionsError;
  payload: UpdateCollectionsListErrorActionPayload;
}

export interface CollectionsListSortCollectionsSuccessAction {
  type: typeof CollectionsListActionTypes.SortCollectionsSuccess;
  payload: SortCollectionsSuccessActionPayload;
}

export type CollectionsListAction =
  | CollectionsListGetCollectionsRequestAction
  | CollectionsListGetCollectionsSuccessAction
  | CollectionsListGetCollectionsErrorAction
  | CollectionsListUpdateCollectionsErrorAction
  | CollectionsListSortCollectionsSuccessAction;
