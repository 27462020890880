import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { ChangesMapper } from '../../mappers/change-mappers.ts/changes-mapper';
import { Experiment, ExperimentSlim, ExperimentSettings } from '../types';

function mapChangeLogs(changeLogs: any[]) {
  if (!changeLogs || !(changeLogs.length > 0)) {
    return [];
  }

  const mappedChangeLogs = changeLogs.map((changeLog: any) => {
    return { ...changeLog, changedAt: parseISO(changeLog.changedAt) };
  });

  return mappedChangeLogs;
}

function parseVariants(variants: [any]) {
  return variants.map((variant: any) => {
    const changeLogs = mapChangeLogs(variant.changeLogs);
    const versionChangesSummary = ChangesMapper.mapChangesSummaryFromResponse(
      variant.versionChangesSummary
    );

    return { ...variant, changeLogs, versionChangesSummary };
  }, []);
}

function parseExperimentDates(experimentRaw: any): Experiment | ExperimentSlim {
  const experiment = { ...experimentRaw };
  ['createdAt', 'updatedAt', 'startedAt', 'endedAt'].forEach(key => {
    if (experiment[key]) {
      experiment[key] = parseISO(experiment[key]);
    }
  });
  if (experiment.variants) {
    experiment.variants = parseVariants(experiment.variants);
  }

  return experiment;
}

export function mapExperimentsResponse({
  data,
}: AxiosResponse): Array<Experiment | ExperimentSlim> {
  return data.experiments.map((experiment: any) => parseExperimentDates(experiment));
}

export function mapExperimentResponse({ data }: AxiosResponse): Experiment | ExperimentSlim {
  return parseExperimentDates(data);
}

export function mapExperimentSettingsResponse({ data }: AxiosResponse): ExperimentSettings {
  return data;
}

export function mapCreateExperimentResponse({ data }: AxiosResponse): Experiment {
  return parseExperimentDates(data) as Experiment;
}
