import React from 'react';
import { Button, Typography, TypographyVariant } from '../../../components-dummy';
import { ICollectionsProps } from '../types';
import emptyCollectionImg from '../images/create-collection-preview.jpg';
import { CreateCollectionButton } from '../CreateCollectionButton';
import './EmptyCollections.scss';

export const EmptyCollections: React.FC<ICollectionsProps> = ({ shopId, dispatch }) => {
  return (
    <div className='empty-collections'>
      <img src={emptyCollectionImg} alt='create-collection-preview' />
      <div className='create-collection'>
        <Typography variant={TypographyVariant.LargeBold}>Create Your First Collection</Typography>
        <Typography variant={TypographyVariant.SmallRegular} className='create-collection-text'>
          Use visual, catalogue, and performance data to automatically curate custom collections
          that sell.
        </Typography>
        <div className='create-collection-button-container'>
          <CreateCollectionButton shopId={shopId} dispatch={dispatch} buttonText='Get Started' />
          <Button className='learn-more-button' variant='secondary'>
            <a href='https://support.syte.ai/space/ET/2696970265' target='_blank' rel='noreferrer'>
              Learn more
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
