import { useEffect, useState } from 'react';
import { useMappedSelectValues } from 'src/components-bl/hooks/useMappedSelectValues';
import { dataFieldHasTypes } from 'src/utils/data-field-has-types';
import { ShopDataField } from 'src/services';
import { FieldSource } from '../CollectionRules/FieldSourceDropdown/constants';
import { IUseDataFieldsProps, IUseDataFieldsReturnType } from './types';

export const useDataFields = ({
  dataFieldsFetchService,
}: IUseDataFieldsProps): IUseDataFieldsReturnType => {
  const [availableFields, setAvailableFields] = useState<ShopDataField[]>([]);

  useEffect(() => {
    dataFieldsFetchService.getDataFields(FieldSource.Catalog).then(newFields => {
      setAvailableFields(newFields.filter(dataFieldHasTypes));
    });
  }, [dataFieldsFetchService]);

  return { dataFieldsDropdownOptions: useMappedSelectValues(availableFields) };
};
