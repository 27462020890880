import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { Chip } from '../Chip';

export const DeleteIconStyled = styled(Icon)`
  width: 10px;
  min-width: 10px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-30']};
  }
`;

export const ChipStyled = styled(Chip)`
  font-family: unset;
  font-size: unset;

  .MuiChip-label {
    padding-left: unset;
  }
`;
