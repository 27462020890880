import React from 'react';
import { useLocation } from 'react-router-dom';
import { GridLayout } from './GridLayout';
import { IndexedOffers, PreviewMerchRule } from '../../../types';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import { UpdateMerchRuleArgs } from '../../../../MerchandisingRules/components/MerchandisingRuleForm';
import { GridLayoutEditMode } from './GridLayoutEditMode';

interface GridLayoutContainerProps {
  offers: IndexedOffers;
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  itemsCountPerRow: number;
  selectedRule: PreviewMerchRule | null;
  submitStrategyForm: (payload: UpdateMerchRuleArgs) => void;
  shopId: number;
  uniqueByField?: string;
}

export const GridLayoutContainer = ({
  offers,
  dataFieldsToDisplay,
  specialDataFieldsToDisplay,
  itemsCountPerRow,
  selectedRule,
  submitStrategyForm,
  shopId,
  uniqueByField,
}: GridLayoutContainerProps): JSX.Element => {
  const location = useLocation();
  const isEditMode = location.pathname.includes('visual-editor/edit/merchandising-rules');

  return isEditMode ? (
    <GridLayoutEditMode
      offers={offers}
      dataFieldsToDisplay={dataFieldsToDisplay}
      specialDataFieldsToDisplay={specialDataFieldsToDisplay}
      itemsCountPerRow={itemsCountPerRow}
      selectedRule={selectedRule}
      submitStrategyForm={submitStrategyForm}
      shopId={shopId}
      uniqueByField={uniqueByField}
    />
  ) : (
    <GridLayout
      offers={offers}
      dataFieldsToDisplay={dataFieldsToDisplay}
      specialDataFieldsToDisplay={specialDataFieldsToDisplay}
      itemsCountPerRow={itemsCountPerRow}
    />
  );
};
