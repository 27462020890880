import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { ShopFeature } from 'src/app-types';
import { shopRoutesActions } from './ShopRoutes.actions';
import { RankingContainer } from '../RankingContainer';
import { MerchandisingRulesMainContainer } from '../MerchandisingRules/MerchandisingRulesMainContainer';
import { LexiconContainerSwitch } from '../Lexicon';
import { DeepTagReportsContainerSwitch } from '../DeepTagReports';
import { PageNotFoundContainer } from '../PageNotFoundContainer';
import { FilterSetsContainer } from '../AugSearchFilters';
import { BoughtTogetherContainer } from '../BoughtTogether';
import { Collections } from '../Collections';
import { DiscoveryBannerContainer } from '../DiscoveryBanner';
import { EditCollectionContainer } from '../EditCollection';
import { EditExperimentContainer } from '../EditExperimentContainer';
import { EnableUiTestContainer } from '../EnableUiTest';
import { ExperimentsContainer } from '../ExperimentsContainer';
import { PersonalizationContainer } from '../Personalization';
import { RecentlyViewedContainer } from '../RecentlyViewedContainer';
import { ShopSettings } from '../ShopSettings';
import { ShopTheLookContainer } from '../ShopTheLook';
import { SimilarItemsContainer } from '../SimilarItems';
import { SocialDiscovery } from '../SocialDiscovery';
import { StoriesContainer } from '../Stories/StoriesContainer';
import { UiTemplates } from '../UiTemplates/uiTemplates';
import { RecEnginesContainer } from '../RecEnginesContainer';
import { ShopPageNotFoundContainer } from '../ShopPageNotFoundContainer/ShopPageNotFoundContainer';
import { CatalogManagerContainer } from '../CatalogManager';
import { ShopperExperiences } from '../ShopperExperiences';
import { FiltersContainer } from '../Filters';
import { ResultsModalSettings } from '../ResultsModalSettings';
import { GalleriesContainer } from '../Galleries';
import { CreateCollectionContainer } from '../CreateCollection';
import { AugmentedSearchContainer } from '../AugmentedSearch';
import { useSetForInternalUseOnly } from '../../hooks/useSetForInternalUseOnly';
import { VisualEditor } from '../../components-bl/VisualEditor/components';

type ShopRoutesProps = RoutedComponentProps & { enabledShopFeatures: Set<ShopFeature> };

// Switch component that syncs shopId from route and store.
// Place components that supports "/shops/:shopId" here
export const ShopRoutes = ({
  permittedRouteMap,
  enabledShopFeatures,
}: ShopRoutesProps): JSX.Element => {
  const { shopId: shopIdParamRaw } = useParams<{ shopId: string }>();

  const shopIdParamParsed = Number(shopIdParamRaw);

  const isShopIdParamInvalid = shopIdParamRaw && Number.isNaN(shopIdParamParsed);

  const shopIdParam = Number.isNaN(shopIdParamParsed) ? undefined : shopIdParamParsed;

  const dispatch = useDispatch();

  const selectedShopId = useAppSelector(state => state.shop.current?.shopId);

  const isShopLoading = useAppSelector(state => state.shop.isShopLoading);

  const currentAccount = useAppSelector(state => state.account.current);

  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  // user account is loaded form initApp hook
  const isFinishedLoadingUserAccount = useAppSelector(
    state => state.global.isFinishedLoadingUserAccount
  );

  const userAccountId = useAppSelector(state => state.global.loggedInUser?.accountId);

  const containerRouteProps = useMemo(() => ({ permittedRouteMap }), [permittedRouteMap]);

  const { isInternal } = useSetForInternalUseOnly(ShopFeature.Preview);

  useEffect(
    // Responsible for parsing shop params and setting appLoading instead of appInit, see global reducer for isShopRoute.
    () => {
      (async function processShopParams(): Promise<void> {
        // If user not ready, cannot continue determining route permission
        // TODO: load shop & account based on route, without the need to wait for initapp (still need user)
        if (!userAccountId || !currentAccount || !isFinishedLoadingUserAccount) {
          return;
        }

        try {
          dispatch(shopRoutesActions.setIsShopLoading({ isShopLoading: true }));

          // If there is current shop and invalid shopId (but not empty), clear current shop and show not found page.
          if (selectedShopId !== undefined && isShopIdParamInvalid) {
            dispatch(shopRoutesActions.clearCurrentShop());
            // Not Found Page
          }
          // If there is no current shop, and no shopId param, then just show not found page.
          else if (selectedShopId === undefined && shopIdParam === undefined) {
            // Not found Page
          }
          // If there is a current shop and no shoId param, remain in same shop.
          else if (selectedShopId !== undefined && shopIdParam === undefined) {
            // Remain in same page
          }
          // If there is shopId param and it's different from current shop, try load target shop (current shop can be undefined)
          else if (shopIdParam !== undefined && selectedShopId !== shopIdParam) {
            dispatch(shopRoutesActions.clearCurrentShop());
            await dispatch(
              shopRoutesActions.loadShop({
                targetShopId: shopIdParam,
                currentAccount,
              })
            );
          }
        } catch (error) {
          console.error(error);
        } finally {
          // also sets shop loading
          dispatch(shopRoutesActions.setAppLoading({ appLoading: false }));
        }
      })();
    },
    [shopIdParamRaw, currentAccount, isFinishedLoadingUserAccount, isShopIdParamInvalid]
  );

  // Show not found page if shop is not found or not permitted
  if (!selectedShopId && !isShopLoading) {
    return (
      <AppSwitch>
        <Route
          render={() => {
            return <ShopPageNotFoundContainer permittedRouteMap={permittedRouteMap} />;
          }}
        />
      </AppSwitch>
    );
  }

  // Don't render shop containers until shop is ready.
  // Show skeleton loaders if shop is loading instead of not found (for example clicking on a shop from shop list while current is undefined)
  // This to prevent undefined shopId issues within the containers (like generatePath with undefined shopId)
  // the pages cannot make http requests without shopId anyway.
  if (!selectedShopId) {
    return (
      <>
        <Skeleton variant='text' height='4em' width='20em' sx={{ margin: '20px 20px' }} />
        <Skeleton variant='rectangular' width='100%' height='50vh' sx={{ margin: '0 20px' }} />
      </>
    );
  }

  return (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.shopperExperiences}
        Component={ShopperExperiences}
        componentProps={{ shopId: selectedShopId, permittedRouteMap, enabledShopFeatures }}
      />
      <AppRoute route={permittedRouteMap.ranking} Component={RankingContainer} />

      <AppRoute
        route={permittedRouteMap.merchandisingRules}
        Component={MerchandisingRulesMainContainer}
        componentProps={{ permittedRouteMap }}
        exact={false}
      />

      <AppRoute
        route={permittedRouteMap.filters}
        Component={FiltersContainer}
        componentProps={{ permittedRouteMap, shopId: shopIdParam }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.lexicons}
        Component={LexiconContainerSwitch}
        componentProps={{ permittedRouteMap, shopId: shopIdParam }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.deepTagReports}
        Component={DeepTagReportsContainerSwitch}
        componentProps={{ permittedRouteMap }}
        exact={false}
      />

      <AppRoute
        route={permittedRouteMap.shopSettings}
        Component={ShopSettings}
        componentProps={containerRouteProps}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.recEnginesGeneralSettings}
        Component={RecEnginesContainer}
      />
      <AppRoute route={permittedRouteMap.shopTheLook} Component={ShopTheLookContainer} />
      <AppRoute route={permittedRouteMap.boughtTogether} Component={BoughtTogetherContainer} />
      <AppRoute route={permittedRouteMap.recentlyViewed} Component={RecentlyViewedContainer} />
      <AppRoute route={permittedRouteMap.enableUiTest} Component={EnableUiTestContainer} />
      <AppRoute route={permittedRouteMap.personalization} Component={PersonalizationContainer} />
      <AppRoute route={permittedRouteMap.similarItems} Component={SimilarItemsContainer} />
      <AppRoute route={permittedRouteMap.discoveryBanner} Component={DiscoveryBannerContainer} />
      <AppRoute
        route={permittedRouteMap.editExperiment}
        Component={EditExperimentContainer}
        componentProps={containerRouteProps}
      />
      <AppRoute
        route={permittedRouteMap.experiments}
        Component={ExperimentsContainer}
        componentProps={containerRouteProps}
      />
      <AppRoute
        route={permittedRouteMap.augmentedSearchFiltersSets}
        Component={FilterSetsContainer}
        componentProps={containerRouteProps}
      />
      <AppRoute
        route={permittedRouteMap.augmentedSearch}
        Component={AugmentedSearchContainer}
        componentProps={containerRouteProps}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.collections}
        Component={Collections}
        componentProps={containerRouteProps}
      />
      <AppRoute
        route={permittedRouteMap.uiTemplates}
        Component={UiTemplates}
        componentProps={containerRouteProps}
      />
      <AppRoute
        route={permittedRouteMap.socialDiscovery}
        Component={SocialDiscovery}
        componentProps={{
          shopId: selectedShopId,
        }}
      />
      <AppRoute
        route={permittedRouteMap.createCollection}
        Component={CreateCollectionContainer}
        componentProps={{ permittedRouteMap, shopId: selectedShopId, isCreateMode: true }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.editCollection}
        Component={EditCollectionContainer}
        componentProps={{ permittedRouteMap, shopId: selectedShopId }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.stories}
        Component={StoriesContainer}
        componentProps={{
          shopId: selectedShopId,
        }}
      />
      <AppRoute
        route={permittedRouteMap.catalogManager}
        Component={CatalogManagerContainer}
        componentProps={{
          permittedRouteMap,
          shopId: shopIdParam,
        }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.resultsModalGeneralSettings}
        Component={ResultsModalSettings}
        componentProps={{ permittedRouteMap, shopId: selectedShopId }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.galleries}
        Component={GalleriesContainer}
        componentProps={{ permittedRouteMap, shopId: shopIdParam }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.visualEditor}
        Component={VisualEditor}
        componentProps={{
          shopId: selectedShopId,
          permittedRouteMap,
          setForInternalUseOnly: isInternal,
          featureToggles,
        }}
        exact={false}
      />
      <Route component={PageNotFoundContainer} />
    </AppSwitch>
  );
};
