import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, FormCard, TypographyType, TypographyVariant } from 'src/components-dummy';
import { isEqual } from 'lodash';
import { useValidateSchema } from 'src/hooks';
import { FieldType, ShopDataField } from 'src/services';
import { dataFieldHasTypes } from 'src/utils/data-field-has-types';
import { DataFieldsProps, DataFieldsValidationType } from './types';
import { dataFieldsValidationSchema } from './constants';
import { dataFieldsTableFormActions } from './DataFieldsTableForm.actions';
import { DataFieldsTableFormStyled, NoDataFieldsTypography } from './DataFieldsTableForm.styles';
import { DataFieldTable } from './components/DataFieldsTable';

export const DataFieldsTableForm = ({
  dataFields: externalDataFields,
  dispatch,
  shopId,
  isSyteAdmin,
}: DataFieldsProps): JSX.Element => {
  const [draftDataFieldsState, setDraftDataFieldsState] = useState(externalDataFields);

  const hasNoDataFields = useMemo(() => externalDataFields?.length === 0, [externalDataFields]);

  const isDirty = useMemo(() => {
    return !isEqual(externalDataFields, draftDataFieldsState);
  }, [externalDataFields, draftDataFieldsState]);

  const { errors, validate, isValid, resetValidationState } =
    useValidateSchema<DataFieldsValidationType>({
      schema: dataFieldsValidationSchema,
      validateOnStart: false,
    });

  const onDataFieldChange = useCallback(
    (updatedDataField: ShopDataField) => {
      setDraftDataFieldsState(prevState => {
        const newState = prevState?.map(dataField => {
          if (dataField.id === updatedDataField.id) {
            return updatedDataField;
          }
          return dataField;
        });

        validate({ dataToValidate: { dataFields: newState || [] } });

        return newState;
      });
    },
    [draftDataFieldsState, validate]
  );

  const onDirtyChange = useCallback(
    (newIsDirty: boolean) => {
      dispatch(dataFieldsTableFormActions.notifyIsDirty({ isDirty: newIsDirty }));
    },
    [dispatch]
  );

  useEffect((): void => {
    onDirtyChange(isDirty);
  }, [isDirty]);

  const onSubmit = useCallback(() => {
    const formIsValid = isDirty && isValid;
    if (shopId && formIsValid && draftDataFieldsState) {
      dispatch(
        dataFieldsTableFormActions.updateDataFields({
          dataFields: draftDataFieldsState,
          shopId,
        })
      );
    }
  }, [isDirty, isValid, draftDataFieldsState, shopId]);

  // sync internal state with external
  useEffect(() => {
    const stateIsEqual = isEqual(externalDataFields, draftDataFieldsState);

    if (!stateIsEqual) {
      setDraftDataFieldsState(externalDataFields);

      resetValidationState();
    }
  }, [externalDataFields]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        dataFieldsTableFormActions.getDataFields({ shopId, fieldType: FieldType.CatalogField })
      );
    }
  }, [shopId]);

  return (
    <DataFieldsTableFormStyled>
      <FormCard stickyHeader>
        <FormCard.Button>
          <Button onClick={onSubmit} variant='primary' disabled={!(isValid && isDirty)}>
            Save changes
          </Button>
        </FormCard.Button>
        <FormCard.Content>
          {hasNoDataFields ? (
            <NoDataFieldsTypography
              type={TypographyType.Body}
              variant={TypographyVariant.MediumMedium}
            >
              This shop does not have any data fields yet.
              <br />
              Please, create catalog first
            </NoDataFieldsTypography>
          ) : (
            <DataFieldTable
              dataFields={externalDataFields?.filter(dataFieldHasTypes)}
              isSyteAdmin={isSyteAdmin}
              onDataFieldChange={onDataFieldChange}
              errors={errors}
            />
          )}
        </FormCard.Content>
      </FormCard>
    </DataFieldsTableFormStyled>
  );
};
