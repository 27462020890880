import { Collection } from '../../types';

export enum DuplicateCollectionActionTypes {
  Request = 'DuplicateCollection/DuplicateCollectionRequest',
  Success = 'DuplicateCollection/DuplicateCollectionSuccess',
  Error = 'DuplicateCollection/DuplicateCollectionError',
}

export interface DuplicateCollectionActionPayload {
  shopId: number;
  collectionId: string;
}

export type DuplicateCollectionRequestActionPayload = DuplicateCollectionActionPayload;

export interface DuplicateCollectionSuccessActionPayload {
  collection: Collection;
}

export interface DuplicateCollectionErrorActionPayload {
  error: unknown;
}

export interface DuplicateCollectionRequestAction {
  type: typeof DuplicateCollectionActionTypes.Request;
  payload: DuplicateCollectionRequestActionPayload;
}

export interface DuplicateCollectionSuccessAction {
  type: typeof DuplicateCollectionActionTypes.Success;
  payload: DuplicateCollectionSuccessActionPayload;
}

export interface DuplicateCollectionErrorAction {
  type: typeof DuplicateCollectionActionTypes.Error;
  payload: DuplicateCollectionErrorActionPayload;
}

export type DuplicateCollectionAction =
  | DuplicateCollectionRequestAction
  | DuplicateCollectionSuccessAction
  | DuplicateCollectionErrorAction;
