import React, {
  ReactNode,
  KeyboardEvent,
  forwardRef,
  MouseEvent,
  useEffect,
  MutableRefObject,
} from 'react';
import { InputProps } from '@mui/material/Input';
import classNames from 'classnames';
import { MuiInputStyled, InputChildrenStyled } from './TextBox.styles';
import { TextBoxBase, TextBoxBaseProps } from './TextBoxBase';
import { AvailableIcons } from '../Icon';

export interface TextBoxProps
  extends Pick<
      InputProps,
      'autoComplete' | 'type' | 'multiline' | 'maxRows' | 'rows' | 'autoFocus'
    >,
    Omit<TextBoxBaseProps, 'children'> {
  value?: string;
  placeholder?: string;
  name?: string;
  children?: ReactNode;
  onChange?: (text: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onMouseDown?: (e: MouseEvent) => void;
  onClick?: (e: MouseEvent) => void;
  maxLength?: number;
  prefixId?: string;
  searchIconName?: AvailableIcons;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
}
const COMPONENT_NAME = 'TextBox';

export const TextBox = forwardRef<HTMLDivElement, TextBoxProps>(
  (
    {
      autoComplete,
      autoFocus,
      children,
      className,
      disabled,
      error,
      fullWidth,
      inputRef,
      label,
      maxLength,
      maxRows,
      multiline,
      name: _name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      onMouseDown,
      placeholder,
      prefixId,
      rows,
      searchIconName: _searchIconName,
      success,
      type,
      value,
    },
    ref
  ): JSX.Element => {
    const dataId = prefixId ? `${prefixId}_${COMPONENT_NAME}` : '';

    const onTextChange = onChange
      ? (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value, event);
        }
      : undefined;

    const inputValue = maxLength !== undefined && value ? value?.slice(0, maxLength) : value;

    useEffect(() => {
      if (autoFocus && !value) {
        inputRef?.current?.focus();
      }
    }, [autoFocus, value, inputRef]);

    return (
      <TextBoxBase
        className={classNames('syte-text-box', className)}
        label={label}
        disabled={disabled}
        error={error}
        success={success}
        fullWidth={fullWidth}
        onClick={onClick}
        multiline={multiline}
      >
        {children && (
          <InputChildrenStyled className='syte-text-box-content'>{children}</InputChildrenStyled>
        )}
        <MuiInputStyled
          multiline={multiline}
          maxRows={maxRows}
          rows={rows}
          data-id={dataId}
          autoComplete={autoComplete}
          disabled={disabled}
          value={inputValue}
          onChange={onTextChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          fullWidth
          onKeyDown={onKeyDown as any}
          onMouseDown={onMouseDown}
          inputProps={{
            maxLength,
          }}
          placeholder={placeholder}
          ref={ref}
          onClick={onClick}
          error={!!error}
          inputRef={inputRef}
        />
      </TextBoxBase>
    );
  }
);
