import React from 'react';
import { generatePath } from 'react-router-dom';
import { ICollectionsProps } from '../types';
import { Button } from '../../../components-dummy';
import { collectionNavigationActionMethods } from '../actions';
import { collectionsRoutes } from '../../../app-routes';

export const CreateCollectionButton = ({
  shopId,
  dispatch,
  buttonText,
}: ICollectionsProps): JSX.Element => {
  const onSubmit = () => {
    dispatch(
      collectionNavigationActionMethods.navigateTo({
        navigateTo: generatePath(collectionsRoutes.createCollection?.path, { shopId }),
      })
    );
  };
  return <Button onClick={onSubmit}>{buttonText}</Button>;
};
