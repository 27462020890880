import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageTag } from 'src/services';
import { AvailableIcons, EllipsisWithTooltip, ImageSize } from 'src/components-dummy';
import { PopUpWithOffset } from '../PopUpWithOffset';
import {
  ProductPin,
  ProductPinInnerCircle,
  ProductPinOuterCircle,
  TagIconStyled,
  TagWrapper,
  pinSize,
} from './ProductTagPopUp.styles';
import { calculatePosition, imageTagToDisplayValue } from '../utils';

export interface ProductTagPopUpProps {
  tag: ImageTag;
  onRemove: () => void;
  imageSize: ImageSize | null;
  enableDelete: boolean;
  showPopUp: boolean;
}

export const ProductTagPopUp = ({
  tag,
  imageSize,
  enableDelete,
  onRemove,
  showPopUp,
}: ProductTagPopUpProps): JSX.Element | null => {
  const [showPopup, setShowPopup] = useState(showPopUp);

  const coordinates = useMemo(() => {
    if (!imageSize) return null;

    const offsetX = imageSize.offsetLeft;
    const offsetY = imageSize.offsetTop;

    const imageRenderedHeight = imageSize.height;
    const imageRenderedWidth = imageSize.width;

    const x = (imageRenderedWidth / 100) * tag.coordinates.x - pinSize / 2;
    const y = (imageRenderedHeight / 100) * tag.coordinates.y - pinSize / 2;

    const maxPossibleXValue = imageRenderedWidth + offsetX - pinSize / 2;
    const minPossibleXValue = offsetX;

    const maxPossibleYValue = imageRenderedHeight + offsetY - pinSize / 2;
    const minPossibleYValue = offsetY;

    const result = {
      x: calculatePosition({
        position: x + offsetX,
        min: minPossibleXValue,
        max: maxPossibleXValue,
      }),
      y: calculatePosition({
        position: y + offsetY,
        min: minPossibleYValue,
        max: maxPossibleYValue,
      }),
    };

    return result;
  }, [imageSize, tag.coordinates]);

  const displayValue = imageTagToDisplayValue(tag);

  const onPinClick = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  useEffect(() => {
    setShowPopup(showPopUp);
  }, [showPopUp]);

  return coordinates ? (
    <PopUpWithOffset
      triggerWidth={pinSize}
      triggerHeight={pinSize}
      offsetTop={coordinates.y}
      offsetLeft={coordinates.x}
      show={showPopup}
      onPopupShow={setShowPopup}
      closeOnClickOutside={false}
      hoverable
      triggerEvent='hover'
    >
      <PopUpWithOffset.Trigger>
        <ProductPin onClick={onPinClick}>
          <ProductPinInnerCircle />
          <ProductPinOuterCircle />
        </ProductPin>
      </PopUpWithOffset.Trigger>
      <PopUpWithOffset.Content>
        <TagWrapper>
          <EllipsisWithTooltip tooltipText={displayValue}>{displayValue}</EllipsisWithTooltip>
          {enableDelete && <TagIconStyled name={AvailableIcons.Close} onClick={onRemove} />}
        </TagWrapper>
      </PopUpWithOffset.Content>
    </PopUpWithOffset>
  ) : null;
};
