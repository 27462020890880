import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { PermittedRouteMap, RouteSettings } from 'src/app-routes';
import { getFlagIconNameByLocale } from 'src/components-dummy/Icon/helpers';
import {
  Auth,
  Accounts,
  Meta,
  UserTypes,
  experimentService,
  HttpStatusCode,
  ILocationUser,
  IUserOnlineUpdateEventData,
} from '../../services';

const authService = new Auth();
const accountsService = new Accounts();
const metaService = new Meta();

/* --= Action Interfaces =-- */

export const rootContainerActions = {
  confirmNav: createAction<{
    confirm: { leave: boolean; action?: any };
  }>('RootContainer/ConfirmNav'),

  getPublicMetadata: createAsyncThunk(
    'RootContainer/GetPublicMetadata',
    async (_, { rejectWithValue }) => {
      try {
        const publicMetadata = await metaService.getPublicMetadata();
        return publicMetadata;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  me: createAsyncThunk('RootContainer/Me', async (_, { rejectWithValue }) => {
    try {
      const user = await authService.me();
      return { user };
    } catch (error) {
      return rejectWithValue({ error });
    }
  }),

  initApp: createAsyncThunk(
    'RootContainer/InitApp',
    async (
      {
        user,
        accountId,
        isShopRoute,
      }: {
        user: UserTypes.User;
        accountId: number | undefined;
        isShopRoute: boolean;
      },
      { rejectWithValue }
    ) => {
      try {
        const currentAccountId =
          (accountId && user.role === UserTypes.UserRoles.SyteAdmin && accountId) || user.accountId;
        let account;
        let languagesWithIcons;

        try {
          account = await accountsService.getAccount(currentAccountId);
        } catch (error) {
          console.error(error);
          return rejectWithValue({ accountError: error });
        }

        try {
          const languages = await metaService.getLanguages();

          languagesWithIcons = languages?.map(language => ({
            ...language,
            iconName: getFlagIconNameByLocale(language.locale),
          }));
        } catch (error) {
          console.error(error);
          return rejectWithValue({ languagesError: error });
        }

        return { account, languagesWithIcons, isShopRoute };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),

  checkExperimentRunning: createAsyncThunk(
    'RootContainer/checkExperimentRunning',
    async (shopId: number, { rejectWithValue }) => {
      try {
        const runningExperiment = await experimentService.getRunningExperiment({
          shopId,
          slim: true,
        });

        return { runningExperiment };
      } catch (error) {
        console.error(error);
        // TODO: check why original code consider it as "success"
        if ((error as any).code && (error as any).code === HttpStatusCode.Forbidden) {
          return { runningExperiment: undefined };
        }

        return rejectWithValue({ error });
      }
    }
  ),
  resetRunningExperiment: createAction('RootContainer/ResetRunningExperiment'),

  setFirstRoute: createAction<{ url: string }>('RootContainer/SetFirstRoute'),

  navigateTo: createAction<{ navigateTo: string }>('RootContainer/navigateTo'),
  setPermittedRoutes: createAction<{
    permittedRoutes: RouteSettings[];
    permittedRouteMap: PermittedRouteMap;
  }>('RootContainer/SetPermittedRoutes'),
  domainEventsWebSocketConnection: createAction<{ connected: boolean }>(
    'RootContainer/DomainEventsWebSocketConnection'
  ),
  setUserLocation: createAction<{ users: ILocationUser[] }>('RootContainer/SetUserLocation'),
  setUserOnlineStatus: createAction<IUserOnlineUpdateEventData>(
    'RootContainer/SetUserOnlineStatus'
  ),
};
