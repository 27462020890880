import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Keys } from 'src/types';
import { Types, emailValidationSchema } from 'src/components-bl';
import { SYTE_SUPPORT_MAIL } from 'src/services/src/common/configuration';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { useValidateSchema } from '../../../hooks';
import { LoginFormKeys, IForgotPasswordForm, ForgotPasswordFormArguments } from '../types';
import { loginActions } from '../Actions';
import { ForgotPasswordFormSuccess } from './ForgotPasswordFormSuccess';
import {
  FormStyled,
  ActionButtonStyled,
  LinkTypographyStyled,
  TextBoxStyled,
  TitleTypographyStyled,
  ContactUsTypographyStyled,
  SubTitleTypographyStyled,
  InputActionTextStyled,
} from '../Login.styles';

const schema = {
  [LoginFormKeys.Email]: emailValidationSchema,
};

export const ForgotPasswordForm = ({
  goToLoginForm,
  email = '',
}: ForgotPasswordFormArguments): JSX.Element => {
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch: Types.Dispatch = useDispatch();

  const [emailState, setEmailState] = useState(email);

  const { validatePartial, isValid: formIsValid } = useValidateSchema<IForgotPasswordForm>({
    schema,
    validateOnStart: !!email.length,
  });

  const onEmailChange = (value: string) => {
    setEmailState(value);
    validatePartial({ dataToValidate: { email: value } });
  };

  const onSubmit = () => {
    if (formIsValid) {
      (dispatch(loginActions.forgotPassword({ email: emailState })) as any)
        .unwrap()
        .then(() => setForgotSuccess(true))
        .catch(() => {});
    }
  };

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      const key = e.key as Keys;

      if (key === Keys.Enter && formIsValid) {
        onSubmit();
      }
    },
    [formIsValid, onSubmit]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return !forgotSuccess ? (
    <FormStyled>
      <TitleTypographyStyled type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        Forgot Password?
      </TitleTypographyStyled>
      <SubTitleTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
      >
        Enter your account email and we&apos;ll send you a link to reset your password.
      </SubTitleTypographyStyled>
      <TextBoxStyled
        placeholder='Email address'
        onChange={onEmailChange}
        onKeyDown={onInputKeyDown}
        ref={inputRef}
        value={emailState}
      />

      <ActionButtonStyled onClick={onSubmit} variant='primary' disabled={!formIsValid}>
        Reset Password
      </ActionButtonStyled>

      <ContactUsTypographyStyled
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
      >
        Don&apos;t remember your email?
        <a href={`mailto:${SYTE_SUPPORT_MAIL}`}>
          <LinkTypographyStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
          >
            Contact us
          </LinkTypographyStyled>
        </a>
      </ContactUsTypographyStyled>
      <InputActionTextStyled onClick={goToLoginForm} align='center'>
        <LinkTypographyStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          Back to login
        </LinkTypographyStyled>
      </InputActionTextStyled>
    </FormStyled>
  ) : (
    <ForgotPasswordFormSuccess email={emailState} goToLoginForm={goToLoginForm} />
  );
};
