import { ChangesFilterValues } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { ILexiconRuleTableHeadRow, ILexiconTableBodyRow } from './types';

export type ColumnNames = keyof Omit<ILexiconTableBodyRow, 'id'>;

const mainColumnsMinWidth = 100;

export const CHANGES_FILTER_ALL_VALUES = getStringEnumValues(ChangesFilterValues);

export const tableColumns: Record<
  keyof Omit<ILexiconTableBodyRow, 'id' | 'originalData'>,
  ILexiconRuleTableHeadRow
> = {
  selected: {
    Header: 'Selected',
    accessor: 'selected',
    width: '5%',
    minWidth: 45,
    maxWidth: 70,
  },
  category: {
    Header: 'Category',
    accessor: 'category',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  attribute: {
    Header: 'Attribute',
    accessor: 'attribute',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  value: {
    Header: 'Value',
    accessor: 'value',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  masterTag: {
    Header: 'Source Tag',
    accessor: 'masterTag',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  changes: {
    Header: 'Status',
    accessor: 'changes',
    width: '15%',
    minWidth: 100,
  },
  actions: {
    Header: 'Actions',
    accessor: 'actions',
    width: '12%',
    maxWidth: 70,
  },
};
