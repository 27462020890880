import React, { useState } from 'react';
import { useAppSelector } from 'src/hooks';
import { ResultsScreen } from './ResultsScreen';
import { InfoScreen } from '../ContentTypeScreen/InfoScreen';
import { EditCategoryPage } from '../EditCategoryPage';
import { usePreviewScreen } from '../../hooks';
import { useEditStrategy } from '../../hooks/useEditStrategy';
import { Layout } from '../../consts/layout';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';
import { ResultsScreenContainer, VisualEditorContent } from './VisualEditorResultsScreen.styles';
import { AppRoute, AppSwitch, PermittedRouteMap } from '../../../../app-routes';
import { EditRankingStrategy } from '../EditRankingStrategy/EditRankingStrategy';
import { EditCollectionRules } from '../EditCollectionRules/EditCollectionRules';
import { EditStrategy } from '../EditStrategy/EditStrategy';
import { EditMerchandisingRule } from '../EditMerchandisingRule/EditMerchandisingRule';
import { CreateMerchandisingRule } from '../EditMerchandisingRule/CreateMerchandisingRule';
import { CreateCollectionRules } from '../EditCollectionRules/CreateCollectionRules';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';

interface Props {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  className: string;
  featureToggles: ShopFeatureToggles;
  stateController: StateController<any>;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
}

export function VisualEditorResultsScreen({
  shopId,
  permittedRouteMap,
  className,
  featureToggles,
  stateController,
  resultsScreenRef,
}: Props): JSX.Element {
  const { selectedRule } = useAppSelector(state => state.visualEditor.merchandisingRules);

  const {
    state,
    itemsCount,
    totalItemsCount,
    showResultsScreen,
    displayedOffers,
    onRefresh,
    disableRefresh,
    infiniteScroll,
    shouldDisplayItemsCount,
  } = usePreviewScreen(shopId);

  const { submitStrategyForm } = useEditStrategy({ shopId, selectedRule, permittedRouteMap });

  const [layout, setLayout] = useState<Layout>(Layout.GRID);

  return (
    <ResultsScreenContainer>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.visualEditorCreateMerchandisingRule}
          Component={CreateMerchandisingRule}
          componentProps={{ shopId, permittedRouteMap, className, stateController, featureToggles }}
        />
        <AppRoute
          route={permittedRouteMap.visualEditorEditMerchandisingRule}
          Component={EditMerchandisingRule}
          componentProps={{ shopId, permittedRouteMap, className, stateController, featureToggles }}
        />
        <AppRoute
          route={permittedRouteMap.visualEditorEditRankingStrategy}
          Component={EditRankingStrategy}
          componentProps={{ shopId, permittedRouteMap, className, featureToggles }}
        />
        <AppRoute
          route={permittedRouteMap.visualEditorEditCategoryPage}
          Component={EditCategoryPage}
          componentProps={{ shopId, permittedRouteMap, className, stateController }}
        />
        <AppRoute
          route={permittedRouteMap.visualEditorCreateCollectionRules}
          Component={CreateCollectionRules}
          componentProps={{ shopId, permittedRouteMap, className, isCreateMode: true }}
        />
        <AppRoute
          route={permittedRouteMap.visualEditorEditCollectionRules}
          Component={EditCollectionRules}
          componentProps={{ shopId, permittedRouteMap, className }}
        />
        <AppRoute
          route={permittedRouteMap.visualEditorEditStrategy}
          Component={EditStrategy}
          componentProps={{ shopId, permittedRouteMap, selectedRule, className }}
        />
      </AppSwitch>
      <VisualEditorContent>
        {showResultsScreen ? (
          <ResultsScreen
            shopId={shopId}
            itemsCount={itemsCount}
            totalItemsCount={totalItemsCount}
            offers={displayedOffers}
            disableRefresh={disableRefresh}
            infiniteScroll={infiniteScroll}
            onRefresh={onRefresh}
            selectedRule={selectedRule}
            submitStrategyForm={submitStrategyForm}
            layout={layout}
            onLayoutChange={setLayout}
            shouldDisplayItemsCount={shouldDisplayItemsCount}
            resultsScreenRef={resultsScreenRef}
          />
        ) : (
          <InfoScreen
            shopId={shopId}
            loading={state.loading}
            contentType={state.contentType}
            collection={state.collection}
            layout={layout}
            onLayoutChange={setLayout}
            onRefresh={onRefresh}
            itemsCount={itemsCount}
            totalItemsCount={totalItemsCount}
            disableRefresh={disableRefresh}
            shouldDisplayItemsCount={shouldDisplayItemsCount}
          />
        )}
      </VisualEditorContent>
    </ResultsScreenContainer>
  );
}
