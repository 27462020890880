import styled from '@emotion/styled';
import { Icon, Typography } from '../../../../../components-dummy';
import { multiColorSwatch } from './multi-color-swatch';
import { isMultiColor } from './helpers/is-multi-color';

export const ProductTileContainer = styled.div`
  padding: 0 8px 8px 8px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  gap: 8px;
  height: fit-content;
`;

export const DraggableProductTileContainer = styled(ProductTileContainer)<{ isDragging?: boolean }>`
  transform: translate3d(0, 0, 0);
  border: 1px solid transparent;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'pointer')};
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  &:hover {
    border: 1px solid #0073ff;
  }
`;

export const ProductImageContainer = styled.div`
  border-radius: inherit;
  position: relative;
  height: 300px;
`;

export const StyledImage = styled.img`
  border-radius: inherit;
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

export const ProductIndex = styled.div`
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 8px;
  min-width: 22px;
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: center;
  height: 18px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
`;

export const ProductIsPinnedIndicator = styled(Icon)`
  position: absolute;
  z-index: 2;
  top: 16px;
  right: 8px;
  height: 18px;
  min-width: 22px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
`;

export const ProductDescriptionContainer = styled.div`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const ProductDescriptionText = styled(Typography)`
  display: block;
  color: #6e6e71;
`;

export const ProductDescriptionHeader = styled(ProductDescriptionText)`
  color: #1e1e1e;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductDescriptionItemText = styled(Typography)`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 50%;
`;

export const ProductDescriptionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`;

export const ProductDescriptionKey = styled(ProductDescriptionItemText)`
  color: #878787;
`;

export const ProductDescriptionValue = styled(ProductDescriptionItemText)`
  color: #6e6e71;
`;

export const DiscountPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DiscountPrice = styled(ProductDescriptionText)`
  color: #dc3545;
`;

export const OriginalPrice = styled(ProductDescriptionText)`
  text-decoration: line-through;
`;

export const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Collapse = styled.div`
  position: relative;
  transition: max-height 350ms ease;
`;

export const CollapseTrigger = styled(Icon)`
  position: absolute;
  bottom: 0;
  right: -16px;
  height: 16px;
  width: 16px;
  transform: rotate(-90deg);
  &.closed {
    transform: rotate(90deg);
  }
`;

export const VariantsContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  overflow-y: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? 'none' : '22px')};
  position: relative;
`;

export const SizeVariantItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  border: ${({ isSelected }) => (isSelected ? '1px solid #878787' : '1px solid #dcdcdc')};
  background-color: ${({ isSelected }) => (isSelected ? '#ebebeb' : 'transparent')};
  border-radius: 4px;
  color: #6e6e71;
  padding: 2px 8px;
  &:hover {
    cursor: pointer;
    background-color: #ebebeb;
  }
`;

export const DisplayVariantsToggle = styled.div<{ isExpanded: boolean }>`
  color: #1e1e1e;
  font-size: 12px;
  cursor: pointer;
  order: ${({ isExpanded }) => isExpanded && 1};
`;

export const ColorVariantContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ isSelected }) => (isSelected ? '0.8px solid #878787' : 'none')};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  &:hover {
    cursor: pointer;
    border: 0.8px solid #878787;
  }
`;

export const ColorVariantItem = styled.div<{ color: string }>`
  display: flex;
  border: 0.8px solid #878787;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color || 'transparent'};
  ${({ color }) => isMultiColor(color) && multiColorSwatch};
`;
