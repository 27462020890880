import React, { useCallback, useEffect, useState } from 'react';
import { Button, MenuItem, Select, SelectOnChangeEvent, SelectType } from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { Account, AccountPlan } from 'src/services/src/service/types/accounts/account';
import { createOptionalPortal } from '../helpers';
import { Dispatch } from '../types';
import { formValidationSchema, accountPlanDropdownOptions } from './constants';
import { accountPlansActions } from './AccountPlansActions';
import { AccountPlansFormStyled } from './AccountPlansForm.styles';

interface AccountPlansFormProps {
  account: Account;
  dispatch: Dispatch;
  formSaveButtonRef?: React.RefObject<HTMLElement | null>;
}

export const AccountPlansForm = ({
  account,
  dispatch,
  formSaveButtonRef,
}: AccountPlansFormProps): JSX.Element => {
  const [selectedPlan, setSelectedPlan] = useState<AccountPlan | undefined>(account.accountPlan);
  const isDirty = account.accountPlan !== selectedPlan;

  const { errors, validate, isValid } = useValidateSchema<{ accountPlan?: AccountPlan }>({
    schema: formValidationSchema,
  });

  useEffect(() => {
    dispatch(accountPlansActions.notifyIsDirty({ isDirty }));

    return () => {
      dispatch(accountPlansActions.notifyIsDirty({ isDirty: false }));
    };
  }, [isDirty]);

  useEffect(() => {
    const { accountPlan } = account;
    setSelectedPlan(accountPlan);
    validate({ dataToValidate: { accountPlan } });
  }, [account]);

  const onPlanSelected: SelectOnChangeEvent = useCallback(
    event => {
      const accountPlan = event.target.value as AccountPlan;
      setSelectedPlan(accountPlan);
      validate({ dataToValidate: { accountPlan } });
    },
    [setSelectedPlan]
  );

  const onSave = useCallback(() => {
    if (!isValid || !selectedPlan) {
      return;
    }

    dispatch(
      accountPlansActions.updateAccountPlan({
        accountId: account.accountId,
        accountPlan: selectedPlan,
      })
    );
  }, [account, selectedPlan, isValid, dispatch]);

  const applyButton = (
    <Button
      onClick={onSave}
      variant='primary'
      disabled={!(isValid && isDirty)}
      className='apply-button'
    >
      Save
    </Button>
  );

  const applyButtonSection = createOptionalPortal({
    portalContent: applyButton,
    targetContainerRef: formSaveButtonRef,
    fallback: <div className='syte-account-plans-header'>{applyButton}</div>,
  });

  return (
    <AccountPlansFormStyled>
      <Select
        type={SelectType.Primary}
        label='Account Plan'
        value={selectedPlan}
        onChange={onPlanSelected}
        isError={Boolean(errors.accountPlan)}
        className='syte-drop-down syte-account-plans'
      >
        {accountPlanDropdownOptions.map(({ text, value }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
      {applyButtonSection}
    </AccountPlansFormStyled>
  );
};
